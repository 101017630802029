import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./LevelRuleMobile.scss";
import { Table, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { LEVEL_CONFIG as levelConfig, LEVEL_DOWNGRADE_CONFIG as levelDowngradeConfig } from "utils/Config";

@connect(({ users }) => ({
  levelConfig: users.levelConfig,
}))
class LevelRuleMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getSplitLine = () => {
    return <div className="split-line"/>
  }

  render() {
    return (
      <Container className="level-rule-mobile-page-container">
        <div className="level-rule-mobile-page">
          <div className="level-rule-title title-small">{this.props.t("levelRules")}</div>
          <div>
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesIntroductionTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props
                  .t("levelRulesIntroductionText")
                  .split("\n")
                  .map((content) => (
                    <p>{content}</p>
                  ))}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesValidityTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props
                  .t("levelRulesValidityText")
                  .split("\n")
                  .map((content) => (
                    <p>{content}</p>
                  ))}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesTableTitle")}
              </div>
              <div className="level-rule-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="tag-menu-2">{this.props.t("level")}</th>
                      <th className="tag-menu-2">{this.props.t("spending")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {levelConfig.map((levelRule) => {
                      return (
                        <tr key={levelRule.id}>
                          <td className="level-name tag-menu-1">{levelRule.name}</td>
                          <td className="tag-menu-1">{
                          levelRule?.rangeEnd ? 
                            `${this.props.t(`HKD$`)}${levelRule?.rangeStart}${this.props.t(`Yuan`)}-${levelRule.rangeEnd}${this.props.t(`Yuan`)}` 
                            : `${this.props.t(`HKD$`)}${levelRule?.rangeStart}${this.props.t(`Yuan`)}${this.props.t(`in 1 year`)}`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesRetainDowngradeTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props
                  .t("levelRulesRetainDowngradeText")
                  .split("\n")
                  .map((content) => (
                    <p>{content}</p>
                  ))}
              </div>
              <div className="level-rule-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="tag-menu-2">{this.props.t("level")}</th>
                      <th className="tag-menu-2">{this.props.t("Retain Spending")}</th>
                      <th className="tag-menu-2">{this.props.t("Downgrade Level")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {levelDowngradeConfig.map((levelRule) => {
                      return (
                        <tr key={levelRule.id}>
                          <td className="level-name tag-menu-1">{levelRule.name}</td>
                          <td className="level-name tag-menu-1">{levelRule.retainSpending && this.props.t(`HKD$`)}{levelRule.retainSpending || '-'}{levelRule.retainSpending && this.props.t(`Yuan`)|| ''}</td>
                          <td className="level-name tag-menu-1">{levelRule.downgradeLevel}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesMembershipGiftsTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props
                  .t("levelRulesMembershipGiftsText")
                  .split("\n")
                  .map((content) => (
                    <p>{content}</p>
                  ))}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesCouponRulesTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props
                  .t("levelRulesCouponRulesText")
                  .split("\n")
                  .map((content) => (
                    <p>{content}</p>
                  ))}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelClarify")}
              </div>
            </div>
            {this.getSplitLine()}
          </div>
        </div>
      </Container>

    );
  }
}

export default withRouter(withTranslation()(LevelRuleMobile));
