import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "component/base/CustomButton";
import { createAction } from "utils";
import { APP_NAME, DOWNLOAD_APP_DOMAIN } from 'utils/Config';
import i18n from 'I18n';

import { ReactComponent as CloseIcon } from "../../assets/close-x.svg";
import AppIcon  from "../../assets/app-icon.svg"; 

import "./OpenAppBanner.scss"

const OpenAppBanner = ({ location }) => {
  const { showOpenAppBanner, isOpenInOtherSite } = useSelector((state) => ({
    showOpenAppBanner: state.users.showOpenAppBanner,
    isOpenInOtherSite: state.users.isOpenInOtherSite,
  }));
  const dispatch = useDispatch()
  useEffect(() => {
    if (!showOpenAppBanner || isOpenInOtherSite) {
      document.getElementById("root").style.setProperty('--app-banner-height', "0px");
    }
  }, [showOpenAppBanner, isOpenInOtherSite])

  const onClose = () => {
    dispatch(
      createAction('users/updateState')({
        showOpenAppBanner: false,
      }),
    )
  }
  const openApp = () => {
    const pathName = location.pathname
    const webUriToAppUriMap = {
      "/home": "home",
      "/offer": "offer",
      "/campaign": "campaignDetail",
      "/user/myProfile": "profile",
      "/user/myCard": "myCard",
      "/user/myCoupon": "myCoupon",
      "/user/myAccount": "myAccount",
      "/about/store": "storeList",
      "/transaction": "transaction",
    }

    let directAppUrl = `${DOWNLOAD_APP_DOMAIN}?page=home`;

    Object.entries(webUriToAppUriMap).forEach(([key, value]) => {
      if (pathName?.indexOf(key) === -1) {
        return;
      }

      if (key === "/campaign") {
        const splitPath = pathName.split("/")
        const campaignIDIndex = splitPath?.indexOf("campaign") > -1 ? splitPath?.indexOf("campaign") + 1 : null;
        if (campaignIDIndex && splitPath.length >= campaignIDIndex + 1) {
          const campaignId = splitPath[campaignIDIndex].split("?")?.[0] || ""
          directAppUrl = `${DOWNLOAD_APP_DOMAIN}?page=${value}&id=${campaignId}`;
        }
      } else {
        directAppUrl = `${DOWNLOAD_APP_DOMAIN}?page=${value}`;
      }
    })
    window.location = directAppUrl
  }

  return (
    <>
      {showOpenAppBanner && !isOpenInOtherSite &&
        <div className="open-app-banner-container">
          <div className="open-app-banner-content">
            <CloseIcon
              className="app-banner-close-btn"
              onClick={onClose}
            />
            <Image src={AppIcon} className="app-icon" />
            <div className="open-app-text">
              <div className="app-name body-2">{APP_NAME}</div>
              <div className="open-app-description caption">{i18n.t("open_with_app")}</div>
            </div>
          </div>
          <CustomButton className="open-app-btn button-small" text={i18n.t("open")} btnClicked={openApp} />
        </div>
      }
    </>
  )
}

export default withRouter(OpenAppBanner);