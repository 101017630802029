import i18n from 'I18n';

import {
  getRequireOTP,
  verifyOTPAndUpdateLoginMethod,
} from 'services/CustomerAPIHelper.js';
import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction } from 'utils';
import { EDIT_EMAIL_OPTIONAL, EDIT_MOBILE_OPTIONAL } from 'utils/Config';
import { CUSTOMER_SIGN_UP_METHOD } from 'utils/CustomEnums';

const validatePhoneNumber = (phone) => {
  const re = /^(\+852[0-9]{8,8})$|^(\+853[0-9]{8,8})$|^(\+861[0-9]{10,10})$/;
  return re.test(phone);
}

function validateEmail(email) {
  if (!email) {
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const checkMobileOrEmailValid = ({
  method,
  countryCode,
  nationalNumber,
  email,
}) => {
  let valid = true;
  let mobile = `+${countryCode}${nationalNumber}`;
  let emailAddress = email;

  if (method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
    emailAddress = null;
    if (!nationalNumber && EDIT_MOBILE_OPTIONAL) {
      mobile = null;
    } else if (!validatePhoneNumber(mobile)) {
      valid = false;
    }
  } else if (method === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
    mobile = null;
    if (!email && EDIT_EMAIL_OPTIONAL) {
      emailAddress = null;
    }
    else if (!validateEmail(email)) {
      valid = false;
    }
  } else {
    console.log('verifyOTPAndUpdateLoginMethod invalid', method);
    valid = false;
  }

  return {
    valid,
    mobile,
    emailAddress,
  }
};

const getInitialState = () => {
  return {
    verifyChallenge: null,
    verifyError: null,
    firstName: {
      error: false,
      errorMessage: '',
    },
    lastName: {
      error: false,
      errorMessage: '',
    },
    loginMethodContact: {
      error: false,
      errorMessage: '',
    },
    gender: {
      error: false,
      errorMessage: '',
    },
    birthday: {
      error: false,
      errorMessage: '',
    },
    additionalDataSectionConfig: [],
    profileFieldsConfig: [],
    additionalData: {},
  };
};

export default {
  namespace: 'userInfoTemp',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    invalidContactFormat(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('setting_account_format_error'),
        },
      };
    },
    duplicateContactError(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('setting_account_contact_duplicate_error'),
        },
      };
    },
    overSendLimitError(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('account_verify_over_send_limit_error'),
        },
      };
    },
    failSendOTP(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('account_verify_fail_error'),
        },
      };
    },
    clearState() {
      return getInitialState();
    },
  },

  effects: {
    verifyOTPSentToast: [
      function* ({ payload }, { call, put, select, all }) {
        const { email, countryCode, nationalNumber, method } = payload;
        let content = i18n.t('verify_contact_otp_sent');
        if (method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
          content = content + `+${countryCode} ${nationalNumber}`;
        } else if (method === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
          content = content + email;
        } else {
          return;
        }
        yield put(
          createAction('navBars/updateState')({
            toastShowing: {
              value: true,
              content,
            },
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    updateSuccessToast: [
      function* ({ payload }, { call, put, select, all }) {
        yield put(
          createAction('navBars/updateState')({
            toastShowing: {
              value: true,
              showTickIcon: true,
              content: i18n.t('successfullyUpdated'),
            },
          }),
        );
      },
      { type: 'takeLatest' },
    ],
    updateFailedToast: [
      function* ({ payload }, { call, put, select, all }) {
        yield put(
          createAction('navBars/updateState')({
            toastShowing: {
              value: true,
              showTickIcon: false,
              content: i18n.t('update_failed'),
            },
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    noNetworkToast: [
      function* ({ payload }, { call, put, select, all }) {
        yield put(
          createAction('navBars/updateState')({
            toastShowing: {
              value: true,
              content: i18n.t('ticket_no_internet_connection'),
            },
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    updateUserDirectMarketing: [
      function* ({ payload }, { call, put, select, all }) {
        const { hasAgreedDirectMarketing, directMarketingViaEmail } = payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const params = {
          has_agreed_direct_marketing: hasAgreedDirectMarketing,
          direct_marketing_via_email: directMarketingViaEmail,
        };
        const response = yield call(ssoAPIHelper.updateUserInfo, {
          params,
          accessToken,
          uid,
        });
        console.log(
          'r@MyAccountDirectMarketing',
          response.status,
          response.data,
        );
        if (response?.status === 200) {
          yield put(createAction('users/setNewUser')({ user: response.data }));
          yield put(createAction('updateSuccessToast')());
        }
        if (payload?.afterAction) {
          payload.afterAction();
        };
      },
      { type: 'takeLatest' },
    ],

    updateUserInfoOneField: [
      function* ({ payload }, { call, put, select, all }) {
        const { serverKey, modelKey, value, successCallback } = payload;
        if (
          serverKey === undefined ||
          modelKey === undefined ||
          value === undefined
        ) {
          return;
        }
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const params = {};
        params[serverKey] = value || '';
        const response = yield call(ssoAPIHelper.updateUserInfo, {
          params,
          accessToken,
          uid,
        });
        console.log('updateUserInfoOneField', response.status, response.data);
        if (response?.status === 200) {
          yield put(createAction('users/setNewUser')({ user: response.data }));
          yield put(createAction('updateSuccessToast')());
          if (successCallback) {
            successCallback();
          }
        } else if (response?.status === 400) {
          const stateDict = {};
          stateDict[modelKey] = {
            error: true,
            errorMessage: response?.data?.[serverKey]?.[0],
          };
          yield put(createAction('updateState')(stateDict));
        } else {
          yield put(createAction('noNetworkToast')());
        }
      },
      { type: 'takeLatest' },
    ],

    requireOTPForUpdateLoginMethod: [
      function* ({ payload }, { call, put, select, all }) {
        const {
          email,
          countryCode,
          nationalNumber,
          method,
          successCallback,
          failureCallback,
        } = payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        yield put(createAction('clearState')());
        const data = {
          method,
        };

        const validResult = checkMobileOrEmailValid({
          method,
          countryCode,
          nationalNumber,
          email,
        });

        if (!validResult.valid) {
          yield put({ type: 'invalidContactFormat' });
          if (failureCallback) {
            failureCallback();
          }
          return;
        };
        data.email = validResult.emailAddress;
        data.mobile = validResult.mobile;

        console.log('requireOTPForUpdateLoginMethod', data);
        const response = yield call(getRequireOTP, {
          paramDict: data,
          uid,
        });
        console.log(
          'requireOTPForUpdateLoginMethod',
          response.status,
          response.data,
        );
        if (response.status === 201) {
          const verifyChallenge = response.data.result.verify_challenge;
          console.log('requireOTPForUpdateLoginMethod', verifyChallenge);
          yield put(
            createAction('updateState')({
              verifyChallenge: verifyChallenge,
            }),
          );
          yield put(
            createAction('verifyOTPSentToast')({
              method,
              email,
              countryCode,
              nationalNumber,
            }),
          );
          if (successCallback) {
            successCallback();
          }
        } else {
          const { result } = response.data;
          // "over_send_limit_error", "user_already_exist", "send_verify_code_failed", "invalid_format"
          if (result === 'user_already_exist') {
            yield put(createAction('duplicateContactError')());
          } else if (result === 'over_send_limit_error') {
            yield put(createAction('overSendLimitError')());
          } else if (result === 'invalid_format') {
            yield put({ type: 'invalidContactFormat' });
          } else {
            // 'send_verify_code_failed', other error
            yield put(createAction('failSendOTP')());
          }
          if (failureCallback) {
            failureCallback(result);
          }
        }
      },
      { type: 'takeLatest' },
    ],

    verifyOTPAndUpdateLoginMethod: [
      function* ({ payload }, { call, put, select, all }) {
        const {
          method,
          email,
          countryCode,
          nationalNumber,
          verifyCode,
          successCallback,
          failureCallback,
        } = payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        const verifyChallenge = yield select(
          (state) => state.userInfoTemp.verifyChallenge,
        );
        const data = {
          method,
        };

        const validResult = checkMobileOrEmailValid({
          method,
          countryCode,
          nationalNumber,
          email,
        });

        if (!validResult.valid) {
          yield put({ type: 'invalidContactFormat' });
          if (failureCallback) {
            failureCallback();
          }
          return;
        };
        data.email = validResult.emailAddress;
        data.mobile = validResult.mobile;

        data.verify_code = verifyCode;
        data.verify_challenge = verifyChallenge;
        console.log('verifyOTPAndUpdateLoginMethod', data);
        const response = yield call(verifyOTPAndUpdateLoginMethod, {
          paramDict: data,
          uid,
        });
        console.log(
          'verifyOTPAndUpdateLoginMethod',
          response.status,
          response.data,
        );
        if (response.status === 200) {
          yield put(createAction('users/fetchUserInfo')());
          yield put(createAction('updateSuccessToast')());
          if (successCallback) {
            successCallback();
          }
        } else {
          const { result } = response.data;
          // "user_already_exist", "error_challange_code", "error_verify_code", "invalid_format"
          if (result === 'user_already_exist') {
            yield put({ type: 'duplicateContactError' });
          } else if (result === 'invalid_format') {
            yield put({ type: 'invalidContactFormat' });
          } else {
            // 'error_challange_code', 'error_verify_code', other error
            if (method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
              yield put(
                createAction('updateState')({
                  verifyError: i18n.t('setting_account_mobile_otp_error'),
                }),
              );
            } else {
              yield put(
                createAction('updateState')({
                  verifyError: i18n.t('setting_account_email_otp_error'),
                }),
              );
            }
          }
          if (failureCallback) {
            failureCallback(result);
          }
        }
      },
      { type: 'takeLatest' },
    ],
    updateCustomerAdditionalField: [
      function* ({ payload }, { call, put, select, all }) {
        const {  additionalData, successAction } = payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const response = yield call(ssoAPIHelper.updateCustomerAdditionalField,
          additionalData,
          accessToken,
          uid,
        );
        if (response?.status === 200) {
          yield put(createAction('users/setNewUser')({ user: response.data }));
          yield put(createAction('updateSuccessToast')());
          successAction && successAction()
        } else {
          yield put(createAction('updateFailedToast')());
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
