import i18n from 'I18n';

import {
  CAMPAIGN_TYPE_STAMP,
  EARNING_RULE_BIRTH,
  EARNING_RULE_GPS,
  EARNING_RULE_LEVEL_UPGRADE,
  EARNING_RULE_NEW_MEMBER,
  EARNING_RULE_PURCHASE,
  EARNING_RULE_QR_CODE,
  EARNING_RULE_REFER,
} from 'services/CampaignAPIHelper';
import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction } from 'utils';
import { subHeaderLink } from 'utils/CampaignUtil';
import { NO_LEVEL } from 'utils/Config';
import {
  getActivityLogDate,
  getActivityLogDateTime,
} from 'utils/DateTimeUtils';
import { getCMSLanguage } from 'utils/LanguageCheckUtil';

function groupBy(objectArray, property) {
  return objectArray.reduce((array, obj) => {
    const key = obj[property];
    const newArray = array;
    if (!newArray[key]) {
      newArray[key] = [];
    }
    newArray[key].push(obj);
    return newArray;
  }, {});
}

export const ACTIVITY_LOG_TYPES = {
  CUSTOMER_REWARD_EARNED: 'CUSTOMER_REWARD_EARNED',
  CUSTOMER_POINTS_USED: 'CUSTOMER_POINTS_USED',
  CUSTOMER_POINTS_EXPIRED: 'CUSTOMER_POINTS_EXPIRED',
  CUSTOMER_POINTS_ADMIN_ADDED: 'CUSTOMER_POINTS_ADMIN_ADDED',
  CUSTOMER_POINTS_ADMIN_REMOVED: 'CUSTOMER_POINTS_ADMIN_REMOVED',
  CUSTOMER_POINTS_ADMIN_AUTO_ADDED: 'CUSTOMER_POINTS_ADMIN_AUTO_ADDED',
  CUSTOMER_POINTS_ADMIN_AUTO_REMOVED: 'CUSTOMER_POINTS_ADMIN_AUTO_REMOVED',
  CUSTOMER_REWARD_RECALL: 'CUSTOMER_REWARD_RECALL',
  CUSTOMER_LEVEL_CHANGED: 'CUSTOMER_LEVEL_CHANGED',
  CUSTOMER_LEVEL_RETAINED: 'CUSTOMER_LEVEL_RETAINED',
  CUSTOMER_COUPON_EXPIRED: 'CUSTOMER_COUPON_EXPIRED',
  CUSTOMER_COUPON_USED: 'CUSTOMER_COUPON_USED',
  CUSTOMER_COUPON_ADMIN_RECLAIM: 'CUSTOMER_COUPON_ADMIN_RECLAIM',
  CUSTOMER_COUPON_ADMIN_GRANT: 'CUSTOMER_COUPON_ADMIN_GRANT',
  CUSTOMER_STAMP_EARNED: 'CUSTOMER_STAMPS_EARNED',
  CUSTOMER_STAMP_EXPIRED: 'CUSTOMER_STAMPS_EXPIRED',
  CUSTOMER_STAMPS_ADMIN_RECLAIM: 'CUSTOMER_STAMPS_ADMIN_RECLAIM',
  CUSTOMER_STAMPS_ADMIN_ADD: 'CUSTOMER_STAMPS_ADMIN_ADD',
  CUSTOMER_STAMPS_ADMIN_REMOVE: 'CUSTOMER_STAMPS_ADMIN_REMOVE',
  CUSTOMER_LOGIN: 'CUSTOMER_LOGIN',
  CUSTOMER_LOGOUT: 'CUSTOMER_LOGOUT',
  CUSTOMER_CASH_DOLLAR: 'CUSTOMER_CASH_DOLLAR',
  CUSTOMER_USE_GIFT_CARD: 'CUSTOMER_USE_GIFT_CARD',
};

const EARNING_TYPES_MAP = {
  [EARNING_RULE_GPS]: {
    title: 'gpsCheckIn',
    icon: '/activityLog/GPS.png',
    srcSet: '/activityLog/GPS@2x.png 2x, /activityLog/GPS@3x.png 3x',
  },
  [EARNING_RULE_QR_CODE]: {
    title: 'offer_scan_qr_code',
    icon: '/activityLog/scan.png',
    srcSet: '/activityLog/scan@2x.png 2x, /activityLog/scan@3x.png 3x',
  },
  [EARNING_RULE_REFER]: {
    title: 'referral',
    icon: '/activityLog/referral.png',
    srcSet: '/activityLog/referral@2x.png 2x, /activityLog/referral@3x.png 3x',
  },
  [EARNING_RULE_PURCHASE]: {
    title: 'generalPurchase',
    icon: '/activityLog/purchase.png',
    srcSet: '/activityLog/purchase@2x.png 2x, /activityLog/purchase@3x.png 3x',
  },
  [EARNING_RULE_NEW_MEMBER]: {
    title: 'newMember',
    icon: '/activityLog/newMember.png',
    srcSet:
      '/activityLog/newMember@2x.png 2x, /activityLog/newMember@3x.png 3x',
  },
  [EARNING_RULE_BIRTH]: {
    title: 'birthday',
    icon: '/activityLog/birthday.png',
    srcSet: '/activityLog/birthday@2x.png 2x, /activityLog/birthday@3x.png 3x',
  },
  [EARNING_RULE_LEVEL_UPGRADE]: {
    title: 'levelUpgrade',
    icon: '/activityLog/level-upgrade.png',
    srcSet:
      '/activityLog/level-upgrade@2x.png 2x, /activityLog/level-upgrade@3x.png 3x',
  },
};

const ADMIN_EDIT_NAMES_MAP = {
  [ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_ADDED]: 'adminPointsAdded',
  [ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_REMOVED]: 'adminPointsRemoved',
  [ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_AUTO_ADDED]: 'adminPointsAutoAdded',
  [ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_AUTO_REMOVED]: 'adminPointsAutoRemoved',
  [ACTIVITY_LOG_TYPES.CUSTOMER_REWARD_RECALL]: 'adminPointsRecall',
  [ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_ADMIN_GRANT]: 'adminCouponGrant',
  [ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_ADMIN_RECLAIM]: 'adminCouponReclaim',
};

const COUPON_LOGS_NAME_MAP = {
  [ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_USED]: 'couponUsed',
  [ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_EXPIRED]: 'couponExpired',
  [ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_USED]: 'couponRedemption',
};

const STAMP_LOGS_NAME_MAP = {
  [ACTIVITY_LOG_TYPES.CUSTOMER_STAMP_EARNED]: 'stampEarned',
  [ACTIVITY_LOG_TYPES.CUSTOMER_STAMP_EXPIRED]: 'stampExpired',
  [ACTIVITY_LOG_TYPES.CUSTOMER_STAMPS_ADMIN_RECLAIM]: 'stampUpdatedByAdmin',
  [ACTIVITY_LOG_TYPES.CUSTOMER_STAMPS_ADMIN_REMOVE]: 'stampUpdatedByAdmin',
  [ACTIVITY_LOG_TYPES.CUSTOMER_STAMPS_ADMIN_ADD]: 'stampUpdatedByAdmin',
};

const CUSTOMER_LOGIN_NAME_MAP = {
  [ACTIVITY_LOG_TYPES.CUSTOMER_LOGIN]: 'customerLoginLog',
};

const CUSTOMER_LOGOUT_NAME_MAP = {
  [ACTIVITY_LOG_TYPES.CUSTOMER_LOGOUT]: 'customerLogoutLog',
};

function getDisplayActivityLog(activityLog) {
  switch (activityLog.activity_type) {
    case ACTIVITY_LOG_TYPES.CUSTOMER_REWARD_EARNED:
      if (activityLog.log_info.campaign_type === CAMPAIGN_TYPE_STAMP) {
        let title = 'campaign_point_earned';
        let icon = '/activityLog/stamp-points.png';
        let srcSet =
          '/activityLog/stamp-points@2x.png 2x, /activityLog/stamp-points@3x.png 3x';
        if (activityLog.log_info?.coupon && activityLog.log_info.coupon > 0) {
          title = 'campaign_coupon_earned';
          icon = '/activityLog/stamp-coupon.png';
          srcSet =
            '/activityLog/stamp-coupon@2x.png 2x, /activityLog/stamp-coupon@3x.png 3x';
        }
        return {
          title,
          icon,
          srcSet,
        };
      }
      if (!activityLog.log_info.campaign_earning_type) {
        return {
          title: 'campaign_point_earned',
          icon: null,
          srcSet: null,
        };
      }
      const data =
        EARNING_TYPES_MAP[activityLog.log_info.campaign_earning_type];
      return {
        title: data?.title,
        icon: data?.icon,
        srcSet: data?.srcSet,
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_EXPIRED:
      return {
        title: 'campaign_point_expired',
        icon: '/activityLog/points-expired.png',
        srcSet:
          '/activityLog/points-expired@2x.png 2x, /activityLog/points-expired@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_ADDED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_REMOVED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_AUTO_ADDED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_ADMIN_AUTO_REMOVED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_REWARD_RECALL:
    case ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_ADMIN_GRANT:
    case ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_ADMIN_RECLAIM:
      return {
        title: ADMIN_EDIT_NAMES_MAP[activityLog.activity_type],
        icon: '/activityLog/admin-edit.png',
        srcSet:
          '/activityLog/admin-edit@2x.png 2x, /activityLog/admin-edit@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_LEVEL_CHANGED:
      return {
        title: 'campaign_level_changed',
        icon: '/activityLog/level-change.png',
        srcSet:
          '/activityLog/level-change@2x.png 2x, /activityLog/level-change@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_LEVEL_RETAINED:
      return {
        title: 'campaign_level_retained',
        icon: '/activityLog/level-change.png',
        srcSet:
          '/activityLog/level-change@2x.png 2x, /activityLog/level-change@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_EXPIRED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_COUPON_USED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_POINTS_USED:
      return {
        title: COUPON_LOGS_NAME_MAP[activityLog.activity_type],
        icon: '/activityLog/coupon-redemption.png',
        srcSet:
          '/activityLog/coupon-redemption@2x.png 2x, /activityLog/coupon-redemption@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_STAMP_EARNED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_STAMP_EXPIRED:
    case ACTIVITY_LOG_TYPES.CUSTOMER_STAMPS_ADMIN_RECLAIM:
    case ACTIVITY_LOG_TYPES.CUSTOMER_STAMPS_ADMIN_REMOVE:
    case ACTIVITY_LOG_TYPES.CUSTOMER_STAMPS_ADMIN_ADD:
      return {
        title: STAMP_LOGS_NAME_MAP[activityLog.activity_type],
        icon: '/activityLog/stamp-badge.png',
        srcSet:
          '/activityLog/stamp-badge@2x.png 2x, /activityLog/stamp-badge@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_LOGIN:
      return {
        title: CUSTOMER_LOGIN_NAME_MAP[activityLog.activity_type],
        icon: '/activityLog/login.png',
        srcSet: '/activityLog/login@2x.png 2x, /activityLog/login@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_LOGOUT:
      return {
        title: CUSTOMER_LOGOUT_NAME_MAP[activityLog.activity_type],
        icon: '/activityLog/logout.png',
        srcSet: '/activityLog/logout@2x.png 2x, /activityLog/logout@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_CASH_DOLLAR:
      return {
        title: 'cash_dollar_log',
        icon: '/profile/currentPoint/cash-dollar-log.png',
        srcSet:
          '/profile/currentPoint/cash-dollar-log@2x.png 2x, /profile/currentPoint/cash-dollar-log@3x.png 3x',
      };
    case ACTIVITY_LOG_TYPES.CUSTOMER_USE_GIFT_CARD:
      return {
        title: 'gift_card_point',
        icon: '/activityLog/gift.png',
        srcSet:
          '/activityLog/gift@2x.png 2x, /activityLog/gift@3x.png 3x', 
      };
    default:
      return {
        title: '',
        icon: '',
        srcSet: '',
      };
  }
}

function assembleActivityLog(activityLog) {
  const activityType = activityLog.activity_type;
  const logInfo = activityLog.log_info || {};
  const {
    campaign_name: campaignName,
    coupon_name: couponName,
    coupon,
    content,
    points,
    tpe,
    stamps,
    new_level_name: levelName,
    campaign_type: campaignType,
    campaign_earning_type: earningType,
    cash_dollar: cashDollar,
  } = logInfo;
  const { title, icon, srcSet } = getDisplayActivityLog(activityLog);

  const activityDate = getActivityLogDate(activityLog.activity_time);
  const activityTime = getActivityLogDateTime(activityLog.activity_time);
  const activityId = activityLog.id;
  const language = getCMSLanguage(i18n.language);
  let campaignTitle = campaignName?.en;
  if (campaignName && language in campaignName) {
    campaignTitle = campaignName[language] || campaignTitle;
  }
  let level = levelName?.en;
  if (levelName && language in levelName) {
    level = levelName[language] || level;
  }
  let couponTitle = couponName?.en;
  if (couponName && language in couponName) {
    couponTitle = couponName[language] || couponTitle;
  }
  const cashDollarContent = cashDollar
    ? i18n.t('x_cash_dollar', { cashDollar: cashDollar })
    : null;
  let subTitle = campaignTitle || couponTitle || cashDollarContent || content;
  if (
    [
      ACTIVITY_LOG_TYPES.CUSTOMER_LEVEL_CHANGED,
      ACTIVITY_LOG_TYPES.CUSTOMER_LEVEL_RETAINED,
    ].includes(activityType)
  ) {
    subTitle = '';
  }

  return {
    activityType,
    campaignType,
    earningType,
    activityDate,
    title,
    subTitle,
    icon,
    srcSet,
    activityTime,
    points,
    tpe,
    coupon,
    stamps,
    level,
    activityId,
  };
}

function groupActivityLogs(logs, key) {
  const logInfo = logs[key];
  return {
    title: key,
    data: logInfo,
  };
}

export default {
  namespace: 'activityLogs',
  state: {
    activityLogList: [],
    activityLogOriginList: [],
    activityLogPageLink: null,
    isLoading: false,
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    setUserLogs(state, { payload }) {
      const newLogs = payload.logs;
      if (!newLogs) {
        return {
          ...state,
        };
      }
      const formattedLog = newLogs.map((log) => assembleActivityLog(log));
      const originalLogs = state.activityLogOriginList;
      const totalLog = payload.init
        ? formattedLog
        : originalLogs.concat(formattedLog);
      const groupedLogs = groupBy(totalLog, 'activityTime');
      const activityLogList = Object.keys(groupedLogs).map((key) =>
        groupActivityLogs(groupedLogs, key),
      );
      return {
        ...state,
        activityLogList,
        activityLogOriginList: totalLog,
        activityLogPageLink: payload.activityLogPageLink,
      };
    },

    clearLogs() {
      return {
        activityLogList: [],
        activityLogOriginList: [],
        activityLogPageLink: null,
      };
    },
  },
  effects: {
    fetchUserActivityLogs: [
      function* (action, { call, put, select }) {
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        yield put(
          createAction('updateState')({
            isLoading: true,
          }),
        );
        const response = yield call(ssoAPIHelper.getUserActivityLogs, {
          accessToken,
          uid,
          levelRelated: !NO_LEVEL
        });
        if (response.status === 200) {
          const { data } = response;
          const activityLogPageLink = subHeaderLink(response.headers.link);
          yield put(
            createAction('setUserLogs')({
              logs: data,
              activityLogPageLink,
              init: true,
            }),
          );
          yield put(
            createAction('updateState')({
              isLoading: false,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    uerActivityLogsLoadMore: [
      function* (action, { call, put, select }) {
        const accessToken = yield select((state) => state.users.accessToken);
        const pageLink = yield select(
          (state) => state.activityLogs.activityLogPageLink,
        );
        if (!pageLink) {
          return;
        }
        const response = yield call(ssoAPIHelper.getUserActivityLogs, {
          accessToken,
          pageLink,
        });
        if (response.status === 200) {
          const { data } = response;
          const activityLogPageLink = subHeaderLink(response.headers.link);
          yield put(
            createAction('setUserLogs')({ logs: data, activityLogPageLink, levelRelated: true }),
          );
        }
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            ActivityLogLoadingMore: false,
          }),
        );
      },
      { type: 'takeLatest' },
    ],
  },
};
