import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import i18n from 'I18n';

import { EditButton } from "./MyAccountInfoSection";
import CustomButton from "component/base/CustomButton";
import MonthPicker, { CustomDatePickerInput } from "component/profile/MonthPicker";
import CustomSwitchButton from "component/base/CustomSwitchButton";
import CustomModal from "component/base/CustomModal";
import { getMyAccountUserBirthdayForDisplay } from "utils/DateTimeUtils";
import { ProfileEditState } from "utils/CustomEnums";

import { ReactComponent as Checked } from 'assets/checkbox-checked.svg';
import { ReactComponent as Unchecked } from 'assets/checkbox-unchecked.svg';

import "./AdditionalFIeld.scss"


const AdditionalFieldTitleWithEditButton = ({
  title = "",
  isRequired = false,
  showEditButton = false,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { }
}) => {
  return (
    <div className="common-item-title subheading">
      {title}{!isRequired && i18n.t("personal_info_optional")}
      {showEditButton && (
        <EditButton
          state={editButtonState}
          editClick={onClickEditButton}
        />
      )}
    </div>
  )
}

const AdditionalFieldTitleWithSwitchButton = ({
  title = "",
  showSwitchButton = false,
  disableSwitchButton = false,
  isButtonOpened = false,
  onClickButton = () => { }
}) => {
  return (
    <div className="account-check-section d-flex flex-row justify-content-between align-items-center">
      <div className="common-item-title subheading">
        {title}
      </div>
      {showSwitchButton && (
        <CustomSwitchButton
          defaultChecked={isButtonOpened}
          onChange={onClickButton}
          disabled={disableSwitchButton}
        />
      )}
    </div>
  )
}

const AdditonalFieldSaveButton = ({
  disabled = false,
  onClickSaveButton,
}) => {

  return (
    <CustomButton
      className="additional-field-input-save-button"
      disabled={disabled}
      text={i18n.t('save')}
      btnClicked={onClickSaveButton}
    />
  )
}

const AdditonalFieldDisplayValue = ({value}) => {
  return (
    <div className="additoional-data-display-label">
      {value || i18n.t('not_provided')}
    </div>)
}

export const TextTypeAdditionalField = ({
  title,
  value,
  isRequired,
  isEditable,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { },
  onConfirm = () => { },
  isNumberTypeInput = false
}) => {
  const [newValue, setNewValue] = useState(value)
  useEffect(() => { setNewValue(value) }, [value])
  return (
    <div className="common-account-item">
      <AdditionalFieldTitleWithEditButton
        title={title}
        isRequired={isRequired}
        showEditButton={isEditable}
        editButtonState={editButtonState}
        onClickEditButton={(startEdit) => {
          onClickEditButton(startEdit)
          if (!startEdit) {
            setNewValue(value)
          }
        }}
      />
      {isEditable && editButtonState === ProfileEditState.Editing
        ? (
          <div className="common-item-value field-text">
            <input
              className="account-input-with-downarrow field-text"
              {...(isNumberTypeInput ? {
                type: "number",
                inputMode: "numeric"
              } : {})}
              value={newValue}
              onChange={(e) => {
                const text = e.target.value;
                setNewValue(text);
              }}
            />
            <AdditonalFieldSaveButton
              disabled={isRequired ? !newValue : false}
              onClickSaveButton={() => {
                onConfirm(newValue)
              }}
            />
          </div>
        ) : (
          <div className="common-item-value field-text">
            <AdditonalFieldDisplayValue value={value}/>
          </div>
        )}
    </div>
  )
}

export const NumberTypeAdditionalField = ({
  title,
  value,
  isRequired,
  isEditable,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { },
  onConfirm = () => { },
}) => {
  return <TextTypeAdditionalField
    title={title}
    value={value}
    isRequired={isRequired}
    isEditable={isEditable}
    editButtonState={editButtonState}
    onClickEditButton={onClickEditButton}
    onConfirm={onConfirm}
    isNumberTypeInput={true}
  />
}

export const DatetimeTypeAdditionalField = ({
  title,
  value,
  isRequired,
  isEditable,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { },
  onConfirm = () => { },
  language,
}) => {
  const [newValue, setNewValue] = useState(value)
  const [showDatePicker, setShowDatePicker] = useState(false)
  useEffect(() => { setNewValue(value) }, [value])

  return (
    <div className="common-account-item">
      <AdditionalFieldTitleWithEditButton
        title={title}
        isRequired={isRequired}
        showEditButton={isEditable}
        editButtonState={editButtonState}
        onClickEditButton={(startEdit) => {
          onClickEditButton(startEdit)
          if (!startEdit) {
            setNewValue(value)
          }
        }}
      />
      {isEditable && editButtonState === ProfileEditState.Editing
        ? (
          <div className="common-item-value field-text">
            <CustomDatePickerInput
              value={newValue ? getMyAccountUserBirthdayForDisplay(newValue) : null}
              onClick={() => {
                setShowDatePicker(!showDatePicker);
              }}
              showBirthMonthOptions={showDatePicker}
            />
            <MonthPicker
              show={showDatePicker}
              lang={language}
              selected={newValue}
              onChange={(date) => {
                if (!!date) {
                  setNewValue(date);
                }
                setShowDatePicker(false);
              }}
            />
            <AdditonalFieldSaveButton
              disabled={isRequired ? !newValue : false}
              onClickSaveButton={() => {
                onConfirm(newValue)
              }}
            />
          </div>
        ) : (
          <div className="common-item-value field-text">
            <AdditonalFieldDisplayValue value={value ? getMyAccountUserBirthdayForDisplay(value) : null} />
          </div>
        )}
    </div>
  )
}

export const ToggleTypeAdditionalField = ({
  title,
  tips,
  value,
  isEditable,
  disabled,
  onConfirm = () => { },
}) => {
  const disableSwitchButton = !isEditable || disabled
  return (
    <div className="common-account-item">
      {title && <AdditionalFieldTitleWithSwitchButton
        title={title}
        showSwitchButton={!!title}
        disableSwitchButton={disableSwitchButton}
        isButtonOpened={value}
        onClickButton={(checked) => {
          onConfirm(checked)
        }}
      />}
      {title ? (
        <div className="check-message-detail body-1">
          {tips}
        </div>
      ) : (
        <div className="account-check-section d-flex flex-row justify-content-between align-items-center">
          <div className="common-item-value accept-receive-marketing-via-email field-text">
            {tips}
          </div>
          <CustomSwitchButton
            defaultChecked={value}
            onChange={(isChecked) => {
              onConfirm(isChecked)
            }}
              disabled={disableSwitchButton}
          />
        </div>
      )}

    </div>

  )
}

export const DropdownTypeAdditionalField = ({
  title,
  value,
  isRequired,
  isEditable,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { },
  onConfirm = () => { },
  choices = [],
  language,
}) => {
  const [newValue, setNewValue] = useState(value)
  const [showDropdown, setShowDropdown] = useState(false)
  useEffect(() => { setNewValue(value) }, [value])
  const selected = choices?.find(item => item?.fieldValue === newValue)
  const displayValue = selected?.translations?.[language] || selected?.displayLabel || value
  return (
    <div className="common-account-item">
      <AdditionalFieldTitleWithEditButton
        title={title}
        isRequired={isRequired}
        showEditButton={isEditable}
        editButtonState={editButtonState}
        onClickEditButton={(startEdit) => {
          onClickEditButton(startEdit)
          if (!startEdit) {
            setNewValue(value)
          }
        }}
      />
      {isEditable && editButtonState === ProfileEditState.Editing
        ? (
          <div className="common-item-value field-text">
            <div style={{ width: "100%" }}>
              <Dropdown
                style={{ width: '100%' }}
                onToggle={(isOpen) => {
                  setShowDropdown(isOpen);
                }}
                onSelect={(value) => {
                  if (newValue === value) {
                    return
                  }
                  setNewValue(value)
                }}
              >
                <Dropdown.Toggle as="div" className={`picker-box gender-picker-box`}>
                  <div className="account-input field-text">
                    {displayValue}
                    <img
                      alt="down"
                      className="account-down-arrow"
                      src={
                        showDropdown
                          ? '/myAccount/up-arrow.png'
                          : '/myAccount/down-arrow.png'
                      }
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="gender-dropdown">
                  {choices?.map((item) => (
                    <Dropdown.Item
                      className="gender-dropdown-item field-text"
                      eventKey={item?.fieldValue || ''}
                      active={newValue === item?.fieldValue}
                    >
                      {item?.translations?.[language] || item?.displayLabel}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <AdditonalFieldSaveButton
              disabled={isRequired ? !newValue : false}
              onClickSaveButton={() => {
                onConfirm(newValue)
              }}
            />
          </div>
        ) : (
          <div className="common-item-value field-text">
            <AdditonalFieldDisplayValue value={displayValue} />
          </div>
        )}
    </div>
  )
}

export const SingleChoicesTypeAdditionalField = ({
  title,
  value,
  isRequired,
  isEditable,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { },
  onConfirm = () => { },
  onCancel = () => { },
  choices = [],
  language,
}) => {
  const [newValue, setNewValue] = useState(value)
  const [showDropdown, setShowDropdown] = useState(false)
  useEffect(() => { setNewValue(value) }, [value])
  const currentValue = choices?.find((item) => item?.fieldValue === value)
  const currentDisplayLabel = currentValue?.translations?.[language] || currentValue?.displayLabel || value
  return (
    <div className="common-account-item">
      <AdditionalFieldTitleWithEditButton
        title={title}
        isRequired={isRequired}
        showEditButton={isEditable}
        editButtonState={editButtonState}
        onClickEditButton={(startEdit) => {
          onClickEditButton(startEdit)
          setShowDropdown(startEdit)
          if (!startEdit) {
            setNewValue(value)
          }
        }}
      />
      <div className="common-item-value field-text">
        <AdditonalFieldDisplayValue value={currentDisplayLabel} />
      </div>
      <CustomModal
        className={"additional-data-modal"}
        title={title}
        show={showDropdown}
        tabs={[{
          name: "",
          content: (
            <div>
              <div className="additional-data-choices-scrollable-contaniner">
                {choices?.map((choice) => {
                  return (
                    <div
                      className="field-text additional-field-choice"
                      onClick={() => { setNewValue(choice?.fieldValue) }}  >
                      {newValue === choice?.fieldValue
                        ? <Checked />
                        : <Unchecked />
                      }
                      <div style={{ wordBreak: "break-all" }}>
                        {choice?.translations?.[language] || choice?.displayLabel || choice?.fieldValue}
                      </div>
                    </div>)
                })}
              </div>
              <CustomButton
                btnClicked={() => {
                  onConfirm(newValue)
                  setShowDropdown(false) 
                }}
                disabled={isRequired && !newValue}
                text={i18n.t('account_update')}
              />
            </div>
          )
        }]}
        hideTab={true}
        onClose={() => {
          onCancel()
          setNewValue(value)
          setShowDropdown(false)
        }}
      />
    </div>
  )
}

export const MulitChoicesTypeAdditionalField = ({
  title,
  value,
  isRequired,
  isEditable,
  editButtonState = ProfileEditState.Normal,
  onClickEditButton = () => { },
  onConfirm = () => { },
  onCancel = () => { },
  choices = [],
  language,
}) => {
  const [newValue, setNewValue] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  useEffect(() => {
    setNewValue(value)
  }, [value])
  
  
  const currentSelectedChoices = choices?.filter((choice) => value?.includes(choice?.fieldValue)) || []
  
  const currentDisplayLabel = currentSelectedChoices?.map((choice)=> {
    return choice?.translations?.[language] || choice?.displayLabel || choice?.fieldValue || "-"
  }).join(",")
  return (
    <div className="common-account-item">
      <AdditionalFieldTitleWithEditButton
        title={title}
        isRequired={isRequired}
        showEditButton={isEditable}
        editButtonState={editButtonState}
        onClickEditButton={(startEdit) => {
          onClickEditButton(startEdit)
          setShowDropdown(startEdit)
          if (!startEdit) {
            setNewValue(value)
          }
        }}
      />
      <div className="common-item-value field-text">
        <AdditonalFieldDisplayValue value={currentDisplayLabel} />
      </div>
      <CustomModal
        className={"additional-data-modal"}
        title={title}
        show={showDropdown}
        tabs={[{
          name: "",
          content: (
            <div>
              <div className="additional-data-choices-scrollable-contaniner">
                {choices?.map((choice) => {
                  const checked = newValue?.includes(choice?.fieldValue)
                  return (
                    <div className="field-text additional-field-choice" onClick={()=>{
                      if (checked) {
                        const selectItem = newValue?.filter(item => item !== choice?.fieldValue)
                        setNewValue(selectItem)
                        return
                      }
                      setNewValue([...newValue, choice?.fieldValue])
                    }}>
                      {checked
                        ? <Checked />
                        : <Unchecked />
                      }
                      <div style={{wordBreak: "break-all"}}>
                        {choice?.translations?.[language] || choice?.displayLabel || choice?.fieldValue}
                      </div>
                    </div>)
                })}
              </div>
              <CustomButton
                btnClicked={() => {
                  onConfirm(newValue?.join(",") || "")
                  setShowDropdown(false)
                }}
                disabled={isRequired && newValue?.length < 1}
                text={i18n.t('account_update')}
              />
            </div>
          )
        }]}
        hideTab={true}
        onClose={() => {
          onCancel()
          setNewValue(value)
          setShowDropdown(false)
        }}
      />
    </div>
  )
}
