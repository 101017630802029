import i18n from 'I18n';

import { TransactionAPIHelper } from 'services/TransactionAPIHelper';
import { UploadFileAPIHelper, getFileData } from 'services/UploadFileHelper';

import {
  createAction,
  enLocaleNumberFormatter,
  getTranslationData,
} from 'utils';
import { subHeaderLink } from 'utils/CampaignUtil';
import {
  getActivityLogDate,
  getActivityLogDateTime,
  getReceiptDate,
} from 'utils/DateTimeUtils';
import {
  enLocaleDollarFormatter,
  negativeNumber,
} from 'utils/NumberUtils';
import {
  getDataWithDelay,
  hasMoreData,
  refresh,
} from 'utils/PullAndRefreshUtil';

export const ClaimType = {
  APPROVED: 'records:record_approval',
  REJECTED: 'records:record_rejected',
  RECALLED: 'records:record_recalled',
  WAITING_FOR_APPROVAL: 'records:record_pending',
};

export const TransactionEventTypeDict = {
  PENDING: 'records:record_pending',
  AUTHORIZED: 'records:transaction_authorized',
  PAID: 'records:transaction_paid',
  PARTIALLY_PAID: 'records:transaction_partially_paid',
  REFUNDED: 'records:transaction_refunded',
  PARTIALLY_REFUNDED: 'records:transaction_partially_refunded',
  VOIDED: 'records:transaction_voided',
  VOID: 'records:transaction_void',
  WAITING_FOR_APPROVAL: 'records:transaction_waiting_for_approval',
  APPROVED: 'records:transaction_approved',
  RECALLED: 'records:transaction_recalled',
  REJECTED: 'records:transaction_rejected',
  COMPLETED: 'records:transaction_completed',
  PROCESSING: 'records:transaction_processing',
  REFUND: 'records:transaction_refund',
  EXCHANGE: 'records:transaction_exchange',
};

export const TransactionHistoryKey = 'TransactionHistory';

const getStoreTranslation = (store) => {
  const currentLanguage = i18n.language;
  let { name } = store;
  const { translations } = store;
  const translation = translations?.find(
    (trans) => trans.language === currentLanguage,
  );
  if (translation) {
    name = translation.name || name;
  }
  return {
    id: store.id,
    name,
  };
};

const getBrand = (brand) => {
  if (!brand) {
    return brand;
  }
  const currentLanguage = i18n.language;
  let brandName = brand.name;
  const { translations } = brand;
  const translation = translations?.find(
    (trans) => trans.language === currentLanguage,
  );
  if (translation?.name) {
    brandName = translation.name;
  }
  return {
    brandImage: brand?.icon_url,
    brandName,
  };
};

const getReward = (data) => {
  if (!data) {
    return null;
  }
  let value = 0;
  const campaignList = [];
  const earningRuleList = [];
  data.forEach((item) => {
    value += item.value;
    if (!!item?.campaign) {
      campaignList.push(item.campaign);
    } else if (!!item?.earning_rule) {
      earningRuleList.push(item.earning_rule);
    }
  });
  if (value === 0) {
    return null;
  }
  return {
    count: enLocaleNumberFormatter(value),
    campaignList,
    earningRuleList,
  };
};

function parseSingleRewardClaim(transaction) {
  const eventType = transaction.offline_event_type;
  const store = getStoreTranslation(transaction.store);
  const brand = getBrand(transaction?.store?.brand);
  return {
    id: transaction.id,
    memberId: transaction.customer?.membership_id,
    eventType: eventType,
    status: ClaimType[eventType],
    tenant: store.name,
    receiptNumber: transaction.invoice_id,
    receiptDate: getReceiptDate(transaction.date),
    createDate: getReceiptDate(transaction.creation_date),
    receiptAmount: `HK$ ${enLocaleNumberFormatter(transaction.total_value)}`,
    pointsRewards: getReward(transaction?.rewards?.points_rewards),
    couponRewards: getReward(transaction?.rewards?.coupons_rewards),
    stampRewards: getReward(transaction?.rewards?.stamps_rewards),
    receiptImage: transaction.receipt_image_url || '',
    creditImage: transaction.credit_card_slip_image_url || '',
    updateDate: getReceiptDate(transaction.last_modified_date),
    brandImage: brand?.brandImage,
    brandTitle: brand?.brandName,
  };
}

function assembleStore(store) {
  const storeWithTranslation = getStoreTranslation(store);
  return {
    id: store.id,
    name: storeWithTranslation.name || store.name,
  };
}

function parseRewardClaimList(transactions) {
  return transactions.map((transaction) => parseSingleRewardClaim(transaction));
}

function getStoreList(stores) {
  return stores.map((store) => assembleStore(store));
}

const parseDiscount = (item) => {
  const discount = {
    id: item.id,
    code: item.code,
    type: item.type,
    value: Number(item.value),
    title: item.title?.toUpperCase() || "",
  };
  return discount;
};

const parsePurchasedItem = (item, purchaseForceRefund) => {
  const filteredDiscounts = item.discounts?.filter(
    (discount) => discount.type !== 'TRANSACTION',
  );
  const discounts = filteredDiscounts.map((discount) => {
    const itemData = parseDiscount(discount);
    return itemData;
  });
  // if transaction data from cms admin,
  // item.original_price will be None
  const isRefund =
    item.status === 'REFUNDED' ||
    Number(item.quantity) < 0 ||
    Number(item.value) < 0;
  const status = isRefund ? 'REFUNDED' : item.status;
  const forceNegative = purchaseForceRefund || isRefund;
  const quantity = forceNegative
    ? negativeNumber(item.quantity)
    : Number(item.quantity);
  const value = Math.abs(item.value) * quantity;
  const originalPrice = item.original_price
    ? Math.abs(item.original_price) * quantity
    : value;
  const purchasedItem = {
    id: item.id,
    name: item.name,
    quantity,
    originalPrice,
    value,
    status,
    discounts,
  };
  return purchasedItem;
};

const parseSingleTransaction = (data) => {
  const showTransactionRefundValue = [
    'REFUNDED',
    'PARTIALLY_REFUNDED',
    'REFUND',
  ].includes(data.event_type);
  const transactionRefund = ['REFUNDED', 'REFUND'].includes(data.event_type);
  let subtotal = 0.0;
  const purchasedItems = data.purchased_items?.map((purchasedItem) => {
    const itemData = parsePurchasedItem(purchasedItem, transactionRefund);
    subtotal += Number(itemData.value);
    return itemData;
  });
  const shippingFee = Number(data.shipping_fee);
  const otherCharge = Number(data.other_charge);
  const discounts = data.discounts
    ?.filter((discount) => discount.type !== 'ITEM')
    ?.map((discount) => {
      const itemData = parseDiscount(discount);
      return itemData;
    });

  const eventType = data.event_type;
  const orderStatus = TransactionEventTypeDict[eventType];
  let paidAmount = null;
  if (data?.paid_amount) {
    if (transactionRefund) {
      paidAmount = negativeNumber(data.paid_amount);
    } else {
      paidAmount = Number(data.paid_amount);
    }
  }

  const store = getTranslationData(data?.store, i18n.locale);
  let storeName = store?.name;
  if (data?.store?.third_party_store_code) {
    storeName = `${store?.name} (${data?.store?.third_party_store_code})`;
  }

  const item = {
    id: data.id,
    receiptNumber: data.invoice_id,
    receiptDateTime: getActivityLogDateTime(data.date),
    createDateTime: getActivityLogDateTime(data.creation_date),
    eventType,
    orderStatus,
    storeName,

    purchasedItems,

    shippingFee,
    otherCharge,

    subtotal,
    pointToCash: negativeNumber(data.point_to_cash),
    discountAmount: negativeNumber(data.discount_amount),
    discounts,

    paidAmount,
    total: transactionRefund
      ? negativeNumber(data.total_value)
      : Number(data.total_value),
    refund: showTransactionRefundValue ? negativeNumber(data.refund) : null,

    remarks: data.remarks,
  };
  return item;
};

const parseTransactionList = (data) => {
  if (!data || data.length === 0) {
    return null;
  }
  const dataList = data.map((item) => {
    const totalValue =
      item.paid_amount === null ? item.total_value : item.paid_amount;
    const forceNegative = ['REFUNDED', 'REFUND'].includes(item.event_type);
    const datetime = new Date(item.date).getTime();
    const groupID = Math.floor(datetime / (1000 * 60 * 60 * 24));
    const parsedData = {
      id: item.id,
      groupName: getActivityLogDate(item.date),
      groupID,
      receiptNumber: item.invoice_id,
      receiptDate: getReceiptDate(item.date),
      receiptDateTime: getActivityLogDateTime(item.date),
      receiptAmount: forceNegative
        ? enLocaleDollarFormatter(negativeNumber(totalValue))
        : enLocaleDollarFormatter(totalValue),
      createDateTime: getActivityLogDateTime(item.creation_date),
    };
    return parsedData;
  });
  return dataList;
};

export default {
  namespace: 'transaction',
  state: {
    rewardClaimList: [],
    pageLink: '',
    storeList: [],
    storePageLink: '',

    transactionDetail: {},
    transactionDetailNetworkError: false,
    transactionDetailLoading: false,

    transactionOriginList: [],
    transactionNetWorkError: false,
    transactionNextLink: '',
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleRewardClaimList(state, { payload }) {
      const rewardClaimList = parseRewardClaimList(payload.data);
      const { pageLink } = payload;
      return {
        ...state,
        ...payload,
        rewardClaimList,
        pageLink,
      };
    },

    assembleRewardClaimListLoadMore(state, { payload }) {
      const rewardClaimList = parseRewardClaimList(payload.data);
      const { pageLink } = payload;
      return {
        ...state,
        ...payload,
        rewardClaimList: [...state.rewardClaimList, ...rewardClaimList],
        pageLink,
      };
    },

    assembleStore(state, { payload }) {
      const storeList = getStoreList(payload.data);
      const { storePageLink } = payload;
      return {
        ...state,
        ...payload,
        storeList,
        storePageLink,
      };
    },

    storeLoadMoreAssemble(state, { payload }) {
      const storeList = getStoreList(payload.data);
      const { storePageLink } = payload;
      return {
        ...state,
        ...payload,
        storeList: [...state.storeList, ...storeList],
        storePageLink,
      };
    },
    assembleSingleTransaction(state, { payload }) {
      const { data } = payload;
      console.log('data', data);
      const transactionDetail = parseSingleTransaction(data);
      console.log('transactionDetail', transactionDetail);
      return {
        ...state,
        transactionDetail,
        transactionDetailNetworkError: false,
        transactionDetailLoading: false,
      };
    },
    assembleTransactionList(state, { payload }) {
      const { data, pageLink } = payload;
      const transactionOriginList = parseTransactionList(data);
      console.log('kevin@317', typeof pageLink, pageLink);
      return {
        ...state,
        transactionOriginList,
        transactionNextLink: pageLink,
      };
    },
    assembleTransactionListLoadmore(state, { payload }) {
      const { data, pageLink } = payload;
      const transactionOriginList = parseTransactionList(data);
      console.log('kevin@327', typeof pageLink, pageLink);
      return {
        ...state,
        transactionOriginList: [
          ...state.transactionOriginList,
          ...transactionOriginList,
        ],
        transactionNextLink: pageLink,
      };
    },
    clearTransactionList(state, { payload }) {
      return {
        ...state,
        transactionOriginList: [],
        transactionNetWorkError: false,
        transactionNextLink: '',
      };
    },
  },

  effects: {
    refreshRewardClaimList: [
      function* ({ payload }, { call, put, select }) {
        const userId = yield select((state) => state.users.userInfo.uid);
        const pageLink = yield select((state) => state.transaction.pageLink);
        const oldData = yield select(
          (state) => state.transaction.rewardClaimList,
        );
        const hasOldData = oldData && oldData.length;
        const transactionApiServiceWithArgs = [
          TransactionAPIHelper.getRewardClaimList,
          { userId, pageLink },
        ];
        function* onGetDataSuccess(response, nextLink) {
          const { data } = response;
          yield put(
            createAction('assembleRewardClaimList')({
              data,
              pageLink: nextLink,
            }),
          );
        }
        yield refresh(
          'TransactionList',
          hasOldData,
          transactionApiServiceWithArgs,
          onGetDataSuccess,
        );
      },
      { type: 'takeLatest' },
    ],

    loadMoreRewardClaimList: [
      function* (_action, { put, select }) {
        const pageLink = yield select((state) => state.transaction.pageLink);
        const data = yield getDataWithDelay(
          TransactionAPIHelper.getRewardClaimList,
          { pageLink },
          'TransactionList',
          true,
        );
        let nextLink = '';
        if (data?.status === 200) {
          nextLink = subHeaderLink(data.headers.link);
          yield put(
            createAction('assembleRewardClaimListLoadMore')({
              data: data.data,
              pageLink: nextLink,
            }),
          );
        }
        yield hasMoreData('TransactionList', nextLink);
      },
      { type: 'takeLatest' },
    ],

    deleteRewardClaim: [
      function* ({ payload }, { call, put, select }) {
        const customerId = yield select((state) => state.users.userInfo.uid);
        const { transactionId, onDone } = payload;
        const response = yield call(TransactionAPIHelper.deleteRewardClaim, {
          userId: customerId,
          transactionId: transactionId,
        });
        onDone();
        if (response.status === 204) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: i18n.t('Delete successfully'),
                showTickIcon: true,
              },
            },
          });
          yield put({ type: 'updateState', payload: { rewardClaimList: [] } });
          yield put({ type: 'refreshRewardClaimList' });
        } else {
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: i18n.t('records:please_check_back_again_later'),
              },
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],

    createRewardClaim: [
      function* ({ payload }, { call, put, select }) {
        const customerId = yield select((state) => state.users.userInfo.uid);
        const { data, onSuccess } = payload;
        // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
        // yield sleep(5000);
        const response = yield call(TransactionAPIHelper.createRewardClaim, {
          userId: customerId,
          input: data,
        });
        if (response.status === 201) {
          onSuccess();
          yield put({ type: 'updateState', payload: { rewardClaimList: [] } });
          yield put({ type: 'refreshRewardClaimList' });
        } else {
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: i18n.t('records:please_check_back_again_later'),
              },
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],

    uploadFile: [
      function* ({ payload }, { call, put, select }) {
        const { file, onDone } = payload;
        // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
        // yield sleep(3000);
        const response = yield call(UploadFileAPIHelper.uploadFile, {
          input: getFileData(file),
        });
        if (response.status !== 200) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: i18n.t('records:please_check_back_again_later'),
              },
            },
          });
        }
        onDone(response.data?.file);
      },
      { type: 'takeEvery' },
    ],

    *refreshStoreList({ payload }, { call, put, select }) {
      // const storePageLink = yield select(
      //   (state) => state.transaction.storePageLink
      // );
      const oldData = yield select((state) => state.transaction.storeList);
      const hasOldData = oldData && oldData.length;

      const transactionApiServiceWithArgs = [
        TransactionAPIHelper.getStoreList,
        {
          pageLink: null,
          searchName: payload?.searchName,
          language: i18n.language,
        },
      ];
      function* onGetDataSuccess(response, nextLink) {
        const { data } = response;
        yield put(
          createAction('assembleStore')({ data, storePageLink: nextLink }),
        );
      }
      yield refresh(
        'StoreList',
        hasOldData,
        transactionApiServiceWithArgs,
        onGetDataSuccess,
      );
    },

    storeLoadMore: [
      function* ({ payload }, { put, select }) {
        const storePageLink = yield select(
          (state) => state.transaction.storePageLink,
        );
        const data = yield getDataWithDelay(
          TransactionAPIHelper.getStoreList,
          {
            pageLink: storePageLink,
            searchName: payload?.searchName,
            language: i18n.language,
          },
          'StoreList',
          true,
        );
        let nextLink = '';
        if (data?.status === 200) {
          nextLink = subHeaderLink(data.headers.link);
          yield put(
            createAction('storeLoadMoreAssemble')({
              data: data.data,
              storePageLink: nextLink,
            }),
          );
        }
        yield hasMoreData('StoreList', nextLink);
      },
      { type: 'takeLatest' },
    ],

    refreshTransactionHistory: [
      function* ({ payload }, { call, put, select }) {
        console.log('kevin@562', payload);
        const { oldData, customerID } = yield select((state) => ({
          oldData: state.transaction.transactionOriginList,
          customerID: state.users.userInfo.uid,
        }));
        const hasOldData = oldData && oldData.length > 0;
        const onlyLoadNotRefresh = payload?.onlyLoadNotRefresh;
        if (onlyLoadNotRefresh && hasOldData) {
          return;
        }
        yield put({
          type: 'updateState',
          payload: {
            transactionNetWorkError: false,
          },
        });
        const serviceArgs = [
          TransactionAPIHelper.getTransactionList,
          {
            pageLink: '',
            customerID,
          },
        ];
        function* onSuccess(response, nextLink) {
          const { data } = response;
          yield put({
            type: 'assembleTransactionList',
            payload: {
              data,
              pageLink: nextLink,
            },
          });
        }
        function* onFailure() {
          yield put({
            type: 'updateState',
            payload: {
              transactionNetWorkError: true,
            },
          });
        }
        yield refresh(
          TransactionHistoryKey,
          hasOldData,
          serviceArgs,
          onSuccess,
          onFailure,
        );
      },
      { type: 'takeLatest' },
    ],

    loadMoreTransactionHistory: [
      function* ({ payload }, { call, put, select }) {
        const pageLink = yield select(
          (state) => state.transaction.transactionNextLink,
        );
        if (!pageLink) {
          return;
        }
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            TransactionHistoryLoadingMore: true,
          }),
        );
        const data = yield getDataWithDelay(
          TransactionAPIHelper.getTransactionList,
          { pageLink },
          TransactionHistoryKey,
          true,
        );
        let nextLink = '';
        if (data?.status === 200) {
          nextLink = subHeaderLink(data.headers.link);
          yield put(
            createAction('assembleTransactionListLoadmore')({
              data: data.data,
              pageLink: nextLink,
            }),
          );
        }
        yield hasMoreData(TransactionHistoryKey, nextLink);
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            TransactionHistoryLoadingMore: false,
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    getTransactionDetail: [
      function* ({ payload }, { call, put, select }) {
        yield put({
          type: 'updateState',
          payload: {
            transactionDetailLoading: true,
            transactionDetailNetworkError: false,
          },
        });
        const customerID = yield select((state) => state.users.userInfo.uid);
        const { transactionID } = payload;

        const response = yield call(TransactionAPIHelper.getTransactionDetail, {
          customerID,
          transactionID,
        });
        if (response.status === 200) {
          yield put({
            type: 'assembleSingleTransaction',
            payload: { data: response.data },
          });
        } else {
          yield put({
            type: 'updateState',
            payload: {
              transactionDetailLoading: false,
              transactionDetailNetworkError: true,
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
