import React from "react";
import "./TransactionListItem.scss";
import { connect } from "react-redux";
import i18n from "../../I18n";

@connect(({ transaction }) => ({ ...transaction }))
class TransactionListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  transactionItemDidClicked = () => {
    this.props.clickAction();
    this.setState({
      didClicked: true,
    });
  };

  render() {
    return (
      <div>
        {this.props.hasseparateLine && (
          <div className="transaction_list_separateLine" />
        )}
        <div
          className={
            this.props.isActive
              ? "transaction_list_transactionItem active"
              : "transaction_list_transactionItem"
          }
          onClick={this.transactionItemDidClicked}
        >
          <div className="transactionItem_container">
            <div className="transactionItem_title_area">
              {
                this.props.transactionData.brandImage &&
                (
                  <div className="transactionItem_title_area_left">
                    <img
                      className="transactionItem_icon"
                      src={this.props.transactionData.brandImage}
                      alt=""
                    />
                  </div>
                )
              }
              <div className="transactionItem_title_area_right">
                <div className="transactionItem_title_area_right_top">
                  <div className="transactionItem_creation_date tag-menu-1">
                    {this.props.transactionData.createDate}
                  </div>
                  <div
                    className={`transactionItem_status ${this.props.transactionData.eventType.toLowerCase()} tag-menu-2`}
                  >
                    {i18n.t(this.props.transactionData?.status)}
                  </div>
                </div>
                {this.props.transactionData.brandTitle &&
                  <div className="transactionItem_title subheading">
                    {this.props.transactionData.brandTitle}
                  </div>
                }
              </div>
            </div>
            {/* <div className="transactionItem_line"></div> */}
            <hr className="transactionItem_line" />
            <div className="transactionItem_content">
              <div className="transactionItem_content_item field-text">
                {i18n.t("records:record_upload_receipt")}:
                <span className="transactionItem_content_item_label body-3">
                  {this.props.transactionData.receiptNumber}
                </span>
              </div>
              <div className="transactionItem_content_item field-text">
                {i18n.t("records:receipt_issue_date")}:
                <span className="transactionItem_content_item_label body-3">
                  {this.props.transactionData.receiptDate}
                </span>
              </div>
              <div className="transactionItem_content_item field-text">
                {i18n.t("records:receipt_amount")}:
                <span className="transactionItem_content_item_label body-3">
                  {this.props.transactionData.receiptAmount}
                </span>
              </div>
            </div>
          </div>
          {this.props.isActive && <div className="transactionItem_active" />}
        </div>
      </div>
    );
  }
}

export default TransactionListItem;
