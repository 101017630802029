
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import { createAction } from 'utils';
import {
  ADDITIOONAL_DATA_TYPE,
  ProfileEditState,
} from 'utils/CustomEnums';
import {
  DatetimeTypeAdditionalField,
  DropdownTypeAdditionalField,
  MulitChoicesTypeAdditionalField,
  NumberTypeAdditionalField,
  SingleChoicesTypeAdditionalField,
  TextTypeAdditionalField,
  ToggleTypeAdditionalField
} from './AdditionalFIeld';


const mapStateToProps = ({ users }) => {
  return {
    language: users.language,
    oldAdditionalData: users.userInfo.additionalData,
  };
};
const AdditionalDataSection = ({ sectionConfig, oldAdditionalData, language, isMobileView = false }) => {
  const { name: sectionName, fields: additionalFields, translations: sectionTranslations } = sectionConfig
  const displaySectionTitle = sectionTranslations?.[language] || sectionName

  const [editFieldName, setEditFieldName] = useState("");
  const [tmpAdditionalData, setTmpAdditionalData] = useState({})

  const dispatch = useDispatch()
  
  useEffect(()=>{
    setTmpAdditionalData(oldAdditionalData)
  }, [oldAdditionalData])
  const groupSectionsByTwo = (items) => {
    if (items?.length === 1) {
      return [items]
    }
    const groupedArray = []
    let tmpSubgroup = []
    items?.length > 0 && items.forEach((item, index)=>{
      if (index=== 0) {
        tmpSubgroup.push(item);
        return
      }
      if (index === (items.length - 1) && index % 2 === 0) {
        groupedArray.push([...tmpSubgroup]);
        groupedArray.push([item]);
        return
      } 
      if (index === (items.length - 1) && index % 2 !== 0) {
        groupedArray.push([...tmpSubgroup,item]);
        return
      }
      if (index % 2 === 0) {
        groupedArray.push([...tmpSubgroup]);
        tmpSubgroup = [item]
        return
      }
      tmpSubgroup.push(item)
    })
    return groupedArray
  };
  const groupedSectionConfig = !isMobileView && groupSectionsByTwo(sectionConfig?.fields)

  const renderAdditionalField = (fieldConifg) => {
    const fieldType = fieldConifg?.fieldType
    const isEditable = fieldConifg?.isEditable || false
    const displayTitle = fieldConifg?.translations?.[language]?.display_title || fieldConifg?.displayTitle
    const fieldName = fieldConifg?.fieldName
    const isRequired = fieldConifg?.isRequired || false
    const choices = fieldConifg?.choices || []
    let editButtonState = ProfileEditState.Normal
    if (editFieldName && editFieldName !== fieldName) {
      editButtonState = ProfileEditState.Disable
    } else if (editFieldName) {
      editButtonState = ProfileEditState.Editing
    }

    const value = tmpAdditionalData?.[fieldName] || ""
    const onClickEditButton = (startEdit)=>{
      if (startEdit) {
        setEditFieldName(fieldName)
      } else {
        setEditFieldName("")
      }
    }
    const onConfirm = (value) => {
      dispatch(createAction("userInfoTemp/updateCustomerAdditionalField")({
        additionalData: { ...tmpAdditionalData, [fieldName]: value },
        successAction:() => {
          setEditFieldName("")
        }
      }))
    }
    switch (fieldType) {
      case ADDITIOONAL_DATA_TYPE.TEXT:
        return (
          <TextTypeAdditionalField
            title={displayTitle}
            value={value}
            isRequired={isRequired}
            isEditable={isEditable}
            editButtonState={editButtonState}
            onClickEditButton={onClickEditButton}
            onConfirm={onConfirm}
          />
        );
      case ADDITIOONAL_DATA_TYPE.NUMBER:
        return (
          <NumberTypeAdditionalField
            title={displayTitle}
            value={value}
            isRequired={isRequired}
            isEditable={isEditable}
            editButtonState={editButtonState}
            onClickEditButton={onClickEditButton}
            onConfirm={onConfirm}
          />
        );
      case ADDITIOONAL_DATA_TYPE.DATETIME:
        return (
          <DatetimeTypeAdditionalField
            title={displayTitle}
            value={value}
            isRequired={isRequired}
            isEditable={isEditable}
            editButtonState={editButtonState}
            onClickEditButton={onClickEditButton}
            onConfirm={onConfirm}
            language={language}
          />
        );
      case ADDITIOONAL_DATA_TYPE.TOGGLE:
        const toggleDisplayTips = fieldConifg?.translations?.[language]?.tips || fieldConifg?.tips
        return (
          <ToggleTypeAdditionalField
            title={displayTitle}
            tips={toggleDisplayTips}
            value={value}
            isEditable={isEditable}
            disabled={editButtonState === ProfileEditState.Disable}
            onConfirm={onConfirm}
          />
        );
      case ADDITIOONAL_DATA_TYPE.CHECKBOX:
        const checkboxDisplayTips = fieldConifg?.translations?.[language]?.tips || fieldConifg?.tips
        return (
          <ToggleTypeAdditionalField
            title={displayTitle}
            tips={checkboxDisplayTips}
            value={value}
            isEditable={isEditable}
            disabled={editButtonState === ProfileEditState.Disable}
            onConfirm={onConfirm}
          />
        );
      case ADDITIOONAL_DATA_TYPE.DROPDOWN:
        return (
          <DropdownTypeAdditionalField
            title={displayTitle}
            value={value}
            isRequired={isRequired}
            isEditable={isEditable}
            editButtonState={editButtonState}
            onClickEditButton={onClickEditButton}
            onConfirm={onConfirm}
            choices={choices}
            language={language}
          />
        );
      case ADDITIOONAL_DATA_TYPE.SINGLE_CHOICES:
        return (
          <SingleChoicesTypeAdditionalField
            title={displayTitle}
            value={value}
            isRequired={isRequired}
            isEditable={isEditable && choices?.length > 0}
            editButtonState={editButtonState}
            onClickEditButton={onClickEditButton}
            onConfirm={onConfirm}
            choices={choices}
            language={language}
            onCancel={()=>{
              setEditFieldName("")
            }}
          />
        );
      case ADDITIOONAL_DATA_TYPE.MULTI_CHOICES:
        const valueList =(value && value?.split(",")) || []
        return (
          <MulitChoicesTypeAdditionalField
            title={displayTitle}
            value={valueList}
            isRequired={isRequired}
            isEditable={isEditable && choices?.length > 0}
            editButtonState={editButtonState}
            onClickEditButton={onClickEditButton}
            onConfirm={onConfirm}
            choices={choices}
            language={language}
            onCancel={() => {
              setEditFieldName("")
            }}
          />
        );
      default:
        return (<></>)
    }
  }
  if (!sectionName) { return <></> }
  return (
    <Container className="common-shadow-section-container account-direct-marketing-section">
      <div className="common-section-title title-medium-2">
        {displaySectionTitle}
      </div>

      {isMobileView ? additionalFields?.map(field => (
        <Row>
          <Col className="common-account-col">
            {renderAdditionalField(field)}
          </Col>
        </Row>
      )) : groupedSectionConfig?.map(fields => (
        <Row>
          <Col className="common-account-col">
            {renderAdditionalField(fields[0])}
          </Col>
          <Col className="common-account-col">
            {renderAdditionalField(fields[1])}
          </Col>
        </Row>
      )) }
    </Container>
  )
}
export default connect(mapStateToProps)(
  (AdditionalDataSection),
);