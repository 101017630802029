import i18n from 'I18n';

import { ACTION_BUTTON_STATUS } from 'component/campaign/CheckInSection';
import {
  CAMPAIGN_TYPE_COUPON,
  CAMPAIGN_TYPE_EARNING,
  CampaignAPIHelper,
  EARNING_RULE_BIRTH,
  EARNING_RULE_GPS,
  EARNING_RULE_NEW_MEMBER,
  EARNING_RULE_PURCHASE,
  EARNING_RULE_QR_CODE,
  EARNING_RULE_REFER,
  CAMPAIGN_TYPE_STAMP,
  EARNING_RULE_LEVEL_UPGRADE,
} from 'services/CampaignAPIHelper';

import {
  createAction,
  delay,
  getTranslationData,
  generateTakeLeading,
  enLocaleNumberFormatter,
} from 'utils';
import {
  checkIfStarted,
  errorMessageIfFailedToGetRewards,
} from 'utils/CampaignUtil';
import { NO_LEVEL } from 'utils/Config';
import {
  convertDateToStamp,
  formatDate,
  getCampaignDetailEndDate,
  getCurrentDateFormatted,
} from 'utils/DateTimeUtils';

const takeLeading = generateTakeLeading('campaignDetail');

const getDefaultInstructionTitle = (campaignType) => {
  if (campaignType === CAMPAIGN_TYPE_COUPON) {
    return i18n.t('campaignDetail:howToUse');
  }
  if ([CAMPAIGN_TYPE_STAMP, CAMPAIGN_TYPE_EARNING].includes(campaignType)) {
    return i18n.t('campaignDetail:howToEarn');
  }
  return null;
};

const checkIsCoupon = (campaignType) => campaignType === CAMPAIGN_TYPE_COUPON;

const parseCouponCampaignData = (apiData) => {
  const requiredPoint = apiData.coupon_campaign_type_required_points || 0;
  const point = enLocaleNumberFormatter(requiredPoint);
  let status =
    requiredPoint > 0 ? ACTION_BUTTON_STATUS.NORMAL : ACTION_BUTTON_STATUS.GET;
  if (!checkIfStarted(apiData.start_date)) {
    status = ACTION_BUTTON_STATUS.UNAVAILABLE;
  }
  return {
    campaignType: CAMPAIGN_TYPE_COUPON,
    earningRuleType: null,
    textTitle: i18n.t('campaignDetail:pointsRequired'),
    point,
    status,
    startDate: convertDateToStamp(apiData.start_date),
    endDate: convertDateToStamp(apiData.end_date),
  };
};

const parseEarning = (apiData) => {
  const earningRule = apiData.earning_campaign_type_earning_rule;
  const earningType = earningRule.type;
  let keyPrefix = null;
  if (earningType === EARNING_RULE_GPS) {
    keyPrefix = 'gps_check_in_type_';
  } else if (earningType === EARNING_RULE_QR_CODE) {
    keyPrefix = 'qr_code_scanning_type_';
  } else if (earningType === EARNING_RULE_REFER) {
    keyPrefix = 'member_referral_type_';
  } else if (earningType === EARNING_RULE_BIRTH) {
    keyPrefix = 'birthday_type_';
  } else if (earningType === EARNING_RULE_NEW_MEMBER) {
    keyPrefix = 'new_member_type_';
  } else if (earningType === EARNING_RULE_PURCHASE) {
    keyPrefix = 'general_purchase_type_';
  }

  if (keyPrefix === null) {
    return {};
  }
  let point = null;
  let textTitle = null;
  let rewardType = 'POINTS';
  if (earningRule[`${keyPrefix}reward_type`] === 'COUPON') {
    point = `X ${earningRule[`${keyPrefix}coupon_reward_type_quantity`]}`;
    textTitle = i18n.t('campaignDetail:couponsEarn');
    rewardType = 'COUPON';
  } else {
    if (earningType === EARNING_RULE_PURCHASE) {
      point =
        `${enLocaleNumberFormatter(
          earningRule[
            `general_purchase_type_points_reward_type_points_per_x_dollars_spent`
          ] || 0,
        )} ` + i18n.t('points');
    } else {
      point = `+${enLocaleNumberFormatter(
        earningRule[`${keyPrefix}points_reward_type_points`] || 0,
      )}`;
    }
    textTitle = i18n.t('campaignDetail:pointsEarn');
  }
  return {
    campaignType: CAMPAIGN_TYPE_EARNING,
    earningRuleType: earningType,
    rewardType,
    textTitle,
    point,
    status: checkIfStarted(apiData.start_date)
      ? ACTION_BUTTON_STATUS.NORMAL
      : ACTION_BUTTON_STATUS.UNAVAILABLE,
    startDate: convertDateToStamp(apiData.start_date),
    endDate: convertDateToStamp(apiData.end_date),
  };
};

const parseStamp = (apiData) => {
  return {
    campaignType: apiData.type,
    earningRuleType: null,
    redeemedRewards: apiData.stamp_redeemed_rewards || [],
    earnedStamps: apiData.earned_stamps,
    startDate: convertDateToStamp(apiData.start_date),
    endDate: convertDateToStamp(apiData.end_date),
    stampBadge: {
      badgeSlot: apiData.stamp_campaign_type_empty_slot_stamp_image,
      badgeAcquired: apiData.stamp_campaign_type_acquired_stamp_image,
      giftSlot: apiData.stamp_campaign_type_empty_gift_slot_image,
      giftAcquire: apiData.stamp_campaign_type_collected_gift_image,
    },
  };
};

const parseActionBarData = (apiData) => {
  let campaignType = apiData.type;
  const status = ACTION_BUTTON_STATUS.NORMAL;
  if (campaignType === CAMPAIGN_TYPE_COUPON) {
    return parseCouponCampaignData(apiData);
  }
  if (campaignType === CAMPAIGN_TYPE_EARNING) {
    return parseEarning(apiData);
  }
  if (campaignType === CAMPAIGN_TYPE_STAMP) {
    return parseStamp(apiData);
  }
  campaignType = null;
  return {
    campaignType,
    status,
  };
};

const getBrand = (brand, currentLanguage) => {
  const brandData = {
    shown: false,
    iconUrl: null,
    name: '',
    description: null,
  };

  if (brand) {
    brandData.shown = brand.name ? true : false;
    brandData.iconUrl = brand.icon_url;
    // const baseData = getTranslationData(brand, currentLanguage);
    const baseData = brand;
    brandData.name = baseData.name;
    brandData.description = baseData.description;
  }
  return brandData;
};

const parseCampaignDetail = (
  apiData,
  currentLanguage,
  lastUpdateTime = null,
) => {
  const baseData = getTranslationData(apiData, i18n.language);
  // const baseData = apiData;
  const hasDescription = (baseData.detail_section_content || '').length > 0;
  const hasInstructions =
    (baseData.instruction_section_content || '').length > 0;

  const data = {
    campaignType: apiData.type,
    inBlackoutPeriod: apiData.is_within_blackout_period,
    images: baseData.photos_url || [
      'campaignDetail/slider/test1.jpg',
      'campaignDetail/slider/test2.jpeg',
      'campaignDetail/slider/test3.jpg',
    ],
    basic: {
      endDate: getCampaignDetailEndDate(apiData.end_date),
      isFeatured: apiData.is_featured,
      isOnlyForYou: apiData.is_only_for_you,
      isActive: apiData.is_active,
      outOfStock: !!(apiData.coupon_campaign_type_coupon_stock === 0),
      name: baseData.name,
    },
    brand: getBrand(getTranslationData(apiData.brand), currentLanguage),
    // brand: apiData.brand_section,
    summary: baseData.short_description,
    description: {
      shown: hasDescription,
      title: baseData.detail_section_title || i18n.t('campaignDetail:details'),
      content: baseData.detail_section_content,
      showTopDiver: hasDescription && hasInstructions,
    },
    instructions: {
      shown: hasInstructions,
      title:
        baseData.instruction_section_title ||
        getDefaultInstructionTitle(apiData.type),
      content: baseData.instruction_section_content,
      showBottomDivder: false,
    },
    coupon: {
      isCoupon: checkIsCoupon(apiData.type),
      requiredPoints: apiData.coupon_campaign_type_required_points,
      leftStock: apiData.coupon_campaign_type_coupon_stock,
      lastUpdateTime:
        lastUpdateTime === null
          ? getCurrentDateFormatted(true, false)
          : formatDate(lastUpdateTime, null, true, false),
      // stores:
      //   (apiData.coupon_campaign_type_coupon_template || {})
      //     .applicable_stores || [],
      stores: apiData.campaign_stores?.map((store) =>
        getTranslationData(store),
      ),
      title: (apiData.coupon || {}).title,
      perLimit: apiData.coupon_campaign_type_per_head_limit,
    },
  };
  return data;
};

const parseStampTask = (
  data,
  currentLanguage,
  actionBar
) => {
  const typeEarningRule = {
    MEMBER_REFERRAL: {
      text: i18n.t('campaignDetail:referAFriend'),
      cardType: 'button',
    },
    QR_CODE_SCANNING: {
      text: i18n.t('campaignDetail:scanQrCode'),
      cardType: 'button',
    },
    GPS_CHECK_IN: {
      text: i18n.t('campaignDetail:checkIn'),
      cardType: 'button',
    },
    GENERAL_PURCHASE: {
      text: i18n.t('records:record_upload_receipt'),
      cardType: 'button',
    },
  };

  const parseList = data.map((aData) => {
    const translateData = getTranslationData(aData, currentLanguage);
    const { type } = translateData;
    const startDate = convertDateToStamp(actionBar.startDate)
    const isStarted = checkIfStarted(startDate);

    let rewardStampCount;
    let rewardString;
    let rewardStringSecond = '';
    if (aData.type === EARNING_RULE_PURCHASE) {
      rewardString = `+${aData.general_purchase_type_stamp_reward_type_stamps_per_x_dollars_spent}`
      rewardStringSecond = `/HK$${aData.general_purchase_type_stamp_reward_type_x}`
    } else {
      const rewardKeyPostfix = '_type_stamp_reward_type_quantity';
      const earningRuleKeyMapping = {
        [EARNING_RULE_REFER]: 'member_referral',
        [EARNING_RULE_BIRTH]: 'birthday',
        [EARNING_RULE_GPS]: 'gps_check_in',
        [EARNING_RULE_QR_CODE]: 'qr_code_scanning',
        [EARNING_RULE_NEW_MEMBER]: 'new_member',
        [EARNING_RULE_LEVEL_UPGRADE]: 'level_upgrade',
      }
      const key = earningRuleKeyMapping[aData.type] + rewardKeyPostfix;
      rewardStampCount = aData[key];
      rewardString = `+${rewardStampCount}`;
    }

    return {
      title: translateData.name,
      rewardStampCount,
      rewardString,
      rewardStringSecond,
      earningRuleID: translateData.id,
      disabled: !isStarted,
      ...typeEarningRule[type],
      buttonStatus: checkIfStarted(startDate)
        ? ACTION_BUTTON_STATUS.NORMAL
        : ACTION_BUTTON_STATUS.UNAVAILABLE,
      earningRuleType: type,
      endDate: convertDateToStamp(actionBar.endDate),
      startDate,
      isStarted,
    }
  })

  return {
    apiResult: data,
    parseList
  }
}


const parseBadge = (
  data,
) => {
  return data.sort((a, b) => a.required_stamps - b.required_stamps)
}

export const getParticipateCampaignSuccessToast = ({
  points,
  tpe,
  couponTemplate,
  couponQuantity,
  inStampCampaign,
  stampCampaign,
}) => {
  let toastMessage = ""
  if (NO_LEVEL) {
    toastMessage = i18n.t('campaignDetail:earnPointsNoLevelToast', {
      points: points || 0,
    });
  } else {
    toastMessage = i18n.t('campaignDetail:earnPointsToast', {
      points: points || 0,
      tpe: tpe || 0,
    });
  }
  

  if (couponTemplate && couponQuantity) {
    const couponTemplateTranslationData = getTranslationData(couponTemplate);
    toastMessage = i18n.t('campaignDetail:earnCouponToast', {
      couponTemplateName: couponTemplateTranslationData?.name || '',
      couponQuantity: couponQuantity || 0,
    })
  }

  if (inStampCampaign) {
    const stampCampaignName = stampCampaign?.data?.basic?.name || '';
    toastMessage = i18n.t('campaignDetail:stampEarnPointsToast', {
      points: points || 0,
      stampCampaignName,
    });

    if (couponQuantity) {
      toastMessage = i18n.t('campaignDetail:stampEarnCouponToast', {
        stampCampaignName,
        couponQuantity: couponQuantity || 0,
      })
    }
  }

  return toastMessage;
}


export default {
  namespace: 'campaignDetail',
  state: {
    // data: {},
    // apiResult: {},
    // isLoading: false,
    // actionBar: {},
    selectedStore: {},
  },
  reducers: {
    updateState(state, { payload }) {
      console.log('@156 update state');
      return { ...state, ...payload };
    },
    updateCampaignDetail(state, { payload }) {
      const { data, campaignID } = payload;

      return {
        ...state,
        [campaignID]: {
          ...state[campaignID],
          ...(data.stores
            ? { data: { ...state[campaignID].data, ...data } }
            : data),
        },
      };
    },
    selectStore(state, { payload }) {
      return {
        ...state,
        selectedStore: payload,
      };
    },
    updateStampActionBarStatus (state, { payload }) {
      const { data, campaignID, earningRuleID} = payload;
      const { buttonStatus } = data;
      const { parseList } = state[campaignID].data.stampCampaigns.tasks;
      const newList = parseList.map((aData) => {
        const newData = { ...aData };
        if (newData.earningRuleID === earningRuleID) {
          newData.buttonStatus = buttonStatus;
        }
        return newData;
      });
      return {
        ...state,
        [campaignID]: {
          ...state[campaignID],
          data: {
            ...state[campaignID].data,
            stampCampaigns: {
              ...state[campaignID].data.stampCampaigns,
              tasks: {
                ...state[campaignID].data.stampCampaigns.tasks,
                parseList: newList,
              }
            }
          }
        }
      }
    },
  },
  effects: {
    *setCampaignID({ payload }, { put }) {
      const { campaignID } = payload;
      yield put(createAction('updateState')({ campaignID, isLoading: true }));
    },
    updateCampaignDetailActionBar: [
      function* ({ payload }, { call, put, select }) {
        const { campaignID } = payload;
        const details = yield select((state) => state.campaignDetail);
        if (campaignID in details) {
          const currentLanguage = yield select(
            (state) => state.users.language
          );
          const response = yield call(
            CampaignAPIHelper.getCampaignDetail,
            campaignID
          );
          if (response.status === 200) {
            const actionBarData = parseActionBarData(response.data, currentLanguage);
            yield put.resolve(
              createAction('updateState')({
                [campaignID]: {
                  ...details[campaignID],
                  actionBar: actionBarData,
                },
              })
            );
          }
        }
      },
      { type: 'takeLatest' },
    ],
    getCampaignDetail: [
      function* ({ payload }, { call, put, select }) {
        const { campaignID } = payload;

        const details = yield select((state) => state.campaignDetail);
        if (campaignID in details) {
          yield put(
            createAction('updateCampaignDetail')({
              campaignID,
              data: { isLoading: true, hasError: false },
            }),
          );
        }
        const response = yield call(
          CampaignAPIHelper.getCampaignDetail,
          campaignID,
        );

        if (response.status === 200) {
          const data = parseCampaignDetail(response.data, "");
          yield put(
            createAction('updateState')({
              [campaignID]: {
                data,
                apiResult: response.data,
                isLoading: true,
                actionBar: parseActionBarData(response.data),
              },
            }),
          );
          const { campaignType } = data;
          if (campaignType === CAMPAIGN_TYPE_STAMP) {
            yield put(
              createAction('getEarningRulesForStampCampaign')({
                campaignID,
              })
            );
          } else {
            yield put(
              createAction('updateCampaignDetail')({
                campaignID,
                data: {
                  isLoading: false,
                },
              })
            )
          }
        } else {
          yield put(
            createAction('updateState')({
              hasError: true,
            })
          )
        }
      },
      { type: 'takeLatest' },
    ],
    getCampaignBlackout: [
      function* ({ payload }, { call, put, select }) {
        const { campaignID, onSuccess, onFailure } = payload;
        const response = yield call(
          CampaignAPIHelper.getCampaignBlackout,
          campaignID,
        );

        if (response.status === 200) {
          console.log('response.data', response.data);
          const inBlackoutPeriod = response.data?.in_blackout_period;
          if (inBlackoutPeriod) {
            onFailure();
          } else {
            onSuccess();
          }
        } else {
          onFailure();
        }
      },
      { type: 'takeLatest' },
    ],
    *getCampaignDetailAndGoRedeemPage({ payload }, { call }) {
      const { campaignID, callBackFunction } = payload;
      const response = yield call(
        CampaignAPIHelper.getCampaignDetail,
        campaignID,
      );
      if (response.status === 200) {
        const data = parseCampaignDetail(response.data, '');

        callBackFunction(data);
      }
    },
    verifyQrCode: [
      function* ({ payload }, { call, put, select }) {
        const { 
          qrCodeString, 
          campaignID, 
          earningRuleID, 
          inStampCampaign,
          rewardStampCount,
        } = payload;
        const customerID = yield select((state) => state.users.userInfo.uid);
        const appInstallationID = yield select(
          (state) => state.users.appInstallationID,
        );
        const startTime = Date.now();
        yield put(
          createAction('updateActionButtonStatus')({
            earningRuleID,
            campaignID,
            status: ACTION_BUTTON_STATUS.LOADING,
            inStampCampaign,
          })
        );
        const response = yield call(
          CampaignAPIHelper.participateCampaign,
          customerID,
          {
            qr_code: qrCodeString,
            app_installation: appInstallationID, // hardcoded now
            stamp_earning_rule: earningRuleID,
            campaign: campaignID,
            customer: customerID,
          },
        );
        const endTime = Date.now();
        if (endTime - startTime < 1000) {
          yield call(delay, 1000 - endTime + startTime);
        }
        if (response.status < 300) {
          yield put(
            createAction('participateCampaignSuccess')({
              earningRuleID,
              campaignID,
              inStampCampaign,
              rewardStampCount,
              responseData: response.data,
            })
          );
        } else {
          yield put(
            createAction('participateCampaignFailed')({
              campaignID,
              response,
              earningRuleID,
              inStampCampaign,
            })
          );
        }
      },
      { type: 'takeLatest' },
    ],
    verifyGPS: [
      function* ({ payload }, { call, put, select }) {
        const {
          latitude, 
          longitude, 
          campaignID, 
          earningRuleID, 
          inStampCampaign,
          rewardStampCount,
        } = payload;
        const customerID = yield select((state) => state.users.userInfo.uid);
        const appInstallationID = yield select(
          (state) => state.users.appInstallationID,
        );
        const startTime = Date.now();
        yield put(
          createAction('updateActionButtonStatus')({
            earningRuleID,
            campaignID,
            status: ACTION_BUTTON_STATUS.LOADING,
            inStampCampaign,
          })
        );
        const response = yield call(
          CampaignAPIHelper.participateCampaign,
          customerID,
          {
            check_in_latitude: latitude.toFixed(6),
            check_in_longitude: longitude.toFixed(6),
            app_installation: appInstallationID,
            stamp_earning_rule: earningRuleID,
            campaign: campaignID,
            customer: customerID,
          },
        );
        const endTime = Date.now();
        if (endTime - startTime < 1000) {
          yield call(delay, 1000 - endTime + startTime);
        }
        if (response.status < 300) {
          yield put(
            createAction('participateCampaignSuccess')({
              earningRuleID,
              campaignID,
              inStampCampaign,
              rewardStampCount,
              responseData: response.data,
            })
          );
        } else {
          yield put(
            createAction('participateCampaignFailed')({
              campaignID,
              response,
              earningRuleID,
              inStampCampaign,
            })
          );
        }
      },
      { type: 'takeLatest' },
    ],
    participateCampaignSuccess: [
      function* ({ payload }, { call, put, select }) {
        const { 
          earningRuleID, 
          campaignID, 
          inStampCampaign,
          rewardStampCount,
          responseData,
        } = payload;
        if (earningRuleID) {
          yield put.resolve(
            createAction('updateCampaignDetailActionBar')({
              campaignID,
            })
          );
          const updateStampCountPayload = {
            campaignID,
            rewardStampCount,
          };
          yield put.resolve(createAction('campaignList/updateStampCampaignEarnedStampCount')(updateStampCountPayload));
          yield put.resolve(createAction('offerList/updateStampCampaignEarnedStampCount')(updateStampCountPayload));
        }
        yield put(
          createAction('updateActionButtonStatus')({
            earningRuleID,
            campaignID,
            status: ACTION_BUTTON_STATUS.TICK,
            inStampCampaign,
          })
        );
        yield put(createAction('users/fetchAvailablePoints')());
        if (rewardStampCount) {
          yield put({
            type: "navBars/updateState",
            payload: {
              toastShowing: {
                value: true,
                content: i18n.t('campaignDetail:successfullyEarnedStamp', {
                  count: rewardStampCount
                }),
              },
            },
          });
        }
        if (!inStampCampaign) {
          const toastMessage = getParticipateCampaignSuccessToast({
            points: responseData.reward_points,
            tpe: responseData.reward_tpe,
            couponQuantity: responseData.reward_coupon_quantity,
            couponTemplate: responseData.reward_coupon,
            inStampCampaign: false,
          })
          yield put({
            type: "navBars/updateState",
            payload: {
              toastShowing: {
                value: true,
                content: toastMessage,
              },
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],

    participateCampaignFailed: [
      function* ({ payload }, { call, put }) {
        const { campaignID, response, error, earningRuleID, inStampCampaign } = payload;
        let errorMessage = errorMessageIfFailedToGetRewards(response, error);
        yield put(
          createAction('updateActionButtonStatus')({
            earningRuleID,
            campaignID,
            status: ACTION_BUTTON_STATUS.NORMAL,
            inStampCampaign,
          })
        );
        yield put({
          type: "navBars/updateState",
          payload: {
            toastShowing: {
              value: true,
              content: errorMessage,
            },
          },
        });
      },
      { type: 'takeLatest' },
    ],

    updateActionButtonStatus: [
      function* ({ payload }, { call, put, select }) {
        const { earningRuleID, campaignID, status, inStampCampaign } = payload;
        if (inStampCampaign) {
          yield put(
            createAction('updateStampActionBarStatus')({
              campaignID,
              earningRuleID,
              data: { buttonStatus: status },
            })
          )
        } else {
          yield put(
            createAction('updateState')({
              actionButtonStatus: status,
            })
          )
        }
      },
      { type: 'takeLatest' },
    ],


    getEarningRulesForStampCampaign: [
      function* ({ payload }, { call, put, select }) {
        const { campaignID } = payload;
        const { data, actionBar } = yield select((state) => state.campaignDetail[campaignID]);
        const currentLanguage = yield select(
          (state) => state.users.language,
        );
        const response = yield call(
          CampaignAPIHelper.getEarningRulesForStamp,
          campaignID
        );
        if (response.status < 300) {
          const stampCampaigns = { tasks: parseStampTask(response.data, currentLanguage, actionBar) }
          const newData = { ...data, stampCampaigns }
          yield put.resolve(
            createAction('updateCampaignDetail')({
              campaignID,
              data: {
                data: newData,
              },
            })
          );
          yield put(
            createAction('getRewardsForStampCampaign')({
              campaignID,
            })
          );
        } else {
          yield put(
            createAction('updateCampaignDetail')({
              campaignID,
              data: {
                isLoading: false,
              },
            })
          )
        }
      },
      { type: 'takeLatest' },
    ],

    getRewardsForStampCampaign: [
      function* ({ payload }, { call, put, select }) {
        const { campaignID } = payload;
        const { data } = yield select((state) => state.campaignDetail[campaignID]);
        const response = yield call(
          CampaignAPIHelper.getRewardsForStampCampaign,
          campaignID,
        )
        if (response.status < 300) {
          const { stampCampaigns } = data;
          const badges = parseBadge(response.data);
          const newStampCampaign = { ...stampCampaigns, badges };
          const newData = { ...data, stampCampaigns: newStampCampaign };
          yield put(
            createAction('updateCampaignDetail')({
              campaignID,
              data: {
                data: newData,
                isLoading: false,
              },
            })
          );
        } else {
          yield put(
            createAction('updateCampaignDetail')({
              campaignID,
              data: {
                isLoading: false,
              },
            })
          );
        }
      },
      { type: 'takeLatest' },
    ],

  
    watcher: takeLeading(
      'customerAcquireStampReward',
      function* ({ payload }, { call, put, select }) {
        const { campaignID, storeID, rewardID, stampRewardData, stampRewardCouponQuantity } = payload;
        const startTime = Date.now();
        yield put(
          createAction('updateState')({
            actionButtonStatus: ACTION_BUTTON_STATUS.LOADING,
          })
        );
        try {
          const response = yield call(CampaignAPIHelper.customerAcquireStampReward, campaignID, {
            store_id: storeID,
            reward_id: rewardID,
            app_installation: null,
          });
          const endTime = Date.now();
          if (endTime - startTime < 1000) {
            yield call(delay, 1000 - endTime + startTime);
          }
          if (response.status < 300) {
            // duplicate acquire will raise 409 error
            // to update badge icon status status
            yield put(
              createAction('campaignDetail/updateCampaignDetailActionBar')({
                campaignID,
              })
            );
            yield call(delay, 1000);
            const campaignDetail = yield select((state) => {
              const campaignDetails = state.campaignDetail;
              if (campaignID in campaignDetails) {
                return campaignDetails[campaignID]
              }
              return null
            });
            // console.log("@@stamp campaign detail:", campaignDetail)
            const toastMessage = getParticipateCampaignSuccessToast({
              points: stampRewardData && stampRewardData.reward_type === "POINTS" ? stampRewardData.quantity : 0,
              tpe: 0,
              couponQuantity: stampRewardCouponQuantity || 0,
              inStampCampaign: true,
              stampCampaign: campaignDetail,
            })

            yield put({
              type: "navBars/updateState",
              payload: {
                toastShowing: {
                  value: true,
                  content: toastMessage,
                },
              },
            });

            yield put(
              createAction('updateState')({
                actionButtonStatus: ACTION_BUTTON_STATUS.MYCOUPON,
              })
            );
          } else {
            yield put(
              createAction('campaignDetail/updateCampaignDetailActionBar')({
                campaignID,
              })
            );
            yield put(
              createAction('acquireRewardFailed')({
                response,
              })
            );
          }
        } catch (error) {
          const endTime = Date.now();
          if (endTime - startTime < 1000) {
            yield call(delay, 1000 - endTime + startTime);
          }
          yield put(
            createAction('acquireRewardFailed')({
              error,
            })
          );
        }
        // Wait 1 more seconds for animation/ navigation
        yield call(delay, 1000);
      }
    ),

    acquireRewardFailed: [
      function* ({ payload }, { put }) {
        const { response, error } = payload;
        const errorMessage = errorMessageIfFailedToGetRewards(response, error);
        yield put(
          createAction('updateState')({
            actionButtonStatus: ACTION_BUTTON_STATUS.NORMAL,
          }),
        );
        yield put({
          type: 'navBars/updateState',
          payload: {
            toastShowing: {
              value: true,
              content: errorMessage,
            },
          },
        });
      },
    ],

  },
};
