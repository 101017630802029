import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { getMaxCashDollar, POINT_TO_CASH_CONVERT_RATE } from "./PointAndCashSection";
import i18n from "I18n";

import { enLocaleNumberFormatter, createAction, getOuterSiteOrigin } from "utils";
import { SHOPIFY_API_HOST as ENV_SHOPIFY_API_HOST } from 'utils/Config';
import CustomAlert from "component/base/CustomAlert";
import UseCashQRCodeModal from "./UseCashQRCodeModal";

import "./UseCashDollar.scss"

const SHOPIFY_API_HOST = getOuterSiteOrigin(ENV_SHOPIFY_API_HOST);

const mapStateToProps = state => {
  return {
    currentPoints: state.users.availablePoints,
    isOpenInOtherSite: state.users.isOpenInOtherSite,
    cashCodeKey: state.users.cashCodeKey,
    notShowPointToCashTipsModalAgain: state.users.notShowPointToCashTipsModalAgain,
  };
};

function UseCashDollar({
  currentPoints,
  isOpenInOtherSite,
  cashCodeKey,
  notShowPointToCashTipsModalAgain
}) {
  const maxCashDollar = getMaxCashDollar(currentPoints);
  const [inputCashDollar, setInputCashDollar] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const [showConfirmUseCashModal, setShowConfirmUseCashModal] = useState(false);
  const [showUseCashModal, setShowUseCashModal] = useState(false);
  const [notShowModalAgain, setNotShowModalAgain] = useState(notShowPointToCashTipsModalAgain || false);
  const [cashDollarInputError, setCashDollarInputError] = useState();
  const dispatch = useDispatch()
  const payBtnRef = useRef();

  const isButtonDisabled = !inputCashDollar || inputCashDollar < 1 || (isOpenInOtherSite && !selectedStore);

  const storeType = {
    shopifyStore: "shopifyStore",
    physicalStore: "physicalStore"
  }
  const storeTypeConfig = [
    {
      key: storeType.shopifyStore,
      displayTitle: i18n.t(i18n.t("shopify_store")),
      imgSrc: "/profile/currentPoint/shopify-cart.png",
      imgSrcSet: "/profile/currentPoint/shopify-cart@2x.png 2x, /profile/currentPoint/shopify-cart@3x.png 3x"
    },
    {
      key: storeType.physicalStore,
      displayTitle: i18n.t(i18n.t("product:Physical store")),
      imgSrc: "/profile/currentPoint/physical-store.png",
      imgSrcSet: "/profile/currentPoint/physical-store@2x.png 2x, /profile/currentPoint/physical-store@3x.png 3x"
    },
  ]

  const validateInput = () => {
    if (inputCashDollar > maxCashDollar) {
      setCashDollarInputError("exceed_point_balance")
      return false
    }
    return true;
  }

  const handlePayCashDollar = () => {
    if (!validateInput()) {
      return;
    }
    payBtnRef.current.disabled = true;
    if (isOpenInOtherSite) {
      if (selectedStore.key === storeType.shopifyStore) {
        if (notShowPointToCashTipsModalAgain) {
          shopifyCreateCashDiscount()
        } else {
          setShowConfirmUseCashModal(true)
        }
        return;
      }
      if (selectedStore.key === storeType.physicalStore) {
        fetchCashCode({
          successAction: () => {
            setShowUseCashModal(true)
          },
          afterAction: () => {
            payBtnRef.current.disabled = isButtonDisabled
          }
        })
        return;
      }
    } else {
      fetchCashCode({
        successAction: () => {
          setShowUseCashModal(true)
        },
        afterAction: () => {
          payBtnRef.current.disabled = isButtonDisabled
        }
      })
    }
  }

  const shopifyCreateCashDiscount = () => {
    dispatch(createAction("users/shopifyCreatePointToCash")({
      cashDollarAmount: parseInt(inputCashDollar),
      afterSuccessAction: (discountCode) => {
        window.top.postMessage(
          { type: "APPLY_CASH_CODE", value: discountCode },
          SHOPIFY_API_HOST
        )
      },
      afterAction: () => {
        payBtnRef.current.disabled = isButtonDisabled;
      }
    }))
  }
  const fetchCashCode = ({ successAction, afterAction = () => { } }) => {
    if (inputCashDollar) {
      dispatch(createAction("users/createPointToCash")({
        cashDollarAmount: parseInt(inputCashDollar),
        successAction,
        afterAction
      }))
    }
  }
  const cancelPointToCash = () => {
    if (cashCodeKey) {
      dispatch(createAction("users/cancelPointToCash")())
    }
  }

  const refreshPointToCashCode = ({ failedAction }) => {
    dispatch(createAction("users/refreshPointToCash")({
      failedAction
    }))
  }

  const refreshQRCodeAction = () => {
    refreshPointToCashCode({
      failedAction: () => {
        cancelPointToCash()
        setShowUseCashModal(false)
      }
    })
  }

  const handleMessageFromOtherSite = (e) => {
    const { data } = e;
    if (data?.type === 'APPLY_CASH_CODE') {
      if (data?.status === 'SUCCESS') {
        dispatch({
          type: 'navBars/updateState',
          payload: {
            toastShowing: {
              value: true,
              content: i18n.t('successfully_applied'),
              showTickIcon: true,
            },
          },
        });
      }
      if (data?.status === 'FAILED') {
        dispatch({
          type: 'navBars/updateState',
          payload: {
            toastShowing: {
              value: true,
              content: data?.error,
            },
          },
        });
      }

    }

  }

  const getErrorMsg = () => {
    if (cashDollarInputError === "exceed_point_balance") {
      return i18n.t("exceed_point_balance", {
        points: enLocaleNumberFormatter(inputCashDollar * POINT_TO_CASH_CONVERT_RATE)
      })
    }
    return "";
  }

  useEffect(() => {
    window.addEventListener("message", handleMessageFromOtherSite)
    return () => {
      window.removeEventListener("message", handleMessageFromOtherSite)
      dispatch(createAction("users/updateState")({
        cashCodeKey: ""
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="use-cash-dollar-page">
      <div className="use-cash-dollar-page-top-section">
        <div className="use-cash-dollar-title title-small">{i18n.t("use_cash_dollar")}</div>
        <div className="cash-modal-descrition body-1">{i18n.t("use_cash_dollar_description")}</div>
      </div>
      <div className="use-cash-dollar-page-bottom-section">
        <div>
          <div className="cash-modal-secondary-title subheading">{i18n.t("balance")}</div>
          <div className="cash-point-balance-text body-2">
            {i18n.t("cash_dollar_points", {
              cashDollar: enLocaleNumberFormatter(maxCashDollar),
              points: enLocaleNumberFormatter(currentPoints)
            })}
          </div>
          <div className="point-to-cash-rule tag-menu-1">
            {i18n.t("point_to_cash_rule", {
              points: enLocaleNumberFormatter(POINT_TO_CASH_CONVERT_RATE),
              cashDollar: 1
            })}
          </div>
        </div>
        {isOpenInOtherSite ?
          <div>
            <div className="cash-modal-secondary-title subheading">{i18n.t("store:select_store")}</div>
            <div className="store-selector">
              {storeTypeConfig.map((item) => {
                const isSelected = selectedStore?.key === item.key
                return (
                  <div
                    className={isSelected ? "store-selected" : ""}
                    onClick={() => {
                      !isSelected && setSelectedStore(item)
                      isSelected && setSelectedStore()
                    }}
                  >
                    <img alt="" src={item.imgSrc} srcSet={item?.imgSrcSet} />
                    <div className="body-2">
                      {item.displayTitle}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          : null
        }
        <div>
          <div className="cash-modal-secondary-title subheading">{i18n.t("will_spend")}</div>
          <div>
            <div className="cash-dollar-input-area">
              <input
                type="number"
                placeholder={i18n.t("records:please_input_hints")}
                min={0}
                value={inputCashDollar}
                inputMode="numeric"
                onChange={(e) => {
                  const inputData = e.target.value > 0 ? parseInt(e.target.value) : "";
                  setInputCashDollar(inputData)
                  if (cashDollarInputError) {
                    setCashDollarInputError()
                  }
                }}
              />
              <div className="cash-point-balance-text body-2" style={{ textTransform: "lowercase" }}>{i18n.t("cash_dollar")}</div>
            </div>
            <div
              className={
                cashDollarInputError ?
                  "cash-modal-tips error-field tag-menu-1" : "cash-modal-tips tag-menu-1"
              }
            >
              {cashDollarInputError ? getErrorMsg()
                : inputCashDollar ? i18n.t("points_will_be_used", {
                points: enLocaleNumberFormatter(inputCashDollar * POINT_TO_CASH_CONVERT_RATE)
              }) : null}
            </div>
          </div>
        </div>
        <div>
          <button
            ref={payBtnRef}
            className="custom-primary-button button-large"
            disabled={isButtonDisabled}
            onClick={handlePayCashDollar}
          >
            {i18n.t("pay_now")}
          </button>
          <div className="cash-modal-tips tag-menu-1">{i18n.t("confirm_pay_cash_tips")}</div>
        </div>
      </div>
      <CustomAlert
        show={showConfirmUseCashModal}
        onHide={() => {
          payBtnRef.current.disabled = isButtonDisabled;
          setShowConfirmUseCashModal(false)
        }}
        title={i18n.t("confirm_use_cash_dollar")}
        content={
          <div className="confirm-use-cash-modal-content body-2">
            <div>
              {i18n.t("confirm_use_cash_dollar_tips")}
            </div>
            <div className="check-not-show-again tag-menu-2">
              {notShowModalAgain ?
                <img
                  alt=""
                  src="/myAccount/checkbox-checked.png"
                  srcSet="/myAccount/checkbox-checked@2x.png 2x,/myAccount/checkbox-checked@3x.png 3x"
                  onClick={() => {
                    setNotShowModalAgain(false)
                  }}
                /> :
                <img
                  alt=""
                  src="/myAccount/checkbox-uncheck.png"
                  srcSet="/myAccount/checkbox-uncheck@2x.png 2x,/myAccount/checkbox-uncheck@3x.png 3x"
                  onClick={() => {
                    setNotShowModalAgain(true)
                  }}
                />}
              <div>{i18n.t("dont_show_again")}</div>
            </div>
          </div>
        }
        leftbtn={i18n.t("message_cancel")}
        leftAction={() => {
          payBtnRef.current.disabled = isButtonDisabled;
          setNotShowModalAgain(false)
          setShowConfirmUseCashModal(false)
        }}
        rightbtn={i18n.t("campaign_confirm")}
        rightAction={() => {
          payBtnRef.current.disabled = isButtonDisabled;
          shopifyCreateCashDiscount()
          setShowConfirmUseCashModal(false)
          dispatch(createAction("users/updateState")({
            notShowPointToCashTipsModalAgain: notShowModalAgain
          }))
        }}
      />
      <UseCashQRCodeModal
        show={showUseCashModal}
        onHide={() => {
          cancelPointToCash()
          setShowUseCashModal(false)
        }}
        cashDollar={inputCashDollar}
        code={cashCodeKey}
        refreshQRCodeAction={refreshQRCodeAction}
      />
    </div>
  )
}

export default connect(mapStateToProps)(UseCashDollar);