import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction } from 'utils';

const parseAdditionFieldConfig = (field) => {
  let translations = {}
  if (field?.translations?.length > 0) {
    translations = field?.translations?.reduce((arr, value) => ({ ...arr, [value.language]: value }), {}) || {}
  }

  const parseFieldChoice = (choice) => {
    let translations = {}
    translations = choice?.translations?.reduce((arr, value) => ({ ...arr, [value.language]: value?.display_label }), {}) || {}

    return {
      displayLabel: choice?.display_label || "-",
      fieldValue: choice?.field_value || "",
      translations,
    }
  }
  return {
    choices: field?.choices?.map(choice => parseFieldChoice(choice)) || [],
    isEditable: field?.customer_editable || false,
    displayOrder: field?.display_priority || [],
    displayTitle: field?.display_title || "",
    fieldName: field?.field_name || "",
    fieldType: field?.field_type || "",
    isRequired: field?.is_mandatory || false,
    tips: field?.tips || "",
    translations
  }
}

const parseAdditionalSections = (data) => {
  if (data?.length < 1) {
    return []
  }
  const parsedData = data?.map((section) => {
    let translations = {}
    if (section?.translations?.length > 0) {
      translations = section.translations?.reduce((arr, value) => ({ ...arr, [value.language]: value?.name }), {}) || {}
    }

    return {
      fields: section?.fields?.map(field => parseAdditionFieldConfig(field || [])),
      translations,
      name: section?.name || '',
    }
  })
  return parsedData
}

const parseProfileFields = (data) => {
  if (data?.length < 1) {
    return [];
  }
  const parsedData = data?.map((item) => {
    return {
      fieldName: item.field_name,
      editable: item.customer_editable,
      mandatory: item.is_mandatory,
      show: item.show_field,
    };
  });
  return parsedData;
};

const getInitialState = () => {
  return {
    additionalDataSectionConfig: [],
    profileFieldsConfig: [],
  };
};

export default {
  namespace: 'config',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState() {
      return getInitialState();
    },
  },

  effects: {
    getAdditionalDataSection: [
      function* ({ payload }, { call, put, select, all }) {
        const response = yield call(ssoAPIHelper.getAdditionalDataSections);
        if (response?.status === 200) {
          const responseData = response?.data
          const additionalDataSectionConfig = parseAdditionalSections(responseData)
          yield put(createAction('updateState')({ additionalDataSectionConfig }));
        }
      },
      { type: 'takeLatest' },
    ],
    getProfileConfig: [
      function* ({ payload }, { call, put, select, all }) {
        const response = yield call(ssoAPIHelper.getCustomerProfileConfig);
        if (response?.status === 200) {
          const responseData = response?.data
          const profileFieldsConfig = parseProfileFields(responseData)
          yield put(createAction('updateState')({ profileFieldsConfig }));
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
