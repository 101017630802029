import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import InboxListTitle from 'component/inbox/InboxListTitle';
import InboxListItem from 'component/inbox/InboxListItem';
import InboxContent from 'component/inbox/InboxContent';
import NothingPage from 'component/error/Nothing';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';
import { createAction } from 'utils';

import './InboxListWithDetail.scss';

@connect(({ inboxMessages, pullrefreshandloadmore, users }) => ({
  ...inboxMessages,
  ...pullrefreshandloadmore,
  language: users.language,
}))
class InboxListWithDetail extends React.Component {
  componentDidMount() {
    if (this.props.messageList?.length > 0) {
      this.selectFirstInboxMessage()
    }
    this.inboxListNode.addEventListener('scroll', this.bindHandleListScroll);
  }

  componentWillUnmount() {
    this.inboxListNode.removeEventListener('scroll', this.bindHandleListScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction('inboxMessages/refreshMessages')());
    }
    if (
      prevProps.messageList?.length < 1 && this.props.messageList?.length > 0
    ) {
      this.selectFirstInboxMessage()
    }
  }

  selectFirstInboxMessage = () =>  {
    if (this.props.messageList?.length < 1) {
      return
    }
    const firstMessageData = this.props.messageList[0]
    if (!firstMessageData.isMessageRead) {
      this.props.dispatch(
        createAction('inboxMessages/markMessageRead')({
          message: firstMessageData,
        }),
      );
    }
    this.showMessageDetail(firstMessageData.messageId);
  }

  shouldLoadMore = () => {
    return (
      this.props.MessageListHasMoreData &&
      !(
        this.props.MessageListIsRefreshing ||
        this.props.MessageListIsLoadingMore
      )
    );
  };

  bindHandleListScroll = (event) => {
    const clientHeight = event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    const scrollTop = event.target.scrollTop;
    const isLoadArea = clientHeight + scrollTop > scrollHeight - 50;
    if (isLoadArea && this.shouldLoadMore()) {
      this.props.dispatch(createAction('inboxMessages/messageLoadMore')());
    }
  };

  messageItemDidClicked = (messageId) => {
    if (this.props.inboxIsEditing) {
      this.props.dispatch(
        createAction('inboxMessages/switchMessageChecked')({
          messageId,
        }),
      );
    }
    this.showMessageDetail(messageId);
  };

  showMessageDetail = (messageId) => {
    this.props.dispatch(
      createAction('inboxMessages/updateState')({
        activeMessageId: messageId,
      }),
    );
  };

  getMessageItems = () => {
    if (this.props.messageList?.length > 0) {
      return this.props.messageList.map((messageItemData, index) => (
        <InboxListItem
          key={index}
          messageData={messageItemData}
          hasSeparateLine={index === 0 ? false : true}
          isActive={this.props.activeMessageId === messageItemData.messageId}
          clickAction={() =>
            this.messageItemDidClicked(messageItemData.messageId)
          }
        />
      ));
    }

    if (
      !this.props['MessageListIsRefreshing'] &&
      !this.props['MessageListIsLoadingMore']
    )
      return <NothingPage />;
  };

  render() {
    return (
      <div>
        <Container>
          <div className="inbox_desktop_container">
            <div className="inbox_list">
              <div className="inbox_list_title">
                <InboxListTitle />
              </div>
              <div className="inbox_list_separateLine" />
              <div
                className="inbox_list_listArea"
                ref={(node) => (this.inboxListNode = node)}
              >
                {this.getMessageItems()}
                <div className="inbox_list_loadmoreArea">
                  {(this.props['MessageListIsRefreshing'] ||
                    this.props['MessageListIsLoadingMore']) && (
                    <LoadMoreSpinner />
                  )}
                </div>
              </div>
            </div>
            {this.props.activeMessageId && (
              <InboxContent messageId={this.props.activeMessageId} />
            )}
          </div>
          <OfferFloatActionButton />
        </Container>
      </div>
    );
  }
}

export default withRouter(InboxListWithDetail);
