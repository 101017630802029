import React, { Component } from "react";
import { Modal, Table } from "react-bootstrap";
import { connect } from "react-redux";
import i18n from "../../I18n";
import "./LevelRuleModal.scss";
import { LEVEL_CONFIG as levelConfig, LEVEL_DOWNGRADE_CONFIG as levelDowngradeConfig } from "utils/Config";

@connect(({ users }) => ({
  levelConfig: users.levelConfig,
}))
class LevelRuleModal extends Component {
  render() {
    console.log("@@18: ", this.props.levelConfig);
    return (
      <Modal
        centered
        show={this.props.showLevelRule}
        onHide={this.props.handleOnHide}
        className="level-rule-modal"
      >
        <Modal.Header>
          <div className="level-rule-aillia-title">
            <img
              alt="logo"
              src="/logo/logo.png"
              srcSet="/logo/logo@2x.png 2x, /logo/logo@3x.png 3x"
            />
            <button
              className="level-rule-aillia-close-btn"
              onClick={this.props.handleOnHide}
            >
              <img
                alt="close"
                src="/menu/menuClose.png"
                srcSet="/menu/menuClose@2x.png 2x, /menu/menuClose@3x.png 3x"
              />
            </button>
          </div>
          <div className="level-rule-title title-medium-1">{i18n.t("levelRules")}</div>
          <button
            onClick={this.props.handleOnHide}
            className="level-rule-close-btn"
          >
            <img
              alt="close"
              src="/profile/close.png"
              srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
            />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesIntroductionTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n
                .t("levelRulesIntroductionText")
                .split("\n")
                .map((content) => (
                  <p>{content}</p>
                ))}
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesValidityTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n
                .t("levelRulesValidityText")
                .split("\n")
                .map((content) => (
                  <p>{content}</p>
                ))}
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesTableTitle")}
            </div>
            <div className="level-rule-table">
              <Table bordered>
                <thead>
                  <tr>
                    <th className="tag-menu-2">{i18n.t("level")}</th>
                    <th className="tag-menu-2">{i18n.t("spending")}</th>
                  </tr>
                </thead>
                <tbody>
                  {levelConfig.map((levelRule) => {
                    return (
                      <tr key={levelRule.id}>
                        <td className="level-name tag-menu-1">{levelRule.name}</td>
                        <td className="tag-menu-1">{
                        levelRule?.rangeEnd ? 
                          `${i18n.t(`HKD$`)}${levelRule?.rangeStart}${i18n.t(`Yuan`)}-${levelRule.rangeEnd}${i18n.t(`Yuan`)}` 
                          : `${i18n.t(`HKD$`)}${levelRule?.rangeStart}${i18n.t(`Yuan`)}${i18n.t(`in 1 year`)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesRetainDowngradeTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n
                .t("levelRulesRetainDowngradeText")
                .split("\n")
                .map((content, index) => (
                  <p key={index}>{content}</p>
                ))}
            </div>
            <div className="level-rule-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="tag-menu-2">{i18n.t("level")}</th>
                      <th className="tag-menu-2">{i18n.t("Retain Spending")}</th>
                      <th className="tag-menu-2">{i18n.t("Downgrade Level")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {levelDowngradeConfig.map((levelRule) => {
                      return (
                        <tr key={levelRule.id}>
                          <td className="level-name tag-menu-1">{levelRule.name}</td>
                          <td className="level-name tag-menu-1">{levelRule.retainSpending && i18n.t(`HKD$`)}{levelRule.retainSpending || '-'}{levelRule.retainSpending && i18n.t(`Yuan`)|| ''}</td>
                          <td className="level-name tag-menu-1">{levelRule.downgradeLevel}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesMembershipGiftsTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n
                .t("levelRulesMembershipGiftsText")
                .split("\n")
                .map((content) => (
                  <p>{content}</p>
                ))}
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesCouponRulesTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n
                .t("levelRulesCouponRulesText")
                .split("\n")
                .map((content) => (
                  <p>{content}</p>
                ))}
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelClarify")}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LevelRuleModal;
