import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatDate } from '../../utils/DateTimeUtils';
import { APP_NAME, CONTACT_US_EMAIL, CONTACT_US_PHONE, CONTACT_US_WHATSAPP_PHONE } from 'utils/Config';
import i18n from 'I18n';
import CustomButton from 'component/base/CustomButton';
import './CustomerContactModal.scss';

const callPhone = () => {
  window.location = `tel:${CONTACT_US_PHONE}`;
};

const getSendText = (memberShipID) => {
  const date = formatDate(new Date(), 'DDMMYYYY');
  return `\n\n\n\n\nDiagnostic Code:\n ${date}_${memberShipID}_${APP_NAME}\n\n\n`;
};

const sendEmail = (memberShipID) => {
  const recipient = [CONTACT_US_EMAIL];
  const content = getSendText(memberShipID);
  window.location = `mailto:${recipient}?subject=&body=${content}`;
};

const openWhatsapp = () => {
  const whatsappUrl = `https://wa.me/${CONTACT_US_WHATSAPP_PHONE}`
  window.open(whatsappUrl);
}

const CustomerContactModal = ({
  memberShipID,
  show,
  onClose
}) => (
  <Modal
    size="lg"
    show={show}
    onHide={onClose}
    scrollable={false}
    className={'login-method-modal'}
    centered
  >
    <Modal.Body className={'login-method-modal-body '}>
      <div className="home-profile-custom-title title-medium-1">
        <div>
          {i18n.t('settings_contact_customer_service_via')}
        </div>
        <div className="close-icon" onClick={onClose}>
          <img
            alt="close"
            src="/profile/close.png"
            srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
          />
        </div>
      </div>

      <div className="customer-contact-modal-button-container">
        {/* <CustomButton
          className="button-small"
          disabled={false}
          text={i18n.t("settings_phone")}
          btnClicked={callPhone}
        /> */}
        <CustomButton
          className="button-small"
          disabled={false}
          text={i18n.t("settings_email")}
          btnClicked={() => sendEmail(memberShipID)}
        />
        <CustomButton
          className="button-small"
          disabled={false}
          text={i18n.t("settings_whatsapp")}
          btnClicked={openWhatsapp}
        />
      </div>
    </Modal.Body>
  </Modal>
);

const mapStateToProps = (state) => ({
  memberShipID: state.users.userInfo.uid
});

export default connect(mapStateToProps)(CustomerContactModal);
