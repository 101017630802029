import i18n from 'I18n';
import jwtDecode from 'jwt-decode';

import BaseAPIHelper from 'services/BaseAPIHelper';
import { CampaignAPIHelper } from 'services/CampaignAPIHelper';
import { CouponAPIHelper } from 'services/CouponAPIHelper';
import { MessageAPIHelper } from 'services/MessageAPIHelper';
import { TIERS_MAP, ssoAPIHelper } from 'services/SSOAPIHelper';
import { TransactionAPIHelper } from 'services/TransactionAPIHelper';
import { UploadFileAPIHelper } from 'services/UploadFileHelper';

import { createAction, delay, getDisplayName, getTranslationData } from 'utils';
import { subHeaderLink } from 'utils/CampaignUtil';
import { NO_LEVEL } from 'utils/Config';
import { getCMSLanguage } from 'utils/LanguageCheckUtil';
import { refresh } from 'utils/PullAndRefreshUtil';

const USING_FAKE_API = false;

const ACCESS_TOKEN_EXPIRED_TIME = 3600000;
const EXPIRED_TIME_UPDATE_BUFFER = 10 * 60 * 1000;

export const RESET_PASSWORD_STATUS = {
  INIT: "INIT",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
}

function assembleUserInfo(user) {
  return {
    address: user.address,
    company: user.company,
    birthday: user.date_of_birth,
    emailAddress: user.email_address,
    firstName: user.first_name,
    lastName: user.last_name,
    nickname: user.nickname,
    gender: user.gender,
    countryCode: user.mobile_phone_number_country_code,
    phoneNumber: user.mobile_phone_number_subscriber_number,
    referredByCampaign: user.referred_by_campaign,
    referrer: user.referrer,
    tier: TIERS_MAP.SILVER, // hard coded
    memberShipID: user.membership_id,
    hasAgreedDirectMarketing: user.has_agreed_direct_marketing,
    directMarketingViaEmail: user.direct_marketing_via_email,
    totalCouponCount: user.number_of_coupons,
    willExpireCouponCount: user.number_of_coupons_about_to_expire,
    signUpMethod: user.sign_up_method,
    allowedEditFields: user.allowed_edit_fields,
    additionalData: user?.user_custom_data,
    tempCountryCode: user.temporary_mobile_phone_number_country_code,
    tempPhoneNumber: user.temporary_mobile_phone_number_subscriber_number,
    tempEmailAddress: user.temporary_email_address,
  };
}

const parseLevelConfig = (levels, stateLevelAnimated) => {
  let animatedChecking = {};
  const configs = levels.map((level) => {
    animatedChecking[level.id] = stateLevelAnimated[level.id]
      ? stateLevelAnimated[level.id]
      : false;

    const translatedData = getTranslationData(level);
    return {
      name: translatedData.level_name,
      id: level.id,
      targetTPE: level.target_total_points_earned,
      nextLevelID: level.next_level_id,
      benefitUrl: translatedData.level_benefit_url,
    };
  });

  return { configs, animatedChecking };
};

const setUpAPIHelperToken = (token) => {
  BaseAPIHelper.addAuthorization(token)
  CampaignAPIHelper.addAuthorization(token);
  CouponAPIHelper.addAuthorization(token);
  MessageAPIHelper.addAuthorization(token);
  TransactionAPIHelper.addAuthorization(token);
  UploadFileAPIHelper.addAuthorization(token);
};

const pareseStampStatus = (items) =>
  items.map((item) => {
    const translatedData = getTranslationData(item);
    return {
      name: translatedData.name,
      stampCampaignId: item.id,
      earnedStamps: item.total_stamps_earned,
      expirationDate: item.end_date,
    };
  });

export default {
  namespace: 'users',
  state: {
    userInfo: {
      uid: null,
      address: null,
      company: null,
      birthday: null,
      emailAddress: null,
      firstName: null,
      lastName: null,
      nickname: null,
      gender: null,
      countryCode: null,
      phoneNumber: null,
      referredByCampaign: null,
      referrer: null,
      tier: null,
      memberShipID: null,
      hasAgreedDirectMarketing: false,
      directMarketingViaEmail: false,
      totalCouponCount: null,
      willExpireCouponCount: null,
      signUpMethod: null,
    },
    resetPasswordStatus: RESET_PASSWORD_STATUS.INIT,
    resetPasswordError: '',
    deleteAccountSuccess: false,
    deleteAccountError: '',
    deleteAccountLoading: false,
    updateUserInfoSuccess: false,
    updateDirectMarketing: false,
    displayName: null,

    isRefreshingPoints: false,
    availablePoints: 0,
    TPE: 0,
    showLevelUpProgress: false,
    levelUpProgress: 0, 
    pointsToLevelUp: null,
    showLevelRetianProgress: false,
    levelRetainProgress: 0,
    pointsToRetainLevel: null,
    lastVisiableTPE: -1,
    level: 0,
    levelName: '',
    lastVisiableLevel: -1,
    renewDate: null,
    willExpirePoints: 0,
    willExpirePointsDate: null,

    isLogin: false,
    accessToken: null,
    refreshToken: null,
    expiresIn: 0,
    expiresDateTime: 0,
    tokenType: 'bearer',
    refreshTokenPendingActionList: [],
    isRefreshing: false,
    appInstallationID: null,
    pushToken: null,
    agreeCookiePolicy: false,
    levelConfig: [],
    levelAnimated: {},
    loginedLevelAnimated: false,
    showOpenAppBanner: true,

    isShowLevel: true,
    language: 'en',

    isRefreshingStamp: false,
    totalEarnedStamps: 0,
    stampStatus: [],
    stampStatusNextLink: null,
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    loginUser(state, { payload }) {
      if (USING_FAKE_API) {
        return {
          ...state,
          isLogin: true,
          accessToken: payload.access,
        };
      }
      const accessTokenData = {
        accessToken: payload.access,
        refreshToken: payload.refresh,
      };
      accessTokenData.expiresDateTime = Date.now() + ACCESS_TOKEN_EXPIRED_TIME;
      const decoded = jwtDecode(accessTokenData.accessToken);
      console.log('@45', decoded, {
        ...state,
        ...accessTokenData,
        isLogin: true,
      });
      return {
        ...state,
        ...accessTokenData,
        isLogin: true,
        userInfo: { ...state.userInfo, uid: decoded.sub },
      };
    },
    logoutUser(state, { payload }) {
      return {
        ...state,
        accessToken: null,
        userInfo: {},
        refreshToken: null,
        expiresIn: 0,
        expiresDateTime: 0,
        tokenType: 'bearer',
        isLogin: false,
        displayName: null,
        availablePoints: 0,
        TPE: 0,
        lastVisiableTPE: -1,
        level: 0,
        lastVisiableLevel: -1,
        levelAnimated: {},
        loginedLevelAnimated: false,
      };
    },
    userAgreeCookiePolicy(state, { payload }) {
      return {
        ...state,
        agreeCookiePolicy: true,
      };
    },
    setNewUser(state, { payload }) {
      const formatedUser = assembleUserInfo(payload.user);
      const displayName = getDisplayName({
        nickname: formatedUser.nickname,
        firstName: formatedUser.firstName,
        lastName: formatedUser.lastName,
      });
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...formatedUser,
        },
        displayName,
      };
    },
    updateLastVisiableLevel(state, { payload }) {
      return {
        ...state,
        lastVisiableLevel: state.level,
        lastVisiableTPE: state.TPE,
      };
    },
  },
  effects: {
    *startUp({ payload }, { call, put, select }) {
      const token = yield select((state) => state.users.accessToken);
      setUpAPIHelperToken(token);

      const isLogin = yield select((state) => state.users.isLogin);

      if (isLogin) {
        yield put(createAction('users/fetchUserInfo')());
        yield put(createAction('users/fetchAvailablePoints')());
        yield put(createAction('users/fetchLevelConfig')());
      }
    },
    // * registerApp({ payload }, { call, select, put }) {
    //   const appInstallationID = yield select((state) => state.users.appInstallationID);
    //   const language = yield select((state) => state.settings.language);
    //   const language = 'en';
    //   const customerID = yield select((state) => state.users.userInfo.memberShipID); // TODO: need update this id
    //   const pushToken = yield select((state) => state.users.pushToken);
    //   const enableNotification = yield select(
    //     (state) => state.settings.notifications.pushNotifications,
    //   );
    //   const data = {
    //     operation_system: Platform.OS === 'ios' ? 'IOS' : 'ANDROID',
    //     language: getCMSLanguage(language),
    //     is_willing_to_receive_notifications: enableNotification,
    //     push_notification_token: pushToken,
    //     customer: customerID,
    //     ...payload,
    //   };
    //   console.log('@128', data);
    //   if (appInstallationID === null) {
    //     const response = yield call(ssoAPIHelper.registerApp, data);
    //     if (response.status < 300) {
    //       console.log(response.data);
    //       yield put(createAction('updateState')({ appInstallationID: response.data.id }));
    //     }
    //   } else {
    //     yield call(ssoAPIHelper.patchRegistedApp, appInstallationID, data);
    //   }
    // },
    // * patchAppInstallation({ payload }, { select, put, call }) {
    //   const appInstallationID = yield select((state) => state.users.appInstallationID);
    //   if (appInstallationID === null) {
    //     yield put(createAction('registerApp')({ ...payload }));
    //   } else {
    //     yield call(ssoAPIHelper.patchRegistedApp, appInstallationID, { ...payload });
    //   }
    // },
    *login({ payload }, { call, put, select }) {
      const response = yield call(ssoAPIHelper.getAccessToken, payload.code);
      if (!response?.data) {
        return;
      }
      setUpAPIHelperToken(response.data.access);

      yield put(createAction('loginUser')({ ...response.data }));
      yield put(createAction('users/fetchUserInfo')({ needRegisteApp: true }));
      yield put(createAction('users/fetchAvailablePoints')());
    },
    *refreshToken({ payload }, { call, put, select }) {
      console.log('start refresh token');
      yield put(createAction('updateState')({ isRefreshing: true }));
      const refreshToken = yield select((state) => state.users.refreshToken);
      const response = yield call(ssoAPIHelper.refreshToken, refreshToken);

      if (response?.data) {
        setUpAPIHelperToken(response.data.access);
        yield put(createAction('loginUser')({ ...response.data }));
      } else {
        yield put(createAction('logout')());
      }

      console.log('finish refresh token');
      yield put(createAction('updateState')({ isRefreshing: false }));
      yield put(createAction('fetchUserInfo')({ fromRefresh: true }));
      yield put(createAction('fetchAvailablePoints')({ fromRefresh: true }));
    },
    *logout({ payload }, { all, call, put, select }) {
      const refreshToken = yield select((state) => state.users.refreshToken);
      yield call(ssoAPIHelper.revokeToken, refreshToken);
      CampaignAPIHelper.removeAuthorization();
      yield put({ type: 'logoutUser' });
      yield all([
        put(createAction('campaignList/clearFeatureListAndOnlyForYouList')()),
        put(createAction('offerList/clearOfferPageData')()),
      ]);
    },

    getTempAccessToken: [
      function* (action, { call, put, select, all }) {
        const { refreshToken } = action.payload;
        const response = yield call(ssoAPIHelper.getAccessToken, refreshToken);
        if (!response?.data) {
          return;
        }
        yield put(
          createAction('updateState')({
            tempAccessToken: response.data?.access,
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    getUserInfoWithoutLogin: [
      function* (action, { call, put, select, all }) {
        const { accessToken } = action.payload;
        const decoded = jwtDecode(accessToken);
        const uid = decoded.sub;
        const response = yield call(ssoAPIHelper.getUserInfo, {
          accessToken,
          uid,
        });

        if (response.status === 200) {
          const formatedUser = assembleUserInfo(response.data);
          yield put(
            createAction('updateState')({
              unloginUserInfo: {
                ...formatedUser,
                uid,
              },
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    updateUserInfo: [
      function* (action, { call, put, select, all }) {
        const { params, isDirectMarketing } = action.payload;
        const accessToken = yield select((state) => state.users.accessToken);
        const uid = yield select((state) => state.users.userInfo.uid);
        const response = yield call(ssoAPIHelper.updateUserInfo, {
          params,
          accessToken,
          uid,
        });
        if (response?.status === 200) {
          yield all([
            put(createAction('setNewUser')({ user: response.data })),
            put(createAction('updateState')(
             isDirectMarketing ? { updateDirectMarketing: true } : { updateUserInfoSuccess: true }
            )),
          ]);
          delay(1000);
          yield put(
            createAction('updateState')(
              isDirectMarketing ? { updateDirectMarketing: false } : { updateUserInfoSuccess: false }
            ));
          return;
        } else if (
          response?.response?.data?.email_address?.[0] === 'email must be unique'
          || response?.response?.data?.email_address?.[0] === '具有 email address 的 customer 已存在。'
        ) {
          yield put(
            createAction('userInfoTemp/updateState')({
              emailAddress: {
                error: true,
                errorResource: 'account_email_bound',
                errorMessage: i18n.t('account_email_bound'),
              },
            }),
          );
        }
        // Toast.show(i18n.t('ticket_no_internet_connection'));
      },
      { type: 'takeLatest' },
    ],

    resetPassword: [
      function* (action, { call, put, select }) {
        const { password } = action.payload;
        const accessToken = yield select((state) => state.users.accessToken);
        const uid = yield select((state) => state.users.userInfo.uid);
        yield put(
          createAction('updateState')({ resetPasswordStatus: RESET_PASSWORD_STATUS.INIT, resetPasswordError: "" }),
        );
        const response = yield call(ssoAPIHelper.resetPassword, {
          params: { new_password: password },
          accessToken,
          uid,
        });
        if (response.status === 200) {
          yield put(
            createAction('updateState')({ resetPasswordStatus: RESET_PASSWORD_STATUS.SUCCESS }),
          );
        } else {
          const { data } = response?.response;
          let detail = data?.detail || '';
          if (detail === 'New password is same with origin.') {
            detail = 'account_same_password';
          } else {
            detail = 'account_failed_to_change_password';
          }
          yield put(
            createAction('updateState')({
              resetPasswordError: detail,
              resetPasswordStatus: RESET_PASSWORD_STATUS.FAIL,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    fetchUserInfo: [
      function* ({ payload }, { call, put, select }) {
        const isLogin = yield select((state) => state.users.isLogin);
        if (!isLogin) {
          return;
        }
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const response = yield call(ssoAPIHelper.getUserInfo, {
          accessToken,
          uid,
        });
        if (response.status === 200) {
          yield put(createAction('setNewUser')({ user: response.data }));
        }
        if (payload && payload.needRegisteApp && !USING_FAKE_API) {
          yield put(createAction('registerApp')());
        }
        yield put(createAction('inboxMessages/refreshMessages')());
      },
      { type: 'takeLatest' },
    ],

    fetchAvailablePoints: [
      function* (action, { call, put, select }) {
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const response = yield call(ssoAPIHelper.getPointsStatus, {
          accessToken,
          uid,
        });
        yield put(createAction('updateState')({ isRefreshingPoints: true }));

        if (response.status === 200) {
          const latestAvaiablePoints = response.data.balance;
          const currentTPE = response.data.total_points_earned;
          const currentLevel = response.data.current_level;
          const currentLevelName = getTranslationData(
            response.data.current_level_data,
          ).level_name;
          const renewDate = response.data.current_level_renew_datetime;
          const lastExpirePoints = response.data.points_about_to_expire;
          const lastExpirePointsDate = response.data.points_coming_expiry_date;
          const pointsToLevelUp = response.data.points_to_level_up;
          const totalLevelUpRequiredPoints = response.data.total_level_up_required_points;
          let showLevelUpProgress = true;
          let levelUpProgress = 0;
          if (pointsToLevelUp === null) {
            showLevelUpProgress = false;
          } else {
            levelUpProgress = currentTPE / totalLevelUpRequiredPoints;
            if (levelUpProgress > 1) {
              levelUpProgress = 1;
            }
          }
          const currentLevelRetainRequired = response.data?.current_level_data?.retain_required_value
          const pointsToRetainLevel = response.data.points_to_retain_level;
          let showLevelRetianProgress = true;
          let levelRetainProgress = 0;
          let currentSpendingToRetain = 0
          if (pointsToRetainLevel !== null && pointsToRetainLevel > 0 && currentLevelRetainRequired) {
            currentSpendingToRetain = currentLevelRetainRequired - pointsToRetainLevel
            levelRetainProgress = currentSpendingToRetain / currentLevelRetainRequired;
          } else {
            showLevelRetianProgress = false;
          }
          // console.log("@@368: ", currentLevelName, currentLevel);
          yield put(
            createAction('updateState')({
              availablePoints: latestAvaiablePoints,
              TPE: currentTPE,
              level: currentLevel,
              levelName: currentLevelName,
              isRefreshingPoints: false,
              renewDate,
              willExpirePoints: lastExpirePoints,
              willExpirePointsDate: lastExpirePointsDate,
              showLevelUpProgress,
              levelUpProgress, 
              pointsToLevelUp,
              showLevelRetianProgress,
              levelRetainProgress, 
              pointsToRetainLevel,
              currentSpendingToRetain,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    *refreshTokenIfNeed({ payload }, { put, select, all, call }) {
      const isLogin = yield select((state) => state.users.isLogin);
      const expiresDateTime = yield select(
        (state) => state.users.expiresDateTime,
      );

      const refreshTokenPendingActionList = yield select(
        (state) => state.users.refreshTokenPendingActionList,
      );

      // check user is active, if not will auto logout
      // and check if should refresh token
      let shouldRefreshToken = false;
      if (isLogin && payload.customer !== null) {
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const response = yield call(ssoAPIHelper.getUserInfo, {
          accessToken,
          uid,
        });
        if (response.data && response.data.is_forced_inactive) {
          yield put(createAction('logout')({ refreshTokenPendingActionList }));
          return;
        }
        if (response.response?.status === 403) {
          shouldRefreshToken = true;
        }
      }

      const currentDateTime = Date.now();

      if (
        isLogin && (
          expiresDateTime - currentDateTime <= EXPIRED_TIME_UPDATE_BUFFER
          || shouldRefreshToken
        )
      ) {
        // const refreshTokenPendingActionList = yield select(
        //   (state) => state.users.refreshTokenPendingActionList
        // );
        refreshTokenPendingActionList.push(payload.action);
        yield put(
          createAction('updateState')({ refreshTokenPendingActionList }),
        );

        const tokenIsRefreshing = yield select(
          (state) => state.users.isRefreshing,
        );

        if (!tokenIsRefreshing) {
          yield all([put.resolve({ type: 'refreshToken', payload })]);

          const redoActions = yield select(
            (state) => state.users.refreshTokenPendingActionList,
          );
          for (const action of redoActions) {
            const refreshPayload = payload || {};
            refreshPayload.fromRefresh = true;
            yield put(createAction(action)(refreshPayload));
          }

          yield put(
            createAction('updateState')({ refreshTokenPendingActionList: [] }),
          );
        }
      } else {
        const refreshPayload = payload || {};
        refreshPayload.fromRefresh = true;

        yield put(createAction(payload.action)(refreshPayload));
      }
    },

    startLogin: [
      function* (action, { call, put, select }) {
        yield call(ssoAPIHelper.getLoginWebUri(getCMSLanguage('en')));
        // if (USING_FAKE_API) {
        //   navigate('LoginTest');
        //   return;
        // }
        // navigate('Login');
      },
      { type: 'takeLatest' },
    ],

    fetchLevelConfig: [
      function* (action, { call, put, select }) {
        if (NO_LEVEL) {
          return;
        }
        try {
          const response = yield call(ssoAPIHelper.getLevelConfig);
          if (response?.status === 200) {
            const stateLevelAnimated = yield select(
              (state) => state.users.levelAnimated,
            );

            const data = parseLevelConfig(response.data, stateLevelAnimated);

            yield put(
              createAction('updateState')({
                levelConfig: data.configs,
                levelAnimated: data.animatedChecking,
              }),
            );
          }
        } catch (error) {
          console.log('Get level error', error);
        }
      },
      { type: 'takeLatest' },
    ],

    refreshPointStatus: [
      function* (action, { call, put, select }) {
        yield put(
          createAction('updateState')({
            isRefreshingPointsStatus: true,
          }),
        );
        yield put(createAction('fetchAvailablePoints')());
        const accessToken = yield select((state) => state.users.accessToken);
        const uid = yield select((state) => state.users.userInfo.uid);
        const oldData = yield select((state) => state.users.pointStatus);
        const hasOldData = oldData && oldData.length;
        const couponApiServiceWithArgs = [
          ssoAPIHelper.getPointStatus,
          accessToken,
          uid,
        ];
        function* onGetPointStatusSuccess(response, nextLink) {
          const { data } = response;
          console.log('@457', data);
          yield put(
            createAction('updateState')({
              pointStatus: data,
              pointStatusNextLink: nextLink,
              isRefreshingPointsStatus: false,
            }),
          );
        }
        yield refresh(
          'PointStatus',
          hasOldData,
          couponApiServiceWithArgs,
          onGetPointStatusSuccess,
        );
      },
      { type: 'takeLatest' },
    ],

    loadMorePointStatus: [
      function* (action, { call, put, select }) {
        const nextLink = yield select(
          (state) => state.users.pointStatusNextLink,
        );
        if (nextLink === null || nextLink === '') {
          return;
        }
        const accessToken = yield select((state) => state.users.accessToken);
        const uid = yield select((state) => state.users.userInfo.uid);
        const oldData = yield select((state) => state.users.pointStatus);
        const response = yield call(
          ssoAPIHelper.getPointStatus,
          accessToken,
          uid,
          nextLink,
        );
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            PointStatusIsLoadingMore: false,
          }),
        );
        if (response && response?.status === 200) {
          const { data, headers } = response;
          const newNextLink = subHeaderLink(headers.link);
          if (newNextLink) {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                PointStatusHasMoreData: true,
              }),
            );
          } else {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                PointStatusHasMoreData: false,
              }),
            );
          }
          const pointStatus = [...oldData, ...data];
          yield put(
            createAction('updateState')({
              pointStatus,
              pointStatusNextLink: newNextLink,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    fetchTotalEarnedStamps: [
      function* (action, { call, put, select }) {
        const { accessToken, uid } = yield select((state) => ({
          accessToken: state.users.accessToken,
          uid: state.users.userInfo.uid,
        }));

        const response = yield call(ssoAPIHelper.getTotalEarnedStamps, {
          accessToken,
          uid,
        });
        if (response.status === 200) {
          const totalEarnedStamps = response.data.count;
          yield put(
            createAction('updateState')({
              totalEarnedStamps,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    refreshStampStatus: [
      function* (action, { put, select }) {
        yield put(
          createAction('updateState')({
            isRefreshingStamp: true,
          }),
        );
        yield put(createAction('fetchTotalEarnedStamps')());
        const { accessToken, uid, oldData } = yield select((state) => ({
          accessToken: state.users.accessToken,
          uid: state.users.userInfo.uid,
          oldData: state.users.stampStatus,
        }));

        const hasOldData = oldData && oldData.length;
        const stampApiServiceWithArgs = [
          ssoAPIHelper.getStampStatus,
          { accessToken, uid },
        ];
        function* onGetStampStatusSuccess(response, nextLink) {
          const data = pareseStampStatus(response.data);
          yield put(
            createAction('updateState')({
              isRefreshingStamp: false,
              stampStatus: data,
              stampStatusNextLink: nextLink,
            }),
          );
        }
        yield refresh(
          'StampStatus',
          hasOldData,
          stampApiServiceWithArgs,
          onGetStampStatusSuccess,
        );
      },
      { type: 'takeLatest' },
    ],

    loadMoreStampStatus: [
      function* (action, { call, put, select }) {
        const nextLink = yield select(
          (state) => state.users.stampStatusNextLink,
        );
        if (nextLink === null || nextLink === '') {
          return;
        }
        const { accessToken, uid, oldData } = yield select((state) => ({
          accessToken: state.users.accessToken,
          uid: state.users.userInfo.uid,
          oldData: state.users.stampStatus,
        }));

        const response = yield call(ssoAPIHelper.getStampStatus, {
          accessToken,
          uid,
          pageLink: nextLink,
        });
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            StampStatusIsLoadingMore: false,
          }),
        );
        if (response && response?.status === 200) {
          const { data: originalData, headers } = response;
          const newNextLink = subHeaderLink(headers.link);
          if (newNextLink) {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                StampStatusHasMoreData: true,
              }),
            );
          } else {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                StampStatusHasMoreData: false,
              }),
            );
          }
          const parsedData = pareseStampStatus(originalData);
          const stampStatus = [...oldData, ...parsedData];
          yield put(
            createAction('updateState')({
              stampStatus,
              stampStatusNextLink: newNextLink,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    fetchRewardStatus: [
      function* (action, { put }) {
        yield put(createAction('fetchLevelConfig')());
        yield put(createAction('refreshPointStatus')());
        yield put(createAction('refreshStampStatus')());
        yield put(
          createAction('myCoupon/refreshMyCoupon')({ forReward: true }),
        );
      },
      { type: 'takeLatest' },
    ],
    clearRewardStatus: [
      function* (action, { put }) {
        yield put(createAction('myCoupon/resetState')());
      },
      { type: 'takeLatest' },
    ],
    shopifyCreatePointToCash: [
      function* (action, { call, put, select }) {
        const { cashDollarAmount, afterSuccessAction, afterAction } =
          action.payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const response = yield call(
          ssoAPIHelper.shopifyCreatePointToCash,
          accessToken,
          cashDollarAmount,
          uid,
        );

        if (response?.status < 300) {
          const { data } = response;
          yield put(createAction('fetchAvailablePoints')());
          afterSuccessAction(data?.code);
        }
        afterAction && afterAction();
      },
      { type: 'takeLatest' },
    ],
    createPointToCash: [
      function* (action, { call, put, select }) {
        const { cashDollarAmount, successAction, afterAction } = action.payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const response = yield call(
          ssoAPIHelper.createPointToCash,
          accessToken,
          cashDollarAmount,
          uid,
        );

        if (response?.status < 300) {
          const { data } = response;
          yield put(
            createAction('updateState')({
              cashCodeKey: data?.cash_dollar_key,
            }),
          );
          yield put(createAction('fetchAvailablePoints')());
          successAction && successAction();
        } else {
          const errorMessage = response.response?.data?.detail;
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: errorMessage,
              },
            },
          });
        }
        afterAction && afterAction();
      },
      { type: 'takeLatest' },
    ],
    cancelPointToCash: [
      function* (action, { call, put, select }) {
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const cashCodeKey = yield select((state) => state.users.cashCodeKey);
        const response = yield call(
          ssoAPIHelper.cancelPointToCash,
          accessToken,
          uid,
          cashCodeKey,
        );

        if (response?.status < 300) {
          yield put(
            createAction('updateState')({
              cashCodeKey: '',
            }),
          );
          yield put(createAction('fetchAvailablePoints')());
        }
      },
      { type: 'takeLatest' },
    ],
    refreshPointToCash: [
      function* (action, { call, put, select }) {
        const { failedAction } = action.payload;
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const cashCodeKey = yield select((state) => state.users.cashCodeKey);
        const response = yield call(
          ssoAPIHelper.refreshPointToCash,
          accessToken,
          uid,
          cashCodeKey,
        );

        if (response?.status < 300) {
          yield put(createAction('fetchAvailablePoints')());
        } else {
          failedAction && failedAction();
          const errorMessage = response.response?.data?.detail;
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: errorMessage,
              },
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],

    deleteUserAccount: [
      function* (action, { call, put, select }) {
        yield put(createAction('updateState')({ deleteAccountLoading: true }));
        const { password } = action.payload;
        const accessToken = yield select((state) => state.users.accessToken);
        const uid = yield select((state) => state.users.userInfo.uid);
        const response = yield call(ssoAPIHelper.deleteUserAccount, {
          params: { password },
          accessToken,
          uid,
        });
        if (response?.status < 300) {
          yield put(
            createAction('updateState')({
              deleteAccountSuccess: true,
              deleteAccountLoading: false,
            }),
          );
        } else {
          const detail = 'account_password_incorrect';
          yield put(
            createAction('updateState')({
              deleteAccountError: detail,
              deleteAccountSuccess: false,
              deleteAccountLoading: false,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    fetchGiftCardInfo: [
      function* (action, { call, put, select }) {
        const { token, code, successAction, failedAction } = action.payload;
        const response = yield call(ssoAPIHelper.getGiftCardInfo, {
          accessToken: token,
          code,
        });
        if (response?.status < 300) {
          successAction && successAction(response?.data);
        } else {
          failedAction && failedAction(response.response?.data?.detail);
        }
      },
      { type: 'takeLatest' },
    ],

    redeemGiftCard: [
      function* (action, { call, put, select }) {
        const { token, code, uid, successAction, failedAction } =
          action.payload;
        const response = yield call(ssoAPIHelper.redeemGiftCard, {
          accessToken: token,
          uid,
          code,
        });
        if (response?.status < 300) {
          successAction && successAction();
        } else {
          failedAction && failedAction();
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
