import React from 'react';
import ReactDOM from 'react-dom';

import Cookies from 'cookies-js';
import { persistReducer, persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import { createFilter } from 'redux-persist-transform-filter';
import { PersistGate } from 'redux-persist/integration/react';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import App from './App';
import dva from './Dva';
import ActivityLogsModel from './models/ActivityLogs';
import BuyCouponModel from './models/BuyCoupon';
import CampaignModel from './models/Campaign';
import CampaignListModel from './models/HomeFeaturesAndOnlyForYou';
import ConfigModel from './models/ConfigModel';
import InboxMessagesModel from './models/InboxMessagesModel';
import UnsubscribeModel from './models/UnsubscribeModel';
import MyCouponModel from './models/MyCoupon';
import NavBarModel from './models/NavBarModel';
import OfferContainerModel from './models/OfferContainer';
import OfferListModel from './models/OfferList';
import PullRefreshAndLoadMoreModel from './models/PullRefreshAndLoadMore';
import RecordsModel from './models/Records';
import StoreModel from './models/Store';
import UserModel from './models/User';
import UserInfoTempModel from './models/UserInfoTemp';
import * as serviceWorker from './serviceWorker';
import { createAction } from './utils';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './frontend_common/Font.scss'
import './frontend_common/ThemeConfig.scss';

const midwares = [];

const usersFilter = createFilter('users', [
  'accessToken',
  'refreshToken',
  'expiresDateTime',
  'userInfo',
  'availablePoints',
  'isLogin',
  'displayName',
  'agreeCookiePolicy',
  'lastVisiableLevel',
  'lastVisiableTPE',
  'levelAnimated',
  'loginedLevelAnimated',
  'language',
  'showOpenAppBanner',
]);

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
  whitelist: ['users'],
  stateReconciler: autoMergeLevel2,
  transforms: [usersFilter],
};

const app = dva({
  initialState: {},
  models: [
    ConfigModel,
    CampaignModel,
    OfferContainerModel,
    OfferListModel,
    PullRefreshAndLoadMoreModel,
    MyCouponModel,
    CampaignListModel,
    UserModel,
    BuyCouponModel,
    InboxMessagesModel,
    UnsubscribeModel,
    StoreModel,
    UserInfoTempModel,
    ActivityLogsModel,
    NavBarModel,
    RecordsModel,
  ],
  onReducer: (rootReducer) => persistReducer(persistConfig, rootReducer),
  // extraReducers: { router: routerReducer },
  onAction: midwares,
  onError(e) {
    console.log('onError', e);
  },
});

const persistor = persistStore(app.getStore(), null, () => {
  app.getStore().dispatch(createAction('users/startUp')());
});
const DvaApp = app.start(<App />);
ReactDOM.render(
  <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <DvaApp />
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
