import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import GenderPicker from 'component/account/GenderPicker';
import LoginMethodContactModal from 'component/account/LoginMethodContactModal';
import CustomButton from 'component/base/CustomButton';
import MonthPicker, {
  CustomDatePickerInput,
} from 'component/profile/MonthPicker';

import CustomerContactModal from './CustomerContactModal';

import { createAction } from 'utils';
import {
  LOGIN_EDITABLE,
  PRIMARY_SIGN_UP_METHOD,
  SECOND_CONTACT_EDITABLE,
  SECOND_CONTACT_FOR_LOGIN,
  SECOND_CONTACT_SHOW,
} from 'utils/Config';
import {
  CUSTOMER_SIGN_UP_METHOD,
  CustomerFieldName,
  GenderType,
  ProfileEditState,
  ProfileEditType,
} from 'utils/CustomEnums';
import {
  getMyAccountUserBirthdayForDisplay,
  getMyAccountUserBirthdayForUpdate,
} from 'utils/DateTimeUtils';

import './MyAccountInfoSection.scss';

export const EditButton = ({ state, editClick }) => {
  const onClick = () => {
    if (state === ProfileEditState.Normal) {
      editClick(true);
    } else if (state === ProfileEditState.Editing) {
      editClick(false);
    }
  };
  return (
    <span
      className={
        state === ProfileEditState.Disable
          ? 'personal_edit_button inactive'
          : 'personal_edit_button'
      }
      onClick={onClick}
    >
      {state === ProfileEditState.Editing
        ? i18n.t('message_cancel')
        : i18n.t('message_edit')}
    </span>
  );
};

const FirstNameSection = ({
  show,
  mandatory,
  allowEdit,
  firstNameEditState,
  setEditType,
  userInfo,
  firstName,
  setFirstName,
}) => {
  const dispatch = useDispatch();
  if (!show) {
    return null;
  }
  return (
    <Col className="common-account-col">
      <div className="common-account-item">
        <div className="common-item-title subheading">
          {i18n.t('personal_info_first_name')}
          {!mandatory && i18n.t('personal_info_optional')}
          {allowEdit && (
            <EditButton
              state={firstNameEditState}
              editClick={(startEdit) => {
                if (startEdit) {
                  setEditType(ProfileEditType.FirstName);
                } else {
                  setEditType(null);
                }
              }}
            />
          )}
        </div>
        {firstNameEditState === ProfileEditState.Editing ? (
          <div className="common-item-value field-text">
            <input
              className="account-input-with-downarrow field-text"
              defaultValue={firstName}
              onChange={(e) => {
                const text = e.target.value;
                setFirstName(text);
              }}
            />
            <CustomButton
              className="account-input-save-button"
              disabled={mandatory && !firstName}
              text={i18n.t('save')}
              btnClicked={() => {
                dispatch(
                  createAction('userInfoTemp/updateUserInfoOneField')({
                    serverKey: 'first_name',
                    modelKey: 'firstName',
                    value: firstName,
                    successCallback: () => {
                      setEditType(null);
                    },
                  }),
                );
              }}
            />
          </div>
        ) : (
          <div className="common-item-value field-text">
            {userInfo.firstName || '-'}
          </div>
        )}
      </div>
    </Col>
  );
};

const LastNameSection = ({
  show,
  mandatory,
  allowEdit,
  lastNameEditState,
  setEditType,
  userInfo,
  lastName,
  setLastName,
}) => {
  const dispatch = useDispatch();
  if (!show) {
    return null;
  }
  return (
    <Col className="common-account-col">
      <div className="common-account-item">
        <div className="common-item-title subheading">
          {i18n.t('personal_info_last_name')}
          {!mandatory && i18n.t('personal_info_optional')}
          {allowEdit && (
            <EditButton
              state={lastNameEditState}
              editClick={(startEdit) => {
                if (startEdit) {
                  setEditType(ProfileEditType.LastName);
                } else {
                  setEditType(null);
                }
              }}
            />
          )}
        </div>
        {lastNameEditState === ProfileEditState.Editing ? (
          <div className="common-item-value field-text">
            <input
              className="account-input-with-downarrow field-text"
              defaultValue={lastName}
              onChange={(e) => {
                const text = e.target.value;
                setLastName(text);
              }}
            />
            <CustomButton
              className="account-input-save-button"
              disabled={mandatory && !lastName}
              text={i18n.t('save')}
              btnClicked={() => {
                dispatch(
                  createAction('userInfoTemp/updateUserInfoOneField')({
                    serverKey: 'last_name',
                    modelKey: 'lastName',
                    value: lastName,
                    successCallback: () => {
                      setEditType(null);
                    },
                  }),
                );
              }}
            />
          </div>
        ) : (
          <div className="common-item-value field-text">
            {userInfo.lastName || '-'}
          </div>
        )}
      </div>
    </Col>
  );
};

const LoginMethod = ({
  email,
  showEmail,
  countryCodeAndPhoneNumber,
  showMobile,
  signUpMethod,
  showAddAccountButton,
  showSecondLoginMethodModal,
  contactForDirectMarketingEditState,
  setEditType,
  hideSecondLoginMethodModal,
  setShowCustomerContactModal,
}) => {
  return (
    <Col className="common-account-col">
      <div className="common-account-item">
        <div className="common-item-title subheading">
          {i18n.t('settings_login_and_contact')}
        </div>
        {showEmail && (
          <div className='subheading'>
            <label className="field-text">{email || '-'}</label>
            <EditButton
              state={contactForDirectMarketingEditState}
              editClick={(startEdit) => {
                if (!LOGIN_EDITABLE) {
                  setShowCustomerContactModal(true);
                  return;
                }
                if (startEdit) {
                  setEditType(ProfileEditType.contactForDirectMarketing);
                  showSecondLoginMethodModal(
                    CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
                  );
                } else {
                  setEditType(null);
                  hideSecondLoginMethodModal();
                }
              }}
            />
          </div>
        )}
        {showMobile && (
          <div className='subheading'>
            <label className="field-text">
              {countryCodeAndPhoneNumber || '-'}
            </label>
            <EditButton
              state={contactForDirectMarketingEditState}
              editClick={(startEdit) => {
                if (!LOGIN_EDITABLE) {
                  setShowCustomerContactModal(true);
                  return;
                }
                if (startEdit) {
                  setEditType(ProfileEditType.contactForDirectMarketing);
                  showSecondLoginMethodModal(
                    CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
                  );
                } else {
                  setEditType(null);
                  hideSecondLoginMethodModal();
                }
              }}
            />
          </div>
        )}
        {showAddAccountButton && (
          <button
            className="add-another-btn button-small subheading"
            onClick={() =>
              showSecondLoginMethodModal(
                signUpMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
                  ? CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
                  : CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
              )
            }
          >
            {i18n.t('settings_login_add_second_method')}
          </button>
        )}
      </div>
    </Col>
  );
};

const ContactForDirectMarketing = ({
  contactForDirectMarketingEditState,
  setEditType,
  email,
  countryCodeAndPhoneNumber,
  showAddContactButton,
  showSecondLoginMethodModal,
  hideSecondLoginMethodModal,
  setShowCustomerContactModal,
}) => {
  return (
    <Col className="common-account-col">
      <div className="common-account-item">
        <div className="common-item-title subheading">
          {i18n.t('settings_contact_for_direct_marketing') +
            i18n.t('personal_info_optional')}
        </div>
        <div className="contact-method-container subheading">
          {PRIMARY_SIGN_UP_METHOD ===
            CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER &&
            email && <label className="field-text">{email}</label>}
          {PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS &&
            countryCodeAndPhoneNumber && (
              <label className="field-text">{countryCodeAndPhoneNumber}</label>
            )}
          {(PRIMARY_SIGN_UP_METHOD ===
            CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER &&
            email) ||
          (PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS &&
            countryCodeAndPhoneNumber) ? (
            <EditButton
              state={contactForDirectMarketingEditState}
              editClick={(startEdit) => {
                if (!SECOND_CONTACT_EDITABLE) {
                  setShowCustomerContactModal(true);
                  return;
                }
                if (startEdit) {
                  setEditType(ProfileEditType.contactForDirectMarketing);
                  showSecondLoginMethodModal();
                } else {
                  setEditType(null);
                  hideSecondLoginMethodModal();
                }
              }}
            />
          ) : null}
        </div>
        {showAddContactButton && (
          <button
            className="add-another-btn button-small subheading"
            onClick={showSecondLoginMethodModal}
          >
            {i18n.t('additional_contact_title')}
          </button>
        )}
      </div>
    </Col>
  );
};

const GenderSection = ({
  show,
  mandatory,
  allowEdit,
  genderEditState,
  setEditType,
  genderList,
  userInfo,
  selectedGender,
  setSelectedGender,
  displayGender,
}) => {
  const dispatch = useDispatch();
  if (!show) {
    return null;
  }
  return (
    <Col className="common-account-col">
      <div className="common-account-item">
        <div className="common-item-title subheading">
          {i18n.t('personal_info_gender')}
          {!mandatory && i18n.t('personal_info_optional')}
          {allowEdit && (
            <EditButton
              state={genderEditState}
              editClick={(startEdit) => {
                if (startEdit) {
                  setEditType(ProfileEditType.Gender);
                } else {
                  setEditType(null);
                }
              }}
            />
          )}
        </div>
        {genderEditState === ProfileEditState.Editing ? (
          <div className="common-item-value field-text">
            <GenderPicker
              customClass={'gender-picker-box'}
              sections={genderList}
              value={userInfo.gender}
              selectValue={(value) => {
                setSelectedGender(value);
              }}
            />
            <CustomButton
              className="account-input-save-button"
              disabled={!selectedGender}
              text={i18n.t('save')}
              btnClicked={() => {
                dispatch(
                  createAction('userInfoTemp/updateUserInfoOneField')({
                    serverKey: 'gender',
                    modelKey: 'gender',
                    value: selectedGender,
                    successCallback: () => {
                      setEditType(null);
                    },
                  }),
                );
              }}
            />
          </div>
        ) : (
          <div className="common-item-value field-text">{displayGender}</div>
        )}
      </div>
    </Col>
  );
};

const BirthdaySection = ({
  show,
  mandatory,
  allowEdit,
  birthdayEditState,
  setEditType,
  userInfo,
  birthday,
  setBirthday,
  language,
}) => {
  const dispatch = useDispatch();
  const [showBirthMonthOptions, setShowBirthMonthOptions] = useState(false);
  if (!show) {
    return null;
  }
  return (
    <Col className="common-account-col">
      <div className="common-account-item">
        <div className="common-item-title subheading">
          {i18n.t('account_birth_month_year')}
          {!mandatory && i18n.t('personal_info_optional')}
          {allowEdit && (
            <EditButton
              state={birthdayEditState}
              editClick={(startEdit) => {
                if (startEdit) {
                  setEditType(ProfileEditType.Birthday);
                } else {
                  setEditType(null);
                }
              }}
            />
          )}
        </div>
        {birthdayEditState !== ProfileEditState.Editing ? (
          <div className="common-item-value field-text">
            {userInfo.birthday
              ? getMyAccountUserBirthdayForDisplay(userInfo.birthday)
              : i18n.t('not_provided')}
          </div>
        ) : (
          <div className="common-item-value field-text">
            <CustomDatePickerInput
              value={
                birthday ? getMyAccountUserBirthdayForDisplay(birthday) : null
              }
              onClick={() => {
                setShowBirthMonthOptions(!showBirthMonthOptions);
              }}
              showBirthMonthOptions={showBirthMonthOptions}
            />
            <MonthPicker
              show={showBirthMonthOptions}
              lang={language}
              selected={birthday}
              onChange={(date) => {
                if (!!date) {
                  setBirthday(date);
                }
                setShowBirthMonthOptions(false);
              }}
            />
            <CustomButton
              className="account-input-save-button"
              disabled={!birthday}
              text={i18n.t('save')}
              btnClicked={() => {
                dispatch(
                  createAction('userInfoTemp/updateUserInfoOneField')({
                    serverKey: 'date_of_birth',
                    modelKey: 'birthday',
                    value: getMyAccountUserBirthdayForUpdate(birthday),
                    successCallback: () => {
                      setEditType(null);
                    },
                  }),
                );
              }}
            />
          </div>
        )}
      </div>
    </Col>
  );
};

const mapStateToProps = ({ userInfoTemp, users, config }) => {
  return {
    ...userInfoTemp,
    profileFieldsConfig: config.profileFieldsConfig,
    language: users.language,
    signUpMethod: users.userInfo.signUpMethod,
    countryCode: users.userInfo.countryCode,
    phoneNumber: users.userInfo.phoneNumber,
    email: users.userInfo.emailAddress,
    userInfo: users.userInfo,
  };
};

const MyAccountInfoSection = (props) => {
  const genderList = [
    {
      name: i18n.t('personal_info_male'),
      pk: GenderType.Male,
    },
    {
      name: i18n.t('personal_info_female'),
      pk: GenderType.Female,
    },
    {
      name: i18n.t('personal_info_not_disclosed'),
      pk: GenderType.NotDisclosed,
    },
  ];
  console.log('props@25', props);
  const {
    profileFieldsConfig,
    language,
    signUpMethod,
    countryCode,
    phoneNumber,
    email,
    userInfo,
  } = props;
  const dispatch = useDispatch();
  const firstNameConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.firstName,
      )?.[0],
    [profileFieldsConfig],
  );
  const lastNameConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.lastName,
      )?.[0],
    [profileFieldsConfig],
  );
  const genderConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.gender,
      )?.[0],
    [profileFieldsConfig],
  );
  const birthdayConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.birthday,
      )?.[0],
    [profileFieldsConfig],
  );

  const [showLoginMethodModal, setShowLoginMethodModal] = useState(false);
  const [modalMethod, setModalMethod] = useState(null);
  const [showCustomerContactModal, setShowCustomerContactModal] =
    useState(false);

  const closeCustomerContactModal = () => {
    setShowCustomerContactModal(false);
  };

  const displayGender = useMemo(() => {
    const userGender = userInfo.gender;
    if (userGender === GenderType.Female) {
      return i18n.t('personal_info_female');
    }
    if (userGender === GenderType.Male) {
      return i18n.t('personal_info_male');
    }
    if (userGender === GenderType.NotDisclosed) {
      return i18n.t('personal_info_not_disclosed');
    }
    return i18n.t('not_provided');
  }, [userInfo.gender]);

  const [editType, setEditType] = useState(null);
  const firstNameEditState = useMemo(() => {
    if (editType === ProfileEditType.FirstName) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const lastNameEditState = useMemo(() => {
    if (editType === ProfileEditType.LastName) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const contactForDirectMarketingEditState = useMemo(() => {
    if (editType === ProfileEditType.contactForDirectMarketing) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const genderEditState = useMemo(() => {
    if (editType === ProfileEditType.Gender) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const birthdayEditState = useMemo(() => {
    if (editType === ProfileEditType.Birthday) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);

  const countryCodeAndPhoneNumber = useMemo(
    () =>
      countryCode && phoneNumber ? `+${countryCode} ${phoneNumber}` : null,
    [countryCode, phoneNumber],
  );
  const showMobile = useMemo(
    () =>
      PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER ||
      (PRIMARY_SIGN_UP_METHOD ===
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS &&
        [
          CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
          CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
        ].includes(signUpMethod)),
    [signUpMethod],
  );
  const showEmail = useMemo(
    () =>
      PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS ||
      (PRIMARY_SIGN_UP_METHOD ===
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS &&
        [
          CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
          CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
        ].includes(signUpMethod)),
    [signUpMethod],
  );
  const showAddAccountButton = useMemo(
    () =>
      SECOND_CONTACT_FOR_LOGIN &&
      [
        CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
      ].includes(signUpMethod),
    [signUpMethod],
  );
  const showAddContactButton = useMemo(
    () =>
      (PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS &&
        (!countryCode || !phoneNumber)) ||
      (PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER &&
        !email),
    [countryCode, phoneNumber, email],
  );
  const showSecondLoginMethodModal = (editMethod) => {
    setEditType(ProfileEditType.LoginAndContact);
    setModalMethod(editMethod);
    setShowLoginMethodModal(true);
  };

  const addContactMethodOnPress = () => {
    const editMethod =
      PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
        ? CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
        : CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS;
    showSecondLoginMethodModal(editMethod);
  };

  const hideSecondLoginMethodModal = () => {
    setEditType(null);
    setShowLoginMethodModal(false);
    setModalMethod(null);
  };

  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [birthday, setBirthday] = useState(userInfo.birthday);
  const [selectedGender, setSelectedGender] = useState(userInfo.gender);
  useEffect(() => {
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
    setBirthday(userInfo.birthday);
    setSelectedGender(userInfo.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editType]);

  useEffect(() => {
    return () => {
      dispatch(createAction('userInfoTemp/clearState')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container className="common-shadow-section-container account-info-section">
      <div className="common-section-title title-medium-2">
        {props.t('account_information')}
      </div>
      <Row>
        <FirstNameSection
          show={firstNameConfig?.show}
          mandatory={firstNameConfig?.mandatory}
          allowEdit={firstNameConfig?.editable}
          firstNameEditState={firstNameEditState}
          setEditType={setEditType}
          userInfo={userInfo}
          firstName={firstName}
          setFirstName={setFirstName}
        />
        <LastNameSection
          show={lastNameConfig?.show}
          mandatory={lastNameConfig?.mandatory}
          allowEdit={lastNameConfig?.editable}
          lastNameEditState={lastNameEditState}
          setEditType={setEditType}
          userInfo={userInfo}
          lastName={lastName}
          setLastName={setLastName}
        />
      </Row>
      <Row>
        <LoginMethod
          contactForDirectMarketingEditState={
            contactForDirectMarketingEditState
          }
          setEditType={setEditType}
          email={email}
          showEmail={showEmail}
          countryCodeAndPhoneNumber={countryCodeAndPhoneNumber}
          showMobile={showMobile}
          signUpMethod={signUpMethod}
          showAddAccountButton={showAddAccountButton}
          showSecondLoginMethodModal={showSecondLoginMethodModal}
          hideSecondLoginMethodModal={hideSecondLoginMethodModal}
          setShowCustomerContactModal={setShowCustomerContactModal}
        />
        {SECOND_CONTACT_FOR_LOGIN || !SECOND_CONTACT_SHOW ? (
          <GenderSection
            show={genderConfig?.show}
            mandatory={genderConfig?.mandatory}
            allowEdit={genderConfig?.editable}
            genderEditState={genderEditState}
            setEditType={setEditType}
            genderList={genderList}
            userInfo={userInfo}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            displayGender={displayGender}
          />
        ) : (
          <ContactForDirectMarketing
            contactForDirectMarketingEditState={
              contactForDirectMarketingEditState
            }
            setEditType={setEditType}
            email={email}
            countryCodeAndPhoneNumber={countryCodeAndPhoneNumber}
            showAddContactButton={showAddContactButton}
            showSecondLoginMethodModal={addContactMethodOnPress}
            hideSecondLoginMethodModal={hideSecondLoginMethodModal}
            setShowCustomerContactModal={setShowCustomerContactModal}
          />
        )}
      </Row>
      <Row>
        {SECOND_CONTACT_FOR_LOGIN || !SECOND_CONTACT_SHOW ? null : (
          <GenderSection
            show={genderConfig?.show}
            mandatory={genderConfig?.mandatory}
            allowEdit={genderConfig?.editable}
            genderEditState={genderEditState}
            setEditType={setEditType}
            genderList={genderList}
            userInfo={userInfo}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            displayGender={displayGender}
          />
        )}
        <BirthdaySection
          show={birthdayConfig?.show}
          mandatory={birthdayConfig?.mandatory}
          allowEdit={birthdayConfig?.editable}
          birthdayEditState={birthdayEditState}
          setEditType={setEditType}
          userInfo={userInfo}
          birthday={birthday}
          setBirthday={setBirthday}
          language={language}
        />
      </Row>
      <LoginMethodContactModal
        showModal={showLoginMethodModal}
        modalMethod={modalMethod}
        onClose={() => hideSecondLoginMethodModal()}
      />
      <CustomerContactModal
        show={showCustomerContactModal}
        onClose={closeCustomerContactModal}
      />
    </Container>
  );
};

export default connect(mapStateToProps)(
  withTranslation()(MyAccountInfoSection),
);
