import React, { useMemo } from 'react';

import { DiscountTagRow } from './DiscountTags';

import { createAction } from 'utils';
import { enLocaleDollarFormatter } from 'utils/NumberUtils';

import './ProductItem.scss';
import i18n from 'I18n';

const ProductItem = ({ extraStyle, data }) => {
  const isRefunded = data.status === 'REFUNDED';
  const displayOriginPrice = useMemo(() => {
    if (Number(data.originalPrice) !== Number(data.value)) {
      return enLocaleDollarFormatter(data.originalPrice);
    }
    return null;
  }, [data.originalPrice]);
  const displayValue = useMemo(
    () => enLocaleDollarFormatter(data.value),
    [data.value],
  );

  return (
    <div className={`productContainer ${extraStyle}`}>
      <div className={'product'}>
        <div className={'productText body-1'}>{data.name}</div>
        <div className={'productText body-1'}>{`x${data.quantity}`}</div>
      </div>
      {data?.discounts?.length > 0 && (
        <DiscountTagRow extraStyle={'discount'} discounts={data?.discounts} />
      )}
      <div className={'price'}>
        {displayOriginPrice ? (
          <div className={'priceText priceDelete body-1'}>
            {displayOriginPrice}
          </div>
        ): null}
        {isRefunded ? (
          <div className={'priceText refundColor body-2'}>
            {`${displayValue} (${i18n.t("records:transaction_refunded")})`}
          </div>
        ) : (
          <div className={'priceText body-2'}>
            {displayValue}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductItem;
