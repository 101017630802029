import React, { useEffect, useRef, useState } from 'react';
import { Image } from "react-bootstrap";
import PropTypes from 'prop-types';
import Lottie from "lottie-react";

const CommonAnimatedIcon = ({ className, source, useLottie, playAnimation }) => {
  const lottieView = useRef(null);
  const [sourceData, setSourceData] = useState(null);
  let uri = "";
  if (typeof source == "string") {
    uri = source;
    if (source?.startsWith("data:image/png")) {
      useLottie = false
    }
  };

  useEffect(() => {
    if (useLottie && !playAnimation && (!uri || (uri && sourceData)) && lottieView.current) {
      // speed up other animation which won't play, and stop finally
      lottieView.current.setSpeed(1000);
    };
  }, [playAnimation, sourceData]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(uri);
      const json = await data.json();
      setSourceData(json);
    }

    if (uri && useLottie) {
      fetchData().catch((error) => {
        console.log("@@fetchData, error:", error);
      });
    }
  }, [uri]);

  if (useLottie) {
    return (
      <Lottie
        lottieRef={lottieView}
        autoplay={true}
        loop={false}
        className={className}
        animationData={uri ? sourceData : source}
      />
    )
  }

  return (
    <Image
      className={className}
      src={source}
    />
  );
};


CommonAnimatedIcon.propType = {
  className: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  useLottie: PropTypes.bool,
  playAnimation: PropTypes.bool
};

export default CommonAnimatedIcon;
