export const COUPON_REDEMPTION_METHOD = {
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
  INPUT_STORE_STAFF_CODE: 'INPUT_STORE_STAFF_CODE',
  POS_UPDATED_CODE: 'POS_UPDATED_CODE',
};

export const COUPON_FORMATS = {
  COUPON_QR_CODE: 'COUPON_QR_CODE',
  COUPON_BAR_CODE: 'COUPON_BAR_CODE',
  TEXT: 'TEXT',
};

export const BARCODE_METHOD = {
  'EAN-13': 'EAN13',
  'UPC-A': 'upc',
  'Code 39': 'CODE39',
  'Code 128': 'CODE128',
};

export const COUPON_TEMPLATE_TYPE = {
  IMPORT_EXISTING_COUPON: 'IMPORT_EXISTING_COUPON',
  SYSTEM_GENERATED: 'SYSTEM_GENERATED',
};

export const ProfileEditType = {
  FirstName: 1,
  LastName: 2,
  Gender: 3,
  Birthday: 4,
  LoginAndContact: 5,
  Email: 6,
  PhoneNumber: 7,
  contactForDirectMarketing: 8,
};

export const ProfileEditState = {
  Normal: 1,
  Editing: 2,
  Disable: 3,
};

export const GenderType = {
  Female: 'FEMALE',
  Male: 'MALE',
  NotDisclosed: 'NOT_DISCLOSED',
};

export const CUSTOMER_SIGN_UP_METHOD = {
  MOBILE_PHONE_NUMBER: 'MOBILE_PHONE_NUMBER',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS:
    'MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS',
  'GOOGLE-OAUTH2': 'GOOGLE-OAUTH2',
  FACEBOOK: 'FACEBOOK',
  'APPLE-ID': 'APPLE-ID',
};

export const SupportedCountryCodeList = ['852', '853', '86'];


export const ADDITIOONAL_DATA_TYPE = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  DATETIME: "DATETIME",
  TOGGLE: "TOGGLE",
  CHECKBOX: "CHECKBOX",
  DROPDOWN: "DROPDOWN",
  SINGLE_CHOICES: "SINGLE_CHOICES",
  MULTI_CHOICES: "MULTI_CHOICES",
}

export const CustomerFieldName = {
  firstName: "FIRST_NAME",
  lastName: "LAST_NAME",
  gender: "GENDER",
  birthday: "BIRTHDAY",
  referralCode: "REFERRAL_CODE",
};
