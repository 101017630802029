import URI from 'urijs';
import queryString from 'query-string';

export const processDeepLink = (urlString) => {
  console.log('@get deep link', urlString);
  if (!urlString) {
    return urlString;
  }
  const uri = new URI(urlString);
  const protocol = uri.protocol();
  const searchMap = uri.search(true);
  const host = uri.host();
  const path = uri.path();
  console.log('@protocol', protocol);
  console.log('@host', host);

  if (host === 'campaignDetail') {
    const campaignID = searchMap?.id;
    console.log('@get campaign id:', campaignID);
    if (campaignID) {
      return `/campaign/${campaignID}`;
    }
  }
  if (host === 'myCard') {
    return '/user/myCard';
  }
  if (host === 'myCoupon') {
    return '/user/myCoupon';
  }
  if (host === 'profile') {
    return '/user/myProfile';
  }
  if (host === 'couponDetail') {
    const couponID = searchMap?.id;
    console.log('@get coupon id:', couponID);
    if (couponID) {
      return `/coupon/${couponID}`;
    }
  }
  if (host === 'inboxMessage') {
    const inboxMessageId = searchMap?.id;
    const scheduledMessageId = searchMap?.scheduledMessageId;
    if (!inboxMessageId && !scheduledMessageId) {
      return '/inbox';
    }
    return '/inbox';
  }
  if (host === 'storeDetail') {
    const storeId = searchMap?.id;
    console.log('@get storeId id:', storeId);
    if (storeId) {
      return `/storeDetail/${storeId}`;
    }
  }
  if (host === 'home') {
    const refererCode = searchMap?.referCode;
    if (refererCode) {
      return '/home';
    }
    return '/home';
  }
  if (host === 'rewardStatus') {
    if (path === '/point') {
      return '/user/myProfile';
    } else if (path === '/coupon') {
      return '/user/myProfile';
    }
    return '/user/myProfile';
  }
};

export const QUERY_STRING_CONFIG = {
  arrayFormat: 'index',
};

export const updateQueryString = (location, queryDict) => {
  const pathname = location.pathname;
  const parsed = queryString.parse(location.search, QUERY_STRING_CONFIG);
  const keys = Object.keys(queryDict);
  for (const key of keys) {
    if (!!queryDict[key]) {
      parsed[key] = queryDict[key];
    } else {
      delete parsed[key];
    }
  }
  const stringify = queryString.stringify(parsed, QUERY_STRING_CONFIG);
  return `${pathname}?${stringify}`;
};
