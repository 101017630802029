import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { createAction } from "utils";
import "./MyAccountChangePassword.scss";
import CustomButton from "component/base/CustomButton";
import { RESET_PASSWORD_STATUS } from "models/User";

@connect(({ users }) => ({
  resetPasswordStatus: users.resetPasswordStatus,
  resetPasswordError: users.resetPasswordError,
  language: users.language,
}))
class MyAccountChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewPassword: false,
      newPassword: null,
      passwordError: null,
      updateButtonDisable: true,
      errorResource: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetPasswordStatus === this.props.resetPasswordStatus) {
      return;
    }
    this.setState({ updateButtonDisable: true });

    if ([RESET_PASSWORD_STATUS.INIT, RESET_PASSWORD_STATUS.SUCCESS].includes(this.props.resetPasswordStatus)) {
      this.setState({
        errorResource: "",
        passwordError: false,
      });
      return 
    } else if (this.props.resetPasswordError && prevProps.resetPasswordError !== this.props.resetPasswordError) {
      this.setState({ errorResource: this.props.resetPasswordError, passwordError: true })
    }
  }

  passwordValidate = () => {
    const validationRegex = ["^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"]; //(?=.*[@$!%*?&-])
    const condition = new RegExp(validationRegex, "g");
    const password = this.state.newPassword;
    const validateResult = condition.test(password);
    if (validateResult) {
      return true;
    }
    let errorResource = "account_password_must_contain_at_least";
    // if (password.length < 8) {
    //   errorResource = "account_new_password_place_holder";
    // }
    this.setState({
      errorResource,
    });
    return false;
  };

  passwordInput = (
    showPassword,
    changeShowPassword,
    value = null,
    passwordChange
  ) => {
    return (
      <div className="account-password-section">
        <input
          type={showPassword ? "text" : "password"}
          className="common-item-value password-input field-text"
          value={value}
          placeholder={this.props.t("account_password")}
          onChange={passwordChange}
          autoComplete="do-not-autofill" // disable autoComplete https://stackoverflow.com/questions/15738259/disabling-chrome-autofill/30976223#30976223
        />
        <button onClick={changeShowPassword} className="password-visible-btn">
          {showPassword ? (
            <img alt="visible" src="/myAccount/visible.png" />
          ) : (
            <img alt="invisible" src="/myAccount/invisible.png" />
          )}
        </button>
      </div>
    );
  };

  resetPassword = () => {
    if (
      this.state.newPassword === undefined ||
      this.state.newPassword === null
    ) {
      return;
    }
    const validationResult = this.passwordValidate();
    if (validationResult) {
      this.props.dispatch(
        createAction("users/resetPassword")({
          password: this.state.newPassword,
        })
      );
      return;
    }
    this.setState({
      passwordError: true,
    });
  };

  render() {
    const hintPasswordError = this.state.errorResource === "account_password_must_contain_at_least";
    return (
      <Container className="common-shadow-section-container account-change-password-section">
        <div className="common-section-title title-medium-2">
          {this.props.i18n.t("account_change_password_lower")}
        </div>
        <Row>
          <Col>
            <div className="common-account-item">
              <div className="common-item-title subheading">
                {this.props.i18n.t("account_new_password")}
              </div>
              {this.passwordInput(
                this.state.showNewPassword,
                () => {
                  this.setState({
                    showNewPassword: !this.state.showNewPassword,
                  });
                },
                null,
                (e) => {
                  this.setState({
                    newPassword: e.target.value,
                  });
                  if (e.target.value) {
                    this.setState({
                      updateButtonDisable: false,
                    });
                  } else {
                    this.setState({
                      updateButtonDisable: true,
                    });
                  }
                }
              )}
              {this.state.passwordError && !hintPasswordError && (
                <div className="account-common-prompt-information change-password-error tag-menu-1">
                  {this.props.t(this.state.errorResource)}
                </div>)
              }
              <div className={`account-common-prompt-information change-password-infomation tag-menu-1 ${hintPasswordError ? 'change-password-error' : ''}`}>
                {this.props.t("account_password_must_contain_at_least")}
              </div>
            </div>
          </Col>
        </Row>

        <CustomButton
          text={this.props.i18n.t("account_update")}
          btnClicked={this.resetPassword}
          disabled={this.state.updateButtonDisable}
        />
      </Container>
    );
  }
}

export default withTranslation()(MyAccountChangePassword);
