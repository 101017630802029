import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import GenderPicker from 'component/account/GenderPicker';
import CustomButton from 'component/base/CustomButton';

import { createAction } from 'utils';
import {
  CUSTOMER_SIGN_UP_METHOD,
  SupportedCountryCodeList,
} from 'utils/CustomEnums';

import {
  SHOW_ONLINE_EMAIL_REMINDER,
  SECOND_CONTACT_HIDE_OTP,
  EDIT_MOBILE_OPTIONAL,
  EDIT_EMAIL_OPTIONAL,
  SECOND_CONTACT_OPTIONAL,
  PRIMARY_SIGN_UP_METHOD,
} from 'utils/Config';

import './LoginMethodContactModal.scss';

const sections = SupportedCountryCodeList.map((item) => ({
  name: item,
  pk: item,
}));

const MaxTimeCount = 60;

const DirectMarketingSection = ({
  agreeDirectMarketing,
  disagreeDirectMarketing,
}) => {
  return (
    <div>
      <div className="home-profile-custom-title title-medium-1">
        {i18n.t('account_direct_marketing')}
      </div>
      <div className="direct-marketing-section-content body-1">
        {i18n.t('personal_info_receive_marketing_reminder')}
      </div>
      <div>
        <CustomButton
          className="direct-marketing-section-button direct-marketing-section-agree-button"
          disabled={false}
          text={i18n.t('settings_agree_direct_marketing')}
          btnClicked={agreeDirectMarketing}
        />
      </div>
      <div>
        <CustomButton
          className="direct-marketing-section-button direct-marketing-section-disagree-button"
          disabled={false}
          text={i18n.t('settings_disagree_direct_marketing')}
          btnClicked={disagreeDirectMarketing}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ users, userInfoTemp }) => {
  return {
    language: users.language,
    contactError: userInfoTemp.loginMethodContact.error,
    contactErrorMessage: userInfoTemp.loginMethodContact.errorMessage,
    verifyChallenge: userInfoTemp.verifyChallenge,
    verifyError: userInfoTemp.verifyError,
    userCountryCode: users.userInfo.countryCode,
    userPhoneNumber: users.userInfo.phoneNumber,
    userEmail: users.userInfo.emailAddress,
    hasAgreedDirectMarketing: users.userInfo.hasAgreedDirectMarketing,
    tempCountryCode: users.userInfo.tempCountryCode,
    tempPhoneNumber: users.userInfo.tempPhoneNumber,
    tempEmailAddress: users.userInfo.tempEmailAddress,
  };
};

const LoginMethodContactModal = ({
  language,
  contactError,
  contactErrorMessage,
  verifyChallenge,
  verifyError,
  userCountryCode,
  userPhoneNumber,
  userEmail,
  hasAgreedDirectMarketing,
  tempCountryCode,
  tempPhoneNumber,
  tempEmailAddress,
  showModal,
  modalMethod,
  onClose = () => { },
}) => {
  const dispatch = useDispatch();

  const defaultCountryCode = userCountryCode || tempCountryCode || SupportedCountryCodeList?.[0];
  const defaultPhoneNumber = userPhoneNumber || tempPhoneNumber || null;
  const defaultEmail = userEmail || tempEmailAddress || null;

  const [showDirectMarketingModal, setShowDirectMarketingModal] = useState(false);
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const selectCountryCode = useCallback((value) => setCountryCode(value), []);
  const [nationalNumber, setNationalNumber] = useState(defaultPhoneNumber);
  const [email, setEmail] = useState(defaultEmail);
  const [requiringOPT, setRequiringOPT] = useState(false);
  const [verifyCode, setVerifyCode] = useState(null);

  // timer
  const [refreshDatetime, setRefreshDatetime] = useState(Date.now());
  useEffect(() => {
    setRefreshDatetime(Date.now());
    const intervalID = setInterval(() => {
      setRefreshDatetime(Date.now());
    }, 100);
    return () => {
      // cancel refresh qrcode
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [callOTPTime, setCallOTPTime] = useState(null);

  const verifyEnable = useMemo(() => {
    if (requiringOPT) {
      return false;
    }
    if (callOTPTime !== null) {
      const ms = Number(refreshDatetime) - Number(callOTPTime);
      const seconds = Math.round(ms / 1000);
      if (seconds < MaxTimeCount) {
        return false;
      }
    }
    if (modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
      if (!email || email === userEmail) {
        return false;
      }
    } else if (modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
      if (
        !countryCode
        || !nationalNumber
        || (
          countryCode === userCountryCode
          && nationalNumber === userPhoneNumber
        )) {
        return false;
      }
    };

    return true;
  }, [requiringOPT, refreshDatetime, callOTPTime]);

  const verifyText = useMemo(() => {
    if (callOTPTime !== null) {
      const ms = Number(refreshDatetime) - Number(callOTPTime);
      const seconds = Math.round(ms / 1000);
      if (seconds < MaxTimeCount) {
        return i18n.t('account_verify') + ` (${MaxTimeCount - seconds}s)`;
      }
    }
    return i18n.t('account_verify');
  }, [refreshDatetime, callOTPTime]);

  const hideOtp = useMemo(() => {
    if (
      (
        (
          PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
          && modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
        ) || (
          PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
          && modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
        )
      ) && SECOND_CONTACT_HIDE_OTP
    ) {
      return true;
    };
    return false;
  }, [modalMethod]);

  const confirmEnable = useMemo(
    () => {
      if (!!verifyChallenge && !!verifyCode) {
        return true;
      };
      if (
        hideOtp
        && (
          (modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER && countryCode && nationalNumber)
          || (modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS && email)
        )
      ) {
        return true;
      }
      if (modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER && !nationalNumber && EDIT_MOBILE_OPTIONAL) {
        return true
      }

      if (modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS && !email && EDIT_EMAIL_OPTIONAL) {
        return true
      }

      return false;
    },
    [modalMethod, verifyChallenge, verifyCode, email, countryCode, nationalNumber]
  );

  const modalTitle = useMemo(() => {
    let methodForLogin = false;
    let title = i18n.t('settings_login_and_contact');
    if (
      modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
      && [
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
      ].includes(PRIMARY_SIGN_UP_METHOD)
    ) {
      methodForLogin = true;
    };
    if (
      modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
      && [
        CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
      ].includes(PRIMARY_SIGN_UP_METHOD)
    ) {
      methodForLogin = true;
    };

    if (!methodForLogin) {
      if (SECOND_CONTACT_OPTIONAL) {
        title = i18n.t('settings_contact_for_direct_marketing') + i18n.t('personal_info_optional');
      } else {
        title = i18n.t('settings_contact_for_direct_marketing');
      }
    };

    return title;
  }, [modalMethod]);

  useEffect(() => {
    dispatch(createAction('userInfoTemp/clearState')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, countryCode, nationalNumber]);

  useEffect(() => {
    setShowDirectMarketingModal(false);
    setEmail(defaultEmail);
    setCountryCode(defaultCountryCode);
    setNationalNumber(defaultPhoneNumber);
    setVerifyCode(null);
    setCallOTPTime(null);
    dispatch(createAction('userInfoTemp/clearState')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const callApiRequireOTP = () => {
    setRequiringOPT(true);
    dispatch(
      createAction('userInfoTemp/requireOTPForUpdateLoginMethod')({
        method: modalMethod,
        countryCode,
        nationalNumber,
        email,
        successCallback: () => {
          setRequiringOPT(false);
          setCallOTPTime(Date.now());
        },
        failureCallback: () => setRequiringOPT(false),
      }),
    );
  };

  const callApiUpdateLoginMethod = () => {
    dispatch(
      createAction('userInfoTemp/verifyOTPAndUpdateLoginMethod')({
        method: modalMethod,
        countryCode,
        nationalNumber,
        email,
        verifyCode,
        successCallback: () => {
          onClose();
        },
        failureCallback: () => { },
      }),
    );
  };

  const clickConfirm = () => {
    if (
      !hasAgreedDirectMarketing
      && (
        (
          modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
          && PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
          && nationalNumber
        ) || (
          modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
          && PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
          && email
        )
      )
    ) {
      setShowDirectMarketingModal(true);
      return;
    }
    callApiUpdateLoginMethod();
  };

  const agreeDirectMarketing = () => {
    dispatch({
      type: 'userInfoTemp/updateUserDirectMarketing',
      payload: {
        hasAgreedDirectMarketing: true,
        directMarketingViaEmail: true,
        afterAction: () => {
          callApiUpdateLoginMethod();
          setShowDirectMarketingModal(false);
        }
      },
    });
  };

  const disagreeDirectMarketing = () => {
    callApiUpdateLoginMethod();
    setShowDirectMarketingModal(false);
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={onClose}
      scrollable={false}
      className={'login-method-modal'}
      centered
    >
      <Modal.Body className={'login-method-modal-body '}>
        {
          showDirectMarketingModal
            ? <DirectMarketingSection
              agreeDirectMarketing={agreeDirectMarketing}
              disagreeDirectMarketing={disagreeDirectMarketing}
            />
            : <>
              <div className="home-profile-custom-title title-medium-1">
                <div>
                  {modalTitle}
                </div>
                <div className="close-icon" onClick={onClose}>
                  <img
                    alt="close"
                    src="/profile/close.png"
                    srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
                  />
                </div>
              </div>
              <div className="account-error" />
              {modalMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS ? (
                <div className={contactError ? 'has-error' : ''}>
                  <div className="home-profile-custom-email bottom-line common-item-value field-text">
                    <input
                      placeholder={i18n.t('settings_email')}
                      className="email-input field-text"
                      defaultValue={email}
                      onChange={(e) => {
                        const text = e.target.value;
                        setEmail(text);
                      }}
                    />
                    {
                      hideOtp
                        ? null
                        : <CustomButton
                          className="button-small"
                          disabled={!verifyEnable}
                          text={verifyText}
                          btnClicked={() => callApiRequireOTP()}
                        />
                    }
                  </div>
                  {SHOW_ONLINE_EMAIL_REMINDER ?
                    <div className="account-common-prompt-information tag-menu-1">
                      {i18n.t('settings_email_tip')}
                    </div> : null
                  }

                </div>
              ) : null}
              {modalMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER ? (
                <div
                  className={
                    contactError
                      ? 'home-profile-mobile has-error'
                      : 'home-profile-mobile'
                  }
                >
                  <div className="home-profile-custom-mobile country-code bottom-line common-item-value field-text">
                    <GenderPicker
                      customClass={'country-code-picker-box'}
                      sections={sections}
                      value={countryCode}
                      selectValue={selectCountryCode}
                    />
                  </div>
                  <div className="home-profile-custom-mobile national-number bottom-line common-item-value field-text">
                    <input
                      placeholder={i18n.t('account_mobile')}
                      className="mobile-input field-text"
                      defaultValue={nationalNumber}
                      onChange={(e) => {
                        const text = e.target.value;
                        setNationalNumber(text);
                      }}
                    />
                    {
                      hideOtp
                        ? null
                        : <CustomButton
                          className="button-small"
                          disabled={!verifyEnable}
                          text={verifyText}
                          btnClicked={() => callApiRequireOTP()}
                        />
                    }
                  </div>
                </div>
              ) : null}
              <div className="account-error account-common-prompt-information change-password-error tag-menu-2">
                {contactError ? contactErrorMessage : null}
              </div>
              {
                hideOtp
                  ? null
                  : <>
                    <div
                      className={`home-profile-custom-verify-code bottom-line common-item-value field-text ${verifyError ? 'has-error' : ''
                        }`}
                    >
                      <input
                        placeholder={i18n.t('sign_up_verification_code')}
                        className="field-text"
                        onChange={(e) => {
                          const text = e.target.value;
                          setVerifyCode(text);
                        }}
                      />
                    </div>
                    <div className="account-error account-common-prompt-information change-password-error tag-menu-2">
                      {verifyError || ''}
                    </div>
                  </>
              }
              <CustomButton
                className="button-large"
                disabled={!confirmEnable}
                text={i18n.t('account_update')}
                btnClicked={clickConfirm}
              />
            </>
        }
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps)(LoginMethodContactModal);
