import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import MyAccountChangePassword from 'component/account/MyAccountChangePassword';
import MyAccountDeleteAccount from 'component/account/MyAccountDeleteAccount';
import MyAccountDirectMarketing from 'component/account/MyAccountDirectMarketing';
import MyAccountInfoSection from 'component/account/MyAccountInfoSection';
import MyAccountMobile from 'component/account/MyAccountMobile';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';
import { RESET_PASSWORD_STATUS } from 'models/User';

import { createAction } from 'utils';
import { isPadOrMobile } from 'utils/ScreenUtils';

import './MyAccount.scss';
import AdditionalDataSection from 'component/account/AdditionalDataSection';

@connect(({ users, userInfoTemp, config }) => ({
  language: users.language,
  updateUserInfoSuccess: users.updateUserInfoSuccess,
  updateDirectMarketing: users.updateDirectMarketing,
  resetPasswordStatus: users.resetPasswordStatus,
  additionalDataSectionConfig: config.additionalDataSectionConfig,
}))
class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
    };
    props.dispatch(createAction('users/fetchUserInfo')());
  }
  componentDidMount() {
    this.props.dispatch(createAction('config/getProfileConfig')());
    this.props.dispatch(createAction('config/getAdditionalDataSection')());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.props.dispatch(createAction('users/fetchUserInfo')());
      this.props.dispatch(createAction('config/getProfileConfig')());
      this.props.dispatch(createAction('config/getAdditionalDataSection')());
    }
    if (
      prevProps.updateUserInfoSuccess !== this.props.updateUserInfoSuccess ||
      prevProps.updateDirectMarketing !== this.props.updateDirectMarketing ||
      (prevProps.resetPasswordStatus !== this.props.resetPasswordStatus
        && this.props.resetPasswordStatus === RESET_PASSWORD_STATUS.SUCCESS
      )
    ) {
      this.props.dispatch({
        type: 'navBars/updateState',
        payload: {
          toastShowing: {
            value: true,
            showTickIcon: true,
            content: this.props.t('successfullyUpdated'),
          },
        },
      });
    }
  }

  render() {
    return (
      <>
        {isPadOrMobile() ? (
          <MyAccountMobile />
        ) : (
          <Container className="user-section-container">
            <div>
              <MyAccountInfoSection />
              {this.props.additionalDataSectionConfig?.map((sectionConfig) => <AdditionalDataSection sectionConfig={sectionConfig} />)}
              <MyAccountDirectMarketing />
              <MyAccountChangePassword />
              <MyAccountDeleteAccount />
            </div>
          </Container>
        )}
        <OfferFloatActionButton />
      </>
    );
  }
}

export default withTranslation()(MyAccount);
