import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import GenderPicker from 'component/account/GenderPicker';
import LoginMethodContactModal from 'component/account/LoginMethodContactModal';
import MyAccountChangePassword from 'component/account/MyAccountChangePassword';
import MyAccountDeleteAccount from 'component/account/MyAccountDeleteAccount';
import CustomButton from 'component/base/CustomButton';
import MonthPicker, {
  CustomDatePickerInput,
} from 'component/profile/MonthPicker';

import MyAccountDirectMarketing from './MyAccountDirectMarketing';

import { createAction } from 'utils';
import {
  CUSTOMER_SIGN_UP_METHOD,
  CustomerFieldName,
  GenderType,
  ProfileEditState,
  ProfileEditType,
} from 'utils/CustomEnums';
import {
  getMyAccountUserBirthdayForDisplay,
  getMyAccountUserBirthdayForUpdate,
} from 'utils/DateTimeUtils';
import { LOGIN_EDITABLE, PRIMARY_SIGN_UP_METHOD, SECOND_CONTACT_EDITABLE, SECOND_CONTACT_FOR_LOGIN, SECOND_CONTACT_SHOW } from 'utils/Config';

import './MyAccountInfoSection.scss';
import './MyAccountMobile.scss';
import AdditionalDataSection from 'component/account/AdditionalDataSection';
import CustomerContactModal from './CustomerContactModal';

const EditButton = ({ state, editClick }) => {
  const onClick = () => {
    if (state === ProfileEditState.Normal) {
      editClick(true);
    } else if (state === ProfileEditState.Editing) {
      editClick(false);
    }
  };
  return (
    <span
      className={
        state === ProfileEditState.Disable
          ? 'personal_edit_button inactive'
          : 'personal_edit_button'
      }
      onClick={onClick}
    >
      {state === ProfileEditState.Editing
        ? i18n.t('message_cancel')
        : i18n.t('message_edit')}
    </span>
  );
};

const ContactSection = ({
  show,
  contactValue,
  showEdit,
  editable,
  contactForDirectMarketingEditState,
  setEditType,
  showSecondLoginMethodModal,
  hideSecondLoginMethodModal,
  setShowCustomerContactModal,
}) => {
  if (!show) {
    return null;
  };
  return (
    <div className="contact-method-container subheading">
      <label className="field-text">{contactValue || '-'}</label>
      {
        showEdit
          ? <EditButton
            state={contactForDirectMarketingEditState}
            editClick={(startEdit) => {
              if (!editable) {
                setShowCustomerContactModal(true);
                return;
              };
              if (startEdit) {
                setEditType(ProfileEditType.contactForDirectMarketing);
                showSecondLoginMethodModal();
              } else {
                setEditType(null);
                hideSecondLoginMethodModal();
              }
            }}
          />
          : null
      }
    </div>
  )
}

const mapStateToProps = ({ userInfoTemp, users, config }) => {
  return {
    ...userInfoTemp,
    profileFieldsConfig: config.profileFieldsConfig,
    additionalDataSectionConfig: config.additionalDataSectionConfig,
    language: users.language,
    signUpMethod: users.userInfo.signUpMethod,
    countryCode: users.userInfo.countryCode,
    phoneNumber: users.userInfo.phoneNumber,
    email: users.userInfo.emailAddress,
    userInfo: users.userInfo,
  };
};

const MyAccountMobile = (props) => {
  const genderList = [
    {
      name: i18n.t('personal_info_male'),
      pk: GenderType.Male,
    },
    {
      name: i18n.t('personal_info_female'),
      pk: GenderType.Female,
    },
    {
      name: i18n.t('personal_info_not_disclosed'),
      pk: GenderType.NotDisclosed,
    },
  ];
  console.log('props@25', props);
  const {
    profileFieldsConfig,
    language,
    signUpMethod,
    countryCode,
    phoneNumber,
    email,
    userInfo,
    additionalDataSectionConfig
  } = props;
  const dispatch = useDispatch();
  const firstNameConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.firstName
      )?.[0],
    [profileFieldsConfig]
  );
  const lastNameConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.lastName
      )?.[0],
    [profileFieldsConfig]
  );
  const genderConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.gender
      )?.[0],
    [profileFieldsConfig]
  );
  const birthdayConfig = useMemo(
    () =>
      profileFieldsConfig?.filter(
        (item) => item.fieldName === CustomerFieldName.birthday
      )?.[0],
    [profileFieldsConfig]
  );

  const [showLoginMethodModal, setShowLoginMethodModal] = useState(false);
  const [modalMethod, setModalMethod] = useState(null);
  const [showCustomerContactModal, setShowCustomerContactModal] = useState(false);

  const closeCustomerContactModal = () => {
    setShowCustomerContactModal(false);
  };

  const displayGender = useMemo(() => {
    const userGender = userInfo.gender;
    if (userGender === GenderType.Female) {
      return i18n.t('personal_info_female');
    }
    if (userGender === GenderType.Male) {
      return i18n.t('personal_info_male');
    }
    if (userGender === GenderType.NotDisclosed) {
      return i18n.t('personal_info_not_disclosed');
    }
    return i18n.t('not_provided');
  }, [userInfo.gender]);

  const [editType, setEditType] = useState(null);
  const firstNameEditState = useMemo(() => {
    if (editType === ProfileEditType.FirstName) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const lastNameEditState = useMemo(() => {
    if (editType === ProfileEditType.LastName) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const genderEditState = useMemo(() => {
    if (editType === ProfileEditType.Gender) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const birthdayEditState = useMemo(() => {
    if (editType === ProfileEditType.Birthday) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const contactForDirectMarketingEditState = useMemo(() => {
    if (editType === ProfileEditType.contactForDirectMarketing) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);

  const countryCodeAndPhoneNumber = useMemo(
    () => (
      countryCode && phoneNumber
        ? `+${countryCode} ${phoneNumber}`
        : null
    ),
    [countryCode, phoneNumber],
  );
  const showMobile = useMemo(
    () => (
      PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
      || (
        PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
        && [
          CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
          CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
        ].includes(signUpMethod)
      )
    ),
    [signUpMethod],
  );
  const showSecondContactMobile = useMemo(
    () => [
      CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
      CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
    ].includes(signUpMethod),
    [signUpMethod],
  )
  const showEmail = useMemo(
    () => (
      PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
      || (
        PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
        && [
          CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
          CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
        ].includes(signUpMethod)
      )
    ),
    [signUpMethod],
  );
  const showSecondContactEmail = useMemo(
    () => [
      CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
      CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
    ].includes(signUpMethod),
    [signUpMethod],
  )
  const showAddAccountButton = useMemo(
    () =>
      [
        CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
      ].includes(signUpMethod),
    [signUpMethod],
  );
  const showSecondLoginMethodModal = (editMethod) => {
    setEditType(ProfileEditType.LoginAndContact);
    setModalMethod(editMethod);
    setShowLoginMethodModal(true);
  };
  const hideSecondLoginMethodModal = () => {
    setEditType(null);
    setShowLoginMethodModal(false);
    setModalMethod(null);
  };

  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [birthday, setBirthday] = useState(userInfo.birthday);
  const [showBirthMonthOptions, setShowBirthMonthOptions] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userInfo.gender);
  useEffect(() => {
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
    setBirthday(userInfo.birthday);
    setSelectedGender(userInfo.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editType]);

  useEffect(() => {
    return () => {
      dispatch(createAction('userInfoTemp/clearState')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="account-mobile-common-section">
        <div className="common-section-title title-medium-2">
          {props.t('account_information')}
        </div>
        {!!firstNameConfig?.show ? (
        <div className="common-account-item">
          <div className="common-item-title subheading">
            {props.t('personal_info_first_name')}
            {!firstNameConfig?.mandatory && i18n.t("personal_info_optional")}
            {!!firstNameConfig?.editable && (
              <EditButton
                state={firstNameEditState}
                editClick={(startEdit) => {
                  if (startEdit) {
                    setEditType(ProfileEditType.FirstName);
                  } else {
                    setEditType(null);
                  }
                }}
              />
            )}
          </div>
          {firstNameEditState === ProfileEditState.Editing ? (
            <div className="common-item-value field-text">
              <input
                className="account-input-with-downarrow field-text"
                defaultValue={firstName}
                onChange={(e) => {
                  const text = e.target.value;
                  setFirstName(text);
                }}
              />
              <CustomButton
                className="account-input-save-button"
                disabled={!firstName}
                text={i18n.t('save')}
                btnClicked={() => {
                  dispatch(
                    createAction('userInfoTemp/updateUserInfoOneField')({
                      serverKey: 'first_name',
                      modelKey: 'firstName',
                      value: firstName,
                      successCallback: () => {
                        setEditType(null);
                      },
                    }),
                  );
                }}
              />
            </div>
          ) : (
            <div className="common-item-value field-text">
              {userInfo.firstName || '-'}
            </div>
          )}
        </div>
        ) : null}
        {!!lastNameConfig?.show ? (
        <div className="common-account-item">
          <div className="common-item-title subheading">
            {props.t('personal_info_last_name')}
            {!lastNameConfig?.mandatory && i18n.t("personal_info_optional")}
            {!!lastNameConfig?.editable && (
              <EditButton
                state={lastNameEditState}
                editClick={(startEdit) => {
                  if (startEdit) {
                    setEditType(ProfileEditType.LastName);
                  } else {
                    setEditType(null);
                  }
                }}
              />
            )}
          </div>
          {lastNameEditState === ProfileEditState.Editing ? (
            <div className="common-item-value field-text">
              <input
                className="account-input-with-downarrow field-text"
                defaultValue={lastName}
                onChange={(e) => {
                  const text = e.target.value;
                  setLastName(text);
                }}
              />
              <CustomButton
                className="account-input-save-button"
                disabled={!lastName}
                text={i18n.t('save')}
                btnClicked={() => {
                  dispatch(
                    createAction('userInfoTemp/updateUserInfoOneField')({
                      serverKey: 'last_name',
                      modelKey: 'lastName',
                      value: lastName,
                      successCallback: () => {
                        setEditType(null);
                      },
                    }),
                  );
                }}
              />
            </div>
          ) : (
            <div className="common-item-value field-text">
              {userInfo.lastName || '-'}
            </div>
          )}
        </div>
        ) : null}
        {!!genderConfig?.show ? (
        <div className="common-account-item">
          <div className="common-item-title subheading">
            {props.t('personal_info_gender')}
            {!genderConfig?.mandatory && props.t('personal_info_optional')}
            {!!genderConfig?.editable && (
              <EditButton
                state={genderEditState}
                editClick={(startEdit) => {
                  if (startEdit) {
                    setEditType(ProfileEditType.Gender);
                  } else {
                    setEditType(null);
                  }
                }}
              />
            )}
          </div>
          {genderEditState === ProfileEditState.Editing ? (
            <div className="common-item-value field-text gender">
              <GenderPicker
                customClass={'gender-picker-box'}
                sections={genderList}
                value={userInfo.gender}
                selectValue={(value) => {
                  setSelectedGender(value);
                }}
              />
              <CustomButton
                className="account-input-save-button"
                disabled={!selectedGender}
                text={i18n.t('save')}
                btnClicked={() => {
                  dispatch(
                    createAction('userInfoTemp/updateUserInfoOneField')({
                      serverKey: 'gender',
                      modelKey: 'gender',
                      value: selectedGender,
                      successCallback: () => {
                        setEditType(null);
                      },
                    }),
                  );
                }}
              />
            </div>
          ) : (
            <div className="common-item-value field-text">{displayGender}</div>
          )}
        </div>
        ) : null}
        {!!birthdayConfig?.show ? (
        <div className="common-account-item">
          <div className="common-item-title subheading">
            {props.t('account_birth_month_year')}
            {!birthdayConfig?.mandatory && i18n.t("personal_info_optional")}
            {!!birthdayConfig?.editable && (
              <EditButton
                state={birthdayEditState}
                editClick={(startEdit) => {
                  if (startEdit) {
                    setEditType(ProfileEditType.Birthday);
                  } else {
                    setEditType(null);
                  }
                }}
              />
            )}
          </div>
          {birthdayEditState !== ProfileEditState.Editing ? (
            <div className="common-item-value field-text">
              {userInfo.birthday
                ? getMyAccountUserBirthdayForDisplay(userInfo.birthday)
                : i18n.t('not_provided')}
            </div>
          ) : (
            <div className="common-item-value field-text">
              <CustomDatePickerInput
                value={
                  birthday ? getMyAccountUserBirthdayForDisplay(birthday) : null
                }
                onClick={() => {
                  setShowBirthMonthOptions(!showBirthMonthOptions);
                }}
                showBirthMonthOptions={showBirthMonthOptions}
              />
              <MonthPicker
                show={showBirthMonthOptions}
                lang={language}
                selected={birthday}
                onChange={(date) => {
                  if (!!date) {
                    setBirthday(date);
                  }
                  setShowBirthMonthOptions(false);
                }}
              />
              <CustomButton
                className="account-input-save-button"
                disabled={!birthday}
                text={i18n.t('save')}
                btnClicked={() => {
                  dispatch(
                    createAction('userInfoTemp/updateUserInfoOneField')({
                      serverKey: 'date_of_birth',
                      modelKey: 'birthday',
                      value: getMyAccountUserBirthdayForUpdate(birthday),
                      successCallback: () => {
                        setEditType(null);
                      },
                    }),
                  );
                }}
              />
            </div>
          )}
        </div>
        ) : null}
        <div className="common-account-item">
          <div className="common-item-title subheading">
            {i18n.t('settings_login_and_contact')}
          </div>

          {
            SECOND_CONTACT_FOR_LOGIN
              ? <>
                <ContactSection
                  show={showEmail}
                  contactValue={email}
                  showEdit
                  editable={LOGIN_EDITABLE}
                  contactForDirectMarketingEditState={contactForDirectMarketingEditState}
                  setEditType={setEditType}
                  showSecondLoginMethodModal={() => showSecondLoginMethodModal(CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS)}
                  hideSecondLoginMethodModal={hideSecondLoginMethodModal}
                  setShowCustomerContactModal={setShowCustomerContactModal}
                />
                <ContactSection
                  show={showMobile}
                  contactValue={countryCodeAndPhoneNumber}
                  showEdit
                  editable={LOGIN_EDITABLE}
                  contactForDirectMarketingEditState={contactForDirectMarketingEditState}
                  setEditType={setEditType}
                  showSecondLoginMethodModal={() => showSecondLoginMethodModal(CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER)}
                  hideSecondLoginMethodModal={hideSecondLoginMethodModal}
                  setShowCustomerContactModal={setShowCustomerContactModal}

                />
              </>
              : <>
                {
                  PRIMARY_SIGN_UP_METHOD === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
                    ? <ContactSection
                      show={showMobile}
                      contactValue={countryCodeAndPhoneNumber}
                      showEdit
                      editable={LOGIN_EDITABLE}
                      contactForDirectMarketingEditState={contactForDirectMarketingEditState}
                      setEditType={setEditType}
                      showSecondLoginMethodModal={() => showSecondLoginMethodModal(CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER)}
                      hideSecondLoginMethodModal={hideSecondLoginMethodModal}
                      setShowCustomerContactModal={setShowCustomerContactModal}
                    />
                    : <ContactSection
                      show={showEmail}
                      contactValue={email}
                      showEdit
                      editable={LOGIN_EDITABLE}
                      contactForDirectMarketingEditState={contactForDirectMarketingEditState}
                      setEditType={setEditType}
                      showSecondLoginMethodModal={() => showSecondLoginMethodModal(CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS)}
                      hideSecondLoginMethodModal={hideSecondLoginMethodModal}
                      setShowCustomerContactModal={setShowCustomerContactModal}
                    />
                }

                {
                  SECOND_CONTACT_SHOW
                    ? <>
                      <div className="common-item-title contact-for-direct-marketing-title subheading">
                        {i18n.t('settings_contact_for_direct_marketing') +
                          i18n.t('personal_info_optional')}
                      </div>
                      {
                        PRIMARY_SIGN_UP_METHOD !== CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
                          ? <ContactSection
                            show={showSecondContactMobile}
                            contactValue={countryCodeAndPhoneNumber}
                            showEdit={countryCodeAndPhoneNumber}
                            editable={SECOND_CONTACT_EDITABLE}
                            contactForDirectMarketingEditState={contactForDirectMarketingEditState}
                            setEditType={setEditType}
                            showSecondLoginMethodModal={() => showSecondLoginMethodModal(CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER)}
                            hideSecondLoginMethodModal={hideSecondLoginMethodModal}
                            setShowCustomerContactModal={setShowCustomerContactModal}
                          />
                          : <ContactSection
                            show={showSecondContactEmail}
                            contactValue={email}
                            showEdit={email}
                            editable={SECOND_CONTACT_EDITABLE}
                            contactForDirectMarketingEditState={contactForDirectMarketingEditState}
                            setEditType={setEditType}
                            showSecondLoginMethodModal={() => showSecondLoginMethodModal(CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS)}
                            hideSecondLoginMethodModal={hideSecondLoginMethodModal}
                            setShowCustomerContactModal={setShowCustomerContactModal}
                          />
                      }
                    </>
                    : null
                }
              </>
          }

          {showAddAccountButton && (
            <button
              className="add-another-btn button-small subheading"
              onClick={() => showSecondLoginMethodModal(
                signUpMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
                  ? CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER
                  : CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS
              )}
            >
              {SECOND_CONTACT_FOR_LOGIN ? i18n.t('settings_login_add_second_method') : i18n.t('additional_contact_title')}
            </button>
          )}
        </div>
      </div>

      <div>
        <MyAccountDirectMarketing />
      </div>

      {additionalDataSectionConfig?.map(sectionConfig => (
        <div>
          <AdditionalDataSection sectionConfig={sectionConfig} isMobileView={true} />
        </div>
      ))}

      <div>
        <MyAccountChangePassword />
      </div>

      <div>
        <MyAccountDeleteAccount />
      </div>

      <LoginMethodContactModal
        showModal={showLoginMethodModal}
        modalMethod={modalMethod}
        onClose={() => hideSecondLoginMethodModal()}
      />
      <CustomerContactModal
        show={showCustomerContactModal}
        onClose={closeCustomerContactModal}
      />
    </div>
  );
};

export default connect(mapStateToProps)(withTranslation()(MyAccountMobile));
