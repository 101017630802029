export const LEVEL_CONFIG = [
  {"id": 2, "name": "Pod", "rangeStart": "1", 'rangeEnd':"2,999"},
  {"id": 3, "name": "Thread", "rangeStart": "3,000", 'rangeEnd':"11,999"},
  {"id": 4, "name": "Bloom", "rangeStart": "12,000", 'rangeEnd':null}
]
export const LEVEL_DOWNGRADE_CONFIG = [
  {"id": 2, "name": "Pod", "retainSpending": null, 'downgradeLevel':"Canvas"},
  {"id": 3, "name": "Thread", "retainSpending": "3,000", 'downgradeLevel':"Canvas"},
  {"id": 4, "name": "Bloom", "retainSpending": "9,000", 'downgradeLevel':"Thread"}
]
export const WEB_DOMAIN =
  process.env.REACT_APP_WEB_DOMAIN || 'https://web.aillia.motherapp.com';
export const DOWNLOAD_APP_DOMAIN =
  process.env.REACT_APP_DOWNLOAD_APP_DOMAIN ||
  'https://download.aillia.motherapp.cn';
export const APP_NAME = process.env.REACT_APP_NAME || 'MA-Loyalty';
export const NO_LEVEL = process.env.REACT_APP_NO_LEVEL === 'true';
export const ONLY_HAVE_WEBSITE =
  process.env.REACT_APP_ONLY_HAVE_WEBSITE === 'true';
export const NO_SHOPIFY = process.env.REACT_APP_NO_SHOPIFY === 'true';
export const NO_WEB_APP = process.env.REACT_APP_NO_WEBAPP === 'true';
export const QRCODE_SHOW_RAW_MEMBERSHIP_ID =
  process.env.REACT_APP_QRCODE_SHOW_RAW_MEMBERSHIP_ID === 'true';
export const SHOPIFY_API_HOST =
  process.env.REACT_APP_SHOPIFY_API_HOST || 'https://ailliademo.myshopify.com';
export const NO_TRANSACTION_HISTORY = process.env.REACT_APP_NO_TRANSACTION_HISTORY === 'true';
export const SHOW_ONLINE_EMAIL_REMINDER = true;
export const MINIMUM_REGISTER_AGE = 0;
export const MAXIMUM_REGISTER_AGE = 150;
// support sign up method(s), have 3 options: "MOBILE_PHONE_NUMBER", "EMAIL_ADDRESS", "MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS"
export const PRIMARY_SIGN_UP_METHOD = "MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS";

// if PRIMARY_SIGN_UP_METHOD is MOBILE_PHONE_NUMBER or EMAIL_ADDRESS,
// LOGIN_OPTIONAL would not work
export const LOGIN_EDITABLE = false;
export const LOGIN_OPTIONAL = false;

// if PRIMARY_SIGN_UP_METHOD is MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
// SECOND_CONTACT_SHOW, SECOND_CONTACT_EDITABLE, SECOND_CONTACT_OPTIONAL and SECOND_CONTACT_HIDE_OTP would not work
export const SECOND_CONTACT_SHOW = true;
export const SECOND_CONTACT_EDITABLE = true;
export const SECOND_CONTACT_OPTIONAL = true;
export const SECOND_CONTACT_HIDE_OTP = false;

// simplify conditions: SECOND_CONTACT_FOR_LOGIN, EDIT_MOBILE_OPTIONAL, EDIT_EMAIL_OPTIONAL
export const SECOND_CONTACT_FOR_LOGIN = PRIMARY_SIGN_UP_METHOD === "MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS";
export const EDIT_MOBILE_OPTIONAL = (
  [
    "MOBILE_PHONE_NUMBER",
    "MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS"
  ].includes(PRIMARY_SIGN_UP_METHOD) && LOGIN_OPTIONAL
) || (
  PRIMARY_SIGN_UP_METHOD === "EMAIL_ADDRESS" && SECOND_CONTACT_OPTIONAL
);
export const EDIT_EMAIL_OPTIONAL = (
  [
    "EMAIL_ADDRESS",
    "MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS"
  ].includes(PRIMARY_SIGN_UP_METHOD) && LOGIN_OPTIONAL
) || (
  PRIMARY_SIGN_UP_METHOD === "MOBILE_PHONE_NUMBER" && SECOND_CONTACT_OPTIONAL
);

export const CONTACT_US_EMAIL = "Customercare@ka-pok.com";
export const CONTACT_US_PHONE = "+85266009429";
export const CONTACT_US_WHATSAPP_PHONE = "+85266009429";
