import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';

import i18n from 'I18n';
import moment from 'moment';
import QRCode from 'qrcode.react';

import { getQRCode } from 'utils/QrCodeUtils';

import './UseCashQRCodeModal.scss';

const CODE_EXPIRED_SECONDS = 120;

function UseCashQRCodeModal({
  show = false,
  onHide = () => {},
  cashDollar,
  code = '',
  refreshQRCodeAction = () => {},
}) {
  const [currentDatetime, setCurrentDateTime] = useState(new Date());
  const [expiredSeconds, setExpiredSeconds] = useState(CODE_EXPIRED_SECONDS);
  const [qrCodeString, setQRCodeString] = useState('');
  const codeKeyRef = useRef(code);
  const todayDate = moment(currentDatetime).format('D MMM, YYYY');
  const currentTime = moment(currentDatetime).format('H:mm:ss');

  const refreshQRCodeString = (codeKey) => {
    if (!codeKey) {
      return;
    }
    const codeContent = {
      key: codeKey,
    };
    const { codeString } = getQRCode(codeContent);
    setQRCodeString(codeString);
  };

  useEffect(() => {
    codeKeyRef.current = code;
    refreshQRCodeString(code);
    setExpiredSeconds(() => CODE_EXPIRED_SECONDS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (!show) {
      return;
    }
    const timerId = setInterval(() => {
      setCurrentDateTime(new Date());
      setExpiredSeconds((prevTime) => {
        if (prevTime === 0) {
          refreshQRCodeAction();
          refreshQRCodeString(codeKeyRef.current);
          return CODE_EXPIRED_SECONDS;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
    return () => {
      clearInterval(timerId);
      setExpiredSeconds(() => CODE_EXPIRED_SECONDS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal
      className="use-cash-qr-code-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header>
        <div className="cash-modal-secondary-title subheading">
          {i18n.t('pay')}
        </div>
        <div className="cash-dollar-text title-x-large">
          <div>{cashDollar || ''}</div>
          <div className="cash-point-balance-text body-2">
            {i18n.t('cash_dollar')}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="cash-point-balance-text body-2">{todayDate}</div>
        <div className="current-time title-medium-1">{currentTime}</div>
        <QRCode value={qrCodeString} renderAs="svg" className="cash-qr-code" />
        <div className="point-to-cash-rule tag-menu-1">
          {i18n.t('expiring_seconds', { seconds: expiredSeconds })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="left_btn button-large" onClick={onHide}>
          {i18n.t('birthday_done')}
        </button>
        <div className="point-to-cash-tips tag-menu-1">
          {i18n.t('use_cash_dollar_tips')}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default UseCashQRCodeModal;
