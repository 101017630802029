import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { Row, Image } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./PickUpStoreSection.scss";
import { createAction } from "../../utils";
import i18n from "../../I18n";
import CheckedRadio from '../../assets/images/checked_radio.svg';
import UncheckedRadio from '../../assets/images/unchecked_radio.svg';
import OutOfStockRadio from '../../assets/images/out_of_stock_radio.svg';
import NoticeImg from '../../assets/images/Info.svg';
import LeftArrow from "../../assets/back-btn.svg";
import LoadMoreSpinner from "component/common/LoadMoreSpinner";

@connect(({ myCoupon, pullrefreshandloadmore }) => ({
  selectedStore: myCoupon.selectedStore,
  selectedUsageMedium: myCoupon.selectedUsageMedium,
  applicableStoreIsLoading: pullrefreshandloadmore.couponApplicableStoreIsLoading,
}))
class PickupStore extends Component {
  constructor(props) {
    super(props);
    this.defaultSelectedFirst = this.props.defaultSelectedFirst || false
    this.isMobileView = this.props.isMobileView || false
    this.state = {
      showConfirmView: false,
      isConfirmed: false,
    };
  }

  componentDidMount() {
    if (this.defaultSelectedFirst) {
      this.setDefaultSelectedStore()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.defaultSelectedFirst && (!prevProps?.stores || prevProps?.stores?.length === 0)) {
      this.setDefaultSelectedStore()
    }
    if (this.isMobileView && prevState.showConfirmView !== this.state.showConfirmView) {
      if (this.state.showConfirmView) {
        this.props.dispatch(createAction("navBars/updateState")({
          backBtnAction: () => {
            this.setState({ showConfirmView: false })
          }
        }))
      } else {
        this.props.dispatch(createAction("navBars/updateState")({
          backBtnAction: null
        }))
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(createAction("myCoupon/updateState")({
      selectedStore: {},
      selectedUsageMedium: {}
    }));
    if (this.isMobileView) {
      this.props.dispatch(createAction("navBars/updateState")({
        backBtnAction: null
      }))
    }
  }

  setDefaultSelectedStore = () => {
    if (this.props?.stores?.length > 0) {
      const defaultSelectedStore = this.props.stores.find(item => item?.stock_count)
      this.props.dispatch(createAction("myCoupon/selectStore")(defaultSelectedStore));
    }
  }

  selectStore = (store) => {
    this.props.dispatch(createAction("myCoupon/selectStore")(store));
  };

  selectStoreUsageMedium = (item) => {
    this.props.dispatch(createAction("myCoupon/updateState")({ selectedUsageMedium: item }));
  }

  confirmStoreAction = () => {
    if (this.props.couponInfo?.base?.shouldSelectStore) {
      this.props.selectedStore?.id && this.props.dispatch(createAction("myCoupon/convertVirtualToReal")({
        couponID: this.props.couponID,
        storeID: this.props.selectedStore?.id,
        afterSuccessAction: (coupon) => {
          this.props.history.replace({
            pathname: `/coupon/${coupon?.id}`
          })
        }
      }));
      return;
    }
    if (this.props.couponInfo?.base?.shouldSelectOnlineOrOffline) {
      this.props.selectedUsageMedium?.key && this.props.dispatch(createAction("myCoupon/convertVirtualToReal")({
        couponID: this.props.couponID,
        usageMedium: this.props.selectedUsageMedium?.key,
        afterSuccessAction: (coupon) => {
          this.props.history.replace({
            pathname: `/coupon/${coupon?.id}`
          })
        }
      }));
      return;
    }
  }
  getPickUpTitle = () => {
    if (this.state.showConfirmView) {
      return i18n.t("coupon_selected_store")
    }
    if (!this.state.showConfirmView) {
      return i18n.t("coupon_select_applicable_store")
    }
  }

  render() {
    const couponBaseInfo = this.props.couponInfo?.base
    const showStoreList = () => {
      if (couponBaseInfo?.shouldSelectStore) {
        return (
          <ListGroup id="store-list" className="overflow-auto store_list">
            {this.props.applicableStoreIsLoading ?
              <LoadMoreSpinner /> :
              this.props?.stores?.map((store, index, arr) => {
                var customClassName = "";
                if (arr.length === 1) {
                  customClassName = "first last";
                } else if (index === 0) {
                  customClassName = "first";
                } else if (index === arr.length - 1) {
                  customClassName = "last";
                } else {
                  customClassName = index;
                }
                const available = store?.stock_count;
                const isSelected = this.props?.selectedStore?.id === store.id;
                return (
                  <ListGroup.Item
                    onClick={() => {
                      if (available) {
                        this.selectStore(store);
                      }
                    }}
                    className={`${customClassName} store-list-item`}
                  >
                    <div>
                      <Image
                        src={isSelected ? CheckedRadio : available ? UncheckedRadio : OutOfStockRadio}
                        className={`store-list-item-button ${available ? 'available-icon-filter' : 'unavailable-icon-filter'}`}
                      />
                    </div>
                    <div>
                      <div className="store_name body-2">{store.name}</div>
                      <span className={`badge badge-primary ${store?.is_online_store ? "online-store-label" : "offline-store-label"} caption`}>
                        {store?.is_online_store ? i18n.t("product:Online store") : i18n.t("product:Physical store")}
                      </span>
                      <div className="store_location body-1">{store.address}</div>
                      {!store?.stock_count && (
                        <span className="badge badge-primary available_state_label caption">
                          {i18n.t("product:Out of stock")}
                        </span>
                      )}
                    </div>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        )
      }
      if (couponBaseInfo?.shouldSelectOnlineOrOffline) {
        const onlineOrOfflineListConfig = [
          {
            key: "ONLINE",
            name: i18n.t("product:Online store"),
            description: i18n.t("coupon:useCouponInAllOnlineStores"),
          },
          {
            key: "OFFLINE",
            name: i18n.t("product:Physical store"),
            description: i18n.t("coupon:useCouponInAllPhysicalStores"),
          }
        ]
        return (
          <ListGroup id="store-list" className="overflow-auto store_list">
            {this.props.applicableStoreIsLoading ?
              <LoadMoreSpinner /> :
              onlineOrOfflineListConfig?.map((item, index, arr) => {
                var customClassName = "";
                if (index === 0) {
                  customClassName = "first";
                } else if (index === arr.length - 1) {
                  customClassName = "last";
                }
                const isSelected = this.props?.selectedUsageMedium?.key === item.key;
                return (
                  <ListGroup.Item
                    onClick={() => {
                      this.selectStoreUsageMedium(item)
                    }}
                    className={`${customClassName} store-list-item`}
                  >
                    <div>
                      <Image
                        src={isSelected ? CheckedRadio : UncheckedRadio}
                        className={`store-list-item-button available-icon-filter`}
                      />
                    </div>
                    <div>
                      <div className="store_name body-2">{item.name}</div>
                      <div className="store_location body-1">{item.description}</div>
                    </div>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        )
      }

    }

    const showSelectedStore = () => {
      if (this.props.selectedStore?.id) {
        return (
          <div className="confirm-selected-store">
            <div className="store_name body-2">{this.props.selectedStore.name}</div>
            <span className={`badge badge-primary ${this.props.selectedStore?.is_online_store ? "online-store-label" : "offline-store-label"} caption`}>
              {this.props.selectedStore?.is_online_store ? i18n.t("product:Online store") : i18n.t("product:Physical store")}
            </span>
            <div className="store_location body-1">{this.props.selectedStore.address}</div>
          </div>)
      }
      if (this.props.selectedUsageMedium?.key) {
        return (
          <div className="confirm-selected-store">
            <div className="store_name body-2">{this.props.selectedUsageMedium.name}</div>
            <div className="store_location body-1">{this.props.selectedUsageMedium.description}</div>
          </div>)
      }

    }

    return (
      <>
        {this.state.showConfirmView && !this.isMobileView ?
          <div
            className="pick-up-store-back-btn" onClick={() => {
              this.setState({ showConfirmView: false })
            }}>
            <Image
              src={LeftArrow}
            />
          </div> : null}
        <Container className="pick_up_section d-block">
          <Row className="pick_up_div">
            <label className="pick_up_label subheading">
              {this.getPickUpTitle()}
            </label>
            {this.state.showConfirmView ? showSelectedStore() : showStoreList()}
            {this.isMobileView ? null :
              <>
                <div className="split-line" />
                <div className="d-flex flex-column" style={{ width: "100%", gap: "12px", padding: "0 16px" }}>
                  <button
                    className="comfirm-select-store-btn button-large"
                    disabled={!this.state.showConfirmView && !this.props.selectedStore?.id && !this.props.selectedUsageMedium?.key}
                    onClick={() => {
                      if (this.state.showConfirmView) {
                        this.confirmStoreAction()
                      } else {
                        this.setState({ showConfirmView: true })
                      }
                    }}
                  >
                    {this.state.showConfirmView ? i18n.t("coupon:Confirm") : i18n.t("coupon:Next")}
                  </button>
                  {this.state.showConfirmView &&
                    <div className="conform-store-tips caption">
                      <Image
                        src={NoticeImg}
                      />
                      <div>{i18n.t("coupon:processNotRedeemCoupon")}</div>
                    </div>}
                </div>
              </>}
          </Row>
        </Container>
        {this.isMobileView ?
          <div className="staff_use_bottom_bar bottom-select-store-btn-container">
            <Container className="staff_use_container">
              {this.state.showConfirmView ?
                <div className="conform-store-tips caption">
                  <Image
                    src={NoticeImg}
                  />
                  <div>{i18n.t("coupon:processNotRedeemCoupon")}</div>
                </div>
                : null
              }
              <button
                className="comfirm-select-store-btn button-large"
                disabled={!this.state.showConfirmView && !this.props.selectedStore?.id && !this.props.selectedUsageMedium?.key}
                onClick={() => {
                  if (this.state.showConfirmView) {
                    this.confirmStoreAction()
                  } else {
                    this.setState({ showConfirmView: true })
                  }
                }}
              >
                {this.state.showConfirmView ?  i18n.t("coupon:Confirm") : i18n.t("coupon:Next")}
              </button>
            </Container>
          </div> : null}
      </>
    );
  }
}

export default withRouter(PickupStore);
