import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "react-bootstrap";

import AlertIcon from "assets/images/alert.svg";
import TickIcon from "assets/images/tick.svg";
import { ToastType } from "models/NavBarModel";

export function CustomToast() {
  const dispatch = useDispatch();
  const { toastShowing } = useSelector((state) => ({
    toastShowing: state.navBars.toastShowing,
  }));
  const [show, setShow] = useState(toastShowing.value);

  useEffect(() => {
    setShow(toastShowing.value);
  }, [toastShowing.value]);

  const onClose = () => {
    setShow(false);
    dispatch({
      type: "navBars/updateState",
      payload: {
        toastShowing: {
          value: false,
          content: null,
          showTickIcon: false,
          toastIcon: null,
        },
      },
    });
  };

  return (
    <div className="update-success-toast">
      {show ? (
        <Toast onClose={onClose} delay={1200} autohide>
        <Toast.Body>
          {toastShowing.type === ToastType.image ? (
            toastShowing.content
          ) : (
                <>
                  <img
                    alt=""
                    src={
                      toastShowing?.showTickIcon
                        ? TickIcon
                        : toastShowing.toastIcon || AlertIcon
                    }
                  />
                  <label className="save-toast-text">{toastShowing.content}</label>
                </>
          )}
        </Toast.Body>
        </Toast>)
        : null
      }
    </div>
  );
}
