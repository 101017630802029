import React, { Component, createRef } from "react";
import { Container, Row, Modal } from "react-bootstrap";

import "./ActionBottomSection.scss";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Lottie from "lottie-react";
// import { DOWNLOAD_APP_DOMAIN } from "../../utils/Config";
import i18n from "../../I18n";
// import { Trans } from "react-i18next";
import {
  EARNING_RULE_GPS,
  EARNING_RULE_QR_CODE,
  EARNING_RULE_REFER,
} from "../../services/CampaignAPIHelper";
import { createAction, detectIsMobile } from "../../utils";
import { checkIfStarted, checkIfExpired } from "../../utils/CampaignUtil";
import { ACTION_BUTTON_STATUS } from "./CheckInSection";
import { ssoAPIHelper } from "../../services/SSOAPIHelper";
import Scanner from "./Scanner";
import { getCampaignAvailableOnDate } from "../../utils/DateTimeUtils";
@connect(({ users, campaignDetail }) => ({
  membershipID: users.userInfo.memberShipID,
  isLogin: users.isLogin,
  actionButtonStatus: campaignDetail.actionButtonStatus,
}))
class ActionBottom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrCodeString: "",
      showScanner: false,
      showActionModal: false
    };
  }
  componentDidUpdate() {
    if (this.state.qrCodeString) {
      this.props.dispatch(
        createAction("campaignDetail/verifyQrCode")({
          qrCodeString: this.state.qrCodeString,
          campaignID: this.props.campaignId,
        })
      );
      this.setState({ qrCodeString: "" })
    }
  }
  getButtonLabel = () => {
    if (!checkIfStarted(this.props.actionBar.startDate)) {
      return i18n.t("campaignDetail:availableOn", {
        date: getCampaignAvailableOnDate(this.props.actionBar.startDate),
      });
    }
    if (this.props.actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON) {
      return i18n.t("campaign_my_coupon")
    }
    const actionBar = this.props.actionBar;
    if (actionBar.earningRuleType === EARNING_RULE_QR_CODE) {
      return i18n.t("campaignDetail:scanQrCode")
    }
    if (actionBar.earningRuleType === EARNING_RULE_GPS) {
      return i18n.t("campaignDetail:checkIn")
    }
    if (actionBar.earningRuleType === EARNING_RULE_REFER) {
      return i18n.t("campaignDetail:referAFriend")
    }
    return i18n.t("campaignDetail:get")
  }

  getGeo = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.props.dispatch(
          createAction('campaignDetail/verifyGPS')({
            campaignID: this.props.campaignId,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
          case error.POSITION_UNAVAILABLE:
          case error.TIMEOUT:
          default:
            this.props.dispatch(createAction("navBars/updateState")(
              {
                toastShowing: {
                  value: true,
                  content: i18n.t("campaignDetail:invalidGPSLocation"),
                },
              }
            ))
        }
      }
    );
  }

  getModalDescription = () => {
    if (this.props.actionBar.earningRuleType === EARNING_RULE_QR_CODE) {
      return i18n.t("campaignDetail:noCamera");
    } else if (this.props.actionBar.earningRuleType === EARNING_RULE_GPS) {
      return i18n.t("campaignDetail:useMobileDevice");
    } else if (this.props.actionBar.earningRuleType === EARNING_RULE_REFER) {
      return i18n.t("referMessage", {
        link: `com.kapok.loyalty.sit://home/?referCode=${this.props.membershipID}`,
        code: this.props.membershipID,
      });
    }
    return null;
  }

  render() {
    const campaign = this.props.campaign;
    const actionBar = this.props.actionBar;
    const isMobile = detectIsMobile()

    const tickAnimation = () => {
      return (
        <Lottie
          animationData={require('../../assets/animation/tick_green.json')}
          loop={false}
          onComplete={() => {
            if ((actionBar.earningRuleType === EARNING_RULE_QR_CODE ||
              actionBar.earningRuleType === EARNING_RULE_GPS) &&
              actionBar?.rewardType === "COUPON") {
              this.props.dispatch(
                createAction("campaignDetail/updateState")({
                  actionButtonStatus: ACTION_BUTTON_STATUS.MYCOUPON,
                }))
              } else {
              this.props.dispatch(
                createAction("campaignDetail/updateState")({
                  actionButtonStatus: ACTION_BUTTON_STATUS.DISABLE,
                }))
              }
          }}
        />
      )
    }

    const loadingAnimation = () => {
      return (
        <Lottie
          animationData={require('../../assets/animation/loading_jumping_balls.json')}
          loop={true} 
        />
      )
    }

    const bottomButtonLabel = () => {
      const isEarningCampaign = this.props.campaign.campaignType === "EARNING_CAMPAIGN";
      if (this.props.actionButtonStatus === ACTION_BUTTON_STATUS.TICK) {
        return tickAnimation() 
      }
      if (this.props.actionButtonStatus === ACTION_BUTTON_STATUS.LOADING) {
        return loadingAnimation()
      }
      return (
        <label
          className="bottom_btn button-large"
        >
          {isEarningCampaign ? this.getButtonLabel() : i18n.t("campaignDetail:redeem")}
        </label>
      )
    }

    const clickActionButton = () => {
      if (campaign.campaignType !== "EARNING_CAMPAIGN") {
        this.props.history.push({
          pathname: '/campaign/detail',
          state: {
            campaign: campaign,
            campaignID: this.props.campaignId,
            goBackOnSuccess: true,
          },
        });
        return;
      }

      if (!this.props?.isLogin) {
        window.location.href = ssoAPIHelper.getLoginWebUri(
          this.props.i18n?.language || 'en'
        );
      } else if (this.props.actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON) {
        this.props.history.push("/user/myCoupon")
      } else if (this.props.actionBar.earningRuleType === EARNING_RULE_QR_CODE && isMobile) {
        this.setState({
          showScanner: true,
        })
      } else if (this.props.actionBar.earningRuleType === EARNING_RULE_GPS && isMobile) {
        this.getGeo()
      } else if (this.props.actionBar.earningRuleType === EARNING_RULE_REFER && isMobile && navigator.share) {
        navigator.share({
          title: "",
          text: i18n.t("referMessage", {
            link: `com.kapok.loyalty.sit://home/?referCode=${this.props.membershipID}`,
            code: this.props.membershipID,
          }),
        })
      } else {
        this.setState({ showActionModal: true })
      }
    }

    const actionModalButton = () => {
      if (this.props.actionBar.earningRuleType === EARNING_RULE_REFER) {
        return (
          <>
            <button
              className="left_btn button-large"
              onClick={() => this.setState({ showActionModal: false })}
            >
              {i18n.t("close")}
            </button>
            <button
              className="right_btn button-large"
              onClick={() => {
                navigator.clipboard.writeText(
                  i18n.t("referMessage", {
                    link: `com.kapok.loyalty.sit://home/?referCode=${this.props.membershipID}`,
                    code: this.props.membershipID,
                  })
                  || "");
                this.setState({ showActionModal: false })
                this.props.dispatch({
                  type: "navBars/updateState",
                  payload: {
                    toastShowing: {
                      value: true,
                      content: i18n.t("copySuccessfully"),
                      showTickIcon: true,
                    },
                  },
                });
              }}
            >
              {i18n.t("copy")}
            </button>
          </>
        )
      }
      return (
        <>
          <button
            className="right_btn button-large"
            onClick={() => {
              this.props.dispatch(
                createAction("campaignDetail/updateState")({
                  actionButtonStatus: ACTION_BUTTON_STATUS.DISABLE,
                }))
              this.setState({
                showActionModal: false,
              })
            }}
          >
            {i18n.t("campaign_ok")}
          </button>
        </>
      )
    }

    const actionModal = () => {
      return (
        <Modal
          show={this.state.showActionModal}
          onHide={() => this.setState({ showActionModal: false })}
          className="use_coupon_modal"
          centered
        >
          <Modal.Body className="use_coupon_modal_content d-flex-column">
            <div>
              <p className="use_coupon_title title-medium-1">
                {this.getModalDescription()}
              </p>
            </div>
            <div className="button_group">
              {actionModalButton()}
            </div>
          </Modal.Body>
        </Modal >)
    }

    return (
      <>
        <Container className="bottom_block " fluid>
        <Row className="bottom">
          <div className="points_required_section">
            <label
              className={
                campaign.campaignType === "EARNING_CAMPAIGN"
                  ? "points_required row earning_type caption"
                  : "points_required row coupon_type caption"
              }
            >
              {actionBar.textTitle}
            </label>
            <label
              className={
                campaign.campaignType === "EARNING_CAMPAIGN"
                  ? "point row earning_type title-large"
                  : "point row coupon_type title-large"
              }
            >
              {actionBar.point}
            </label>
          </div>
          <button
            className={`btn_section
            ${checkIfStarted(this.props.actionBar.startDate) ? "" : "unavailable"}
            ${campaign.campaignType === "EARNING_CAMPAIGN"
                ? "earning_type"
                : "coupon_type"}
            ${this.props.actionButtonStatus === ACTION_BUTTON_STATUS.TICK ? "tick_animation_btn" : ""}
            ${this.props.actionButtonStatus === ACTION_BUTTON_STATUS.LOADING ? "loading_animation_btn" : ""}
            ${this.props.actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON ? "my_coupon_btn" : ""}`
            }
            onClick={() => {
              if (checkIfExpired(this.props.actionBar.endDate)) {
                this.props.dispatch(createAction("navBars/updateState")(
                  {
                    toastShowing: {
                      value: true,
                      content: i18n.t('campaignDetail:expireToast'),
                    },
                  }
                ))
                return;
              };
              clickActionButton();
            }}
            disabled={this.props.actionButtonStatus === ACTION_BUTTON_STATUS.DISABLE ||
              !checkIfStarted(this.props.actionBar.startDate) ||
              campaign?.basic.outOfStock
            // !campaign?.basic.isActive
            // ? true
            // : false
            }
            hidden={
              campaign.campaignType === "EARNING_CAMPAIGN" &&
              [
                EARNING_RULE_QR_CODE,
                EARNING_RULE_GPS,
                EARNING_RULE_REFER,
              ].indexOf(actionBar.earningRuleType) < 0
            }
          >
            {bottomButtonLabel()}
          </button>
        </Row>
      </Container>
        {actionModal()}
        {isMobile && this.state.showScanner ?
          <Scanner
            show={this.state.showScanner}
            handleClose={() => { this.setState({ showScanner: false }) }}
            setQRCodeString={(code) => this.setState({ qrCodeString: code })}
            onError={(errorMessage) => {
              this.props.dispatch(createAction("navBars/updateState")(
                {
                  toastShowing: {
                    value: true,
                    content: errorMessage,
                  },
                }
              ))
            }}
          />
          :
          null
        }
      </>
    );
  }
}

export default withRouter(ActionBottom);
