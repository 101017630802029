import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Trans, withTranslation } from 'react-i18next';
import { useDispatch, connect } from "react-redux";

import "./RedeemGiftCard.scss"
import { POINT_TO_CASH_CONVERT_RATE } from "component/profile/PointAndCashSection";
import i18n from 'I18n';
import { ssoAPIHelper } from "services/SSOAPIHelper";
import { ReactComponent as RedeemSuccess } from '../assets/images/redeemSuccess.svg';
import { ReactComponent as RedeemFailed } from '../assets/images/redeemFailed.svg';
import { createAction, enLocaleNumberFormatter } from 'utils';
import { DOWNLOAD_APP_DOMAIN, APP_NAME, SHOPIFY_API_HOST, NO_SHOPIFY } from "utils/Config";
import { CUSTOMER_SIGN_UP_METHOD } from "utils/CustomEnums";


const USER_LOGIN_STATUS = {
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  CANCEL_AFTER_LOGIN: "CANCEL_AFTER_LOGIN"
}

const GIFT_CARD_DETAIL = {
  NOT_GIFT_CARD_OWNER: "not gift card owner",
  NOT_FOUND_CUSTOMER: "not found customer",
  NOT_FOUND_CODE: "not found code",
}

const mapStateToProps = (state) => {
  return {
    tempAccessToken: state.users.tempAccessToken,
    userInfo: state.users.unloginUserInfo
  };
};

const RedeemGiftCard = ({ t, tempAccessToken, userInfo, location }) => {
  const redirectShopifyUrl = `${SHOPIFY_API_HOST}?open_reward_panel=true`
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [ssoFrontendRealURL, setSsoFrontendRealURL] = useState("");
  const [loginStatus, setLoginStatus] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [giftCardCash, setGiftCardCash] = useState(0);
  const [frameHeight, setFrameHeight]= useState();
  const ssoIframeRef = useRef();
  const [redemptionErrorMessage, setRedemptionErrorMessage] = useState(t("giftCardRedeemed"));

  const account = userInfo?.signUpMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER ?
    `+${userInfo?.countryCode || ""} ${userInfo?.phoneNumber || ""}`
    : userInfo?.emailAddress || ""
  const points = giftCardCash * POINT_TO_CASH_CONVERT_RATE || 0;

  const searchParams = new URLSearchParams(location.search)
  const giftCardCode = searchParams.get("code", "")

  const dispatch = useDispatch()

  const handleReceivedMsg = (e) => {
    const { data, origin } = e;
    if (data?.type === USER_LOGIN_STATUS.LOGIN_SUCCESS) {
      setSsoFrontendRealURL(origin);
      if (!data?.token) {
        setLoginStatus(USER_LOGIN_STATUS.LOGIN_FAILURE)
      }
      setRefreshToken(data.token);
    } else if (data?.type === USER_LOGIN_STATUS.LOGIN_FAILURE) {
      setLoginStatus(USER_LOGIN_STATUS.LOGIN_FAILURE)
    } else if (data?.type ==="RESIZE") {
      setFrameHeight(data?.value)
    }
  }

  const modalCancelAction = () => {
    setShowConfirmModal(false);
    setLoginStatus();
    ssoIframeRef.current.contentWindow.postMessage({
      type: USER_LOGIN_STATUS.CANCEL_AFTER_LOGIN,
    }, ssoFrontendRealURL)
  }

  const modalConfirmAction = () => {
    setShowConfirmModal(false);
    if (userInfo?.uid && giftCardCode && tempAccessToken) {
      dispatch(createAction("users/redeemGiftCard")({
        token: tempAccessToken,
        code: giftCardCode,
        uid: userInfo?.uid,
        successAction: ()=>{
          setLoginStatus(USER_LOGIN_STATUS.LOGIN_SUCCESS);
        },
        failedAction: ()=>{
          setLoginStatus(USER_LOGIN_STATUS.LOGIN_FAILURE);
        }
      }))
    }
  }

  const frameOrRedeemResultSection = () => {
    if (loginStatus === USER_LOGIN_STATUS.LOGIN_SUCCESS) {
      return (
        <>
          <div>
            <RedeemSuccess className="redeem-success"/>
          </div>
          <div className="redeem-result-text-section">
            <div className="title-medium-1">{t("redemptionSuccessful")}</div>
            <div className="text-subheader subheading">{t("point_added_to_account", {
              points: enLocaleNumberFormatter(points),
              cash: enLocaleNumberFormatter(giftCardCash)
            })}</div>
            <div className="text-tips body-1">{t("redeemGiftCardSuccessTips")}</div>
          </div>
          <div className="redeem-gift-card-button-group button-large">
            <button onClick={() => { window.location.replace(DOWNLOAD_APP_DOMAIN) }}>{t("install_open_app", { appName: APP_NAME})}</button>
            {
              NO_SHOPIFY
                ? null
                : <button onClick={() => { window.location.replace(redirectShopifyUrl) }}>{t("visit_shopify_reward_panel")}</button>
            }
          </div>
        </>
      )
    }
    if (loginStatus === USER_LOGIN_STATUS.LOGIN_FAILURE) {
      return (
        <>
          <div>
            <RedeemFailed className="redeem-failed" />
          </div>
          <div className="redeem-result-text-section">
            <div className="title-medium-1">{t("redemptionFailed")}</div>
            <div className="text-description field-text">{redemptionErrorMessage}</div>
          </div>
          <div className="redeem-gift-card-button-group button-large">
            <button onClick={() => { window.location.replace(DOWNLOAD_APP_DOMAIN) }}>{t("install_open_app", { appName: APP_NAME })}</button>
            {
              NO_SHOPIFY
                ? null
                : <button onClick={() => { window.location.replace(redirectShopifyUrl) }}>{t("visit_shopify_reward_panel")}</button>
            }
          </div>
        </>
      )
    }
    return (
      <iframe
        ref={ssoIframeRef}
        title="login"
        style={frameHeight ? {
          minHeight: `${frameHeight}px`
        } : {}}
        src={ssoAPIHelper.getLoginWebUri(
          i18n?.language || "en"
        ) + `&redeem_gift_card=true`}
      />)
  }

  useEffect(() => {
    window.addEventListener("message", handleReceivedMsg);
    return () => {
      window.removeEventListener("message", handleReceivedMsg)
    }
  }, [])

  useEffect(()=>{
    if (refreshToken) {
      dispatch(createAction("users/getTempAccessToken")({
        refreshToken
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken])

  useEffect(() => {
    if (tempAccessToken) {
      dispatch(createAction("users/getUserInfoWithoutLogin")({
        accessToken: tempAccessToken
      }))
      dispatch(createAction("users/fetchGiftCardInfo")({
        token: tempAccessToken,
        code: giftCardCode,
        successAction: (data) => {
          setGiftCardCash(data?.cash_value)
          setShowConfirmModal(true);
        },
        failedAction: (data) => {
          setLoginStatus(USER_LOGIN_STATUS.LOGIN_FAILURE)
          if (data === GIFT_CARD_DETAIL.NOT_GIFT_CARD_OWNER){
            setRedemptionErrorMessage(t("giftCardTargetedCustomer"));
          } else if (data === GIFT_CARD_DETAIL.NOT_FOUND_CUSTOMER){
            setRedemptionErrorMessage(t("giftCardNotFoundCustomer"));
          } else if (data === GIFT_CARD_DETAIL.NOT_FOUND_CODE){
            setRedemptionErrorMessage(t("giftCardNotFound"));
          }
        }
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tempAccessToken])

  return (
    <div className="redeem-gift-card-page">
      <div className="redeem-gift-card-main-body">
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div className="redeem-gift-card-title title-medium-2">{t("redeemTheGiftCard")}</div>
          <div className="redeem-gift-card-tips field-text">{t("redeemGiftCardTips")}</div>
        </div>
        <div className="login-iframe-contanier">
          {frameOrRedeemResultSection()}
        </div>
        <div className="redeem-gift-card-instruction-section">
          <div className="redeem-gift-card-secondary-title title-small">{t("instruction")}</div>
          <ol type="1" className="redeem-gift-card-instruction field-text">
            <li>{t("redeemGiftCardInstruction1")}</li>
            <li>{t("redeemGiftCardInstruction2")}</li>
            <li>{t("redeemGiftCardInstruction3")}</li>
          </ol>
        </div>
      </div>

      <Modal
        show={showConfirmModal}
        onHide={modalCancelAction}
        className="redeem-gift-card-modal field-text"
        scrollable={false}
        centered
      >
        <Modal.Body >
          <div className="modal-title title-medium-1">{t("confirmation")}</div>
          <ul className="" style={{listStyleType: "disc"}}>
            <li>
              <Trans
                i18nKey={'current_logged_in'}
              >
                Currently logged in account:
                <span className="text-color-primaryP2">
                  {{ account }}
                </span>
              </Trans>
            </li>
            <li>{t("point_added_to_account", {
              points: enLocaleNumberFormatter(points),
              cash: enLocaleNumberFormatter(giftCardCash)
            })}</li>
          </ul>
          <div className="button_group">
            <button
              className="left_btn button-large"
              onClick={modalCancelAction}
            >
              {t('message_cancel')}
            </button>
            <button
              className="right_btn button-large"
              onClick={modalConfirmAction}
            >
              {t('redeem')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps)(withTranslation()(RedeemGiftCard));