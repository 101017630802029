import numeral from 'numeral';

export const negativeNumber = (value) => {
  if (Number(value) <= 0) {
    return Number(value);
  } else if (Number(value) > 0) {
    return -Math.abs(value);
  }
  return value;
};

const enLocaleTransactionNumberFormatter = (num) => {
  if (!num) {
    return '0.00';
  }
  return numeral(num).format('0,0.00');
};

export const enLocaleDollarFormatter = (s) => {
  const num = Number(s);
  if (num < 0) {
    return `-HK$ ${enLocaleTransactionNumberFormatter(Math.abs(num))}`;
  }
  return `HK$ ${enLocaleTransactionNumberFormatter(num)}`;
};
